import { Refresh } from '@mui/icons-material'
import _ from 'lodash'
import React, { useState } from 'react'
import parse from 'html-react-parser'
import { Spin } from 'antd'
import RegulationDialogReference from './RegulationDialogReference'
import { postRegulationReferences } from 'api'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { LogoMinimized } from 'assets/images'
const RegulationBotResponseItem = ({
  text = '',
  isLastIndex = true,
  onAddNote = () => {},
  onRegenerate = () => {},
  isLoading,
  item
}) => {
  const token = useSelector((state) => state.user.access_token)
  const [openDialogReference, setOpenDialogReference] = useState(false)
  const [dataRegulationReferences, setDataRegulationReferences] = useState([])

  const handleCloseDialogReference = () => {
    setOpenDialogReference(false)
  }
  const postDataRegulationReferences = useMutation({
    mutationKey: ['postRegulationReferences'],
    mutationFn: async (data) => await postRegulationReferences(token, data),
    onSuccess: (res) => {
      setDataRegulationReferences(res?.resData?.data)
    }
  })

  const handleOpenDialogReference = () => {
    setOpenDialogReference(true)
    postDataRegulationReferences.mutate(item?.sources)
  }
  return (
    <div className="flex gap-2 w-full">
      <div className="flex justify-center items-start w-10">
        <div className="rounded-full overflow-hidden h-6 w-6 bg-[#0049EE]">
          <img src={LogoMinimized} alt="avatarImage" className="object-contain w-full h-full p-1" />
        </div>
      </div>
      <div className="flex w-full h-full flex-col items-start justify-start">
        <span className="text-base font-bold">Legal AI</span>
        <div className="w-full h-full">
          {isLoading && isLastIndex ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spin />
            </div>
          ) : (
            <>
              {parse(text)}{' '}
              {item?.sources ? (
                <span className="text-[#0049EE] cursor-pointer" onClick={handleOpenDialogReference}>
                  {'["]'}
                </span>
              ) : null}
            </>
          )}
        </div>
        {isLastIndex && !isLoading ? (
          <div className="flex  items-center pt-2 text-[#828282] ">
            <div
              onClick={() => {
                _.throttle(() => {
                  return onRegenerate()
                }, 1000)()
              }}>
              <Refresh className="cursor-pointer hover:text-[#5c5b5b]" />
            </div>
          </div>
        ) : null}
      </div>
      <RegulationDialogReference
        open={openDialogReference}
        handleClose={handleCloseDialogReference}
        item={dataRegulationReferences}
      />
    </div>
  )
}

export default RegulationBotResponseItem

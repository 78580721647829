import { getAppInformation } from 'api'
import { useAuth } from 'hooks'
import React from 'react'
import { useQuery } from 'react-query'
import { showErrorMessage } from 'utils/showMessage'
import { useTranslation } from 'react-i18next'

const ContactsPlatformContent = () => {
  const { getAccessToken } = useAuth()
  const { t } = useTranslation();
  const { data: contactsData } = useQuery({
    queryKey: ['app-information-page'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getAppInformation(getAccessToken())
      return res
    },
    onError: () => {
      showErrorMessage('Failed to fetch contacts, please try again')
    }
  })

  return (
    <div className="flex flex-col flex-grow gap-12 max-w-lg">
      {/* Title */}
      <h1 className="text-black text-4xl font-medium">{t("Contacts")}</h1>

      {/* Content */}
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-1">
          <span className="text-black font-semibold text-base">{t("Address")}</span>
          <span className="text-black font-normal text-sm">
            {contactsData?.resData?.data?.find((data) => data?.key === 'address')?.value}
            <br />
            {contactsData?.resData?.data?.find((data) => data?.key === 'zip_code')?.value},{' '}
            {contactsData?.resData?.data?.find((data) => data?.key === 'municipality')?.value},{' '}
            {contactsData?.resData?.data?.find((data) => data?.key === 'province')?.value}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-black font-semibold text-base">{t("Telephone Number")}</span>
          <span className="text-black font-normal text-sm">
            {contactsData?.resData?.data?.find((data) => data?.key === 'phone')?.value}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-black font-semibold text-base">Email</span>
          <span className="text-black font-normal text-sm">
            {contactsData?.resData?.data?.find((data) => data?.key === 'email')?.value}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ContactsPlatformContent

import { zodResolver } from '@hookform/resolvers/zod'
import CustomButton from 'components/atoms/CustomButton'
import DatePickerUserFields from 'components/molecules/user/DatePickerUserFields'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
const schema = z.object({
  fromDate: z.string().optional(),
  toDate: z.string().optional()
})
const ChatFilterModal = ({
  show,
  onClose,
  onSubmit,
  cancelFilter,
  startDate,
  endDate,
  filterDataType
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: {
      fromDate: startDate || '',
      toDate: endDate || ''
    },
    resolver: zodResolver(schema)
  })

  async function processForm(data) {
    let newDateFilterValue = null
    if (data.fromDate !== '' || data.toDate !== '') {
      newDateFilterValue = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== '')
      )
    }

    await onSubmit(newDateFilterValue)
    closeModal()
  }
  function closeModal() {
    reset()
    onClose()
  }

  return (
    <ModalUser modalClassName={''} onClose={closeModal} title={t('Filters')} show={show}>
      {/* Regulation */}
      <div className="h-full md:h-[528px] pt-3 flex flex-col justify-between">
        <div className="flex flex-col gap-8">
          {/* Proceeding Date */}
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">
              {filterDataType === 'chat' ? t('Chat creation date') : t('Documents uploading date')}
            </span>
            <div className="grid grid-cols-2 gap-8">
              <DatePickerUserFields
                control={control}
                label={t('From')}
                name={'fromDate'}
                errors={errors}
              />
              <DatePickerUserFields
                control={control}
                label={t('End')}
                name={'toDate'}
                errors={errors}
              />
            </div>
          </div>
        </div>
        {/* Button Wrapper */}
        <div className="flex justify-end items-center gap-4">
          <CustomButton buttonColor="secondary" onClick={cancelFilter}>
            {t('Cancel Filter')}
          </CustomButton>
          <CustomButton onClick={handleSubmit(processForm)}>{t('Apply')}</CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default ChatFilterModal

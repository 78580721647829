const IcChatEmpty = ({ className, width = 264, height = 239 }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 164 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_588_48308)">
      <mask id="path-1-inside-1_588_48308" fill="white">
        <path d="M45.5 26C37.2157 26 30.5 32.7157 30.5 41V76.9929C30.5 85.2772 37.2157 91.9929 45.5 91.9929H121.168L133.171 104.199L133.171 91.9929H133.5V41C133.5 32.7157 126.784 26 118.5 26H45.5Z" />
      </mask>
      <path
        d="M45.5 26C37.2157 26 30.5 32.7157 30.5 41V76.9929C30.5 85.2772 37.2157 91.9929 45.5 91.9929H121.168L133.171 104.199L133.171 91.9929H133.5V41C133.5 32.7157 126.784 26 118.5 26H45.5Z"
        fill="white"
      />
      <path
        d="M121.168 91.9929L121.881 91.2918L121.587 90.9929H121.168V91.9929ZM133.171 104.199L132.458 104.9L134.171 106.642L134.171 104.199H133.171ZM133.171 91.9929V90.9929H132.171V91.9929H133.171ZM133.5 91.9929V92.9929H134.5V91.9929H133.5ZM31.5 41C31.5 33.268 37.768 27 45.5 27V25C36.6634 25 29.5 32.1634 29.5 41H31.5ZM31.5 76.9929V41H29.5V76.9929H31.5ZM45.5 90.9929C37.768 90.9929 31.5 84.7249 31.5 76.9929H29.5C29.5 85.8295 36.6634 92.9929 45.5 92.9929V90.9929ZM121.168 90.9929H45.5V92.9929H121.168V90.9929ZM133.884 103.497L121.881 91.2918L120.455 92.6941L132.458 104.9L133.884 103.497ZM132.171 91.9929L132.171 104.199H134.171L134.171 91.9929H132.171ZM133.5 90.9929H133.171V92.9929H133.5V90.9929ZM132.5 41V91.9929H134.5V41H132.5ZM118.5 27C126.232 27 132.5 33.268 132.5 41H134.5C134.5 32.1634 127.337 25 118.5 25V27ZM45.5 27H118.5V25H45.5V27Z"
        fill="#E3E3E8"
        mask="url(#path-1-inside-1_588_48308)"
      />
      <rect x="43.5156" y="44.4785" width="76.967" height="1.31986" fill="#E3E3E8" />
      <rect x="43.5156" y="57.0176" width="76.967" height="1.31986" fill="#E3E3E8" />
      <rect x="43.5156" y="69.5547" width="63.9506" height="1.31986" fill="#E3E3E8" />
    </g>
  </svg>
)
export default IcChatEmpty

import { useMutation } from 'react-query'
import useAuth from './useAuth'
import { useState } from 'react'
import { getExportNotes } from 'api'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'

const useExportNotes = () => {
  const { getAccessToken } = useAuth()
  const [exportNotesModal, setExportNotesModal] = useState({
    show: false,
    caseDetail: null
  })
  const exportNotesMutation = useMutation({
    mutationKey: ['getExportNotes'],
    mutationFn: (caseId) => getExportNotes(getAccessToken(), caseId),
    onSuccess: (res) => {
      const downloadURL = window.URL.createObjectURL(new Blob([res?.data]))
      const link = document.createElement('a')
      link.href = downloadURL
      link.download = `${exportNotesModal?.caseDetail?.name}_Notes.docx`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(downloadURL)
      showSuccessMessage('Notes exported')
      closeExportNotesModal()
    },
    onError: () => {
      showErrorMessage('Notes could not be exported')
    }
  })
  function openExportNotesModal(caseDetail) {
    setExportNotesModal((prev) => ({ ...prev, caseDetail: caseDetail, show: true }))
  }
  function closeExportNotesModal() {
    setExportNotesModal((prev) => ({ ...prev, caseDetail: null, show: false }))
  }
  function handleExportNotes() {
    exportNotesMutation.mutate(exportNotesModal.caseDetail?.id)
  }
  return {
    exportNotesMutation,
    exportNotesModal,
    openExportNotesModal,
    closeExportNotesModal,
    handleExportNotes
  }
}

export default useExportNotes

import { zodResolver } from '@hookform/resolvers/zod'
import CustomButton from 'components/atoms/CustomButton'
import DatePickerFields from 'components/molecules/DatePickerFields'
import FilterSidebar from 'components/molecules/FilterSidebar'
import MultipleSelectFields from 'components/molecules/MultipleSelectFields'
import SelectFields from 'components/molecules/SelectFields'
import { ROLE_ADMIN, ROLE_USER } from 'constant'
import { useAuth } from 'hooks'
import moment from 'moment'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

const schema = z.object({
  company: z.number().array().optional(),
  role: z.string().array().optional(),
  typeDate: z.string().nullable().optional(),
  startDate: z.instanceof(moment).optional(),
  endDate: z.instanceof(moment).optional()
})

const FilterUsersModal = ({ show, onClose, onSubmit, resetFilter, companyList, filterState }) => {
  const { t } = useTranslation()
  const { adminData } = useAuth()
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    control,
    setError,
    watch
  } = useForm({
    defaultValues: {
      company: filterState.company || undefined,
      role: filterState.role || undefined,
      typeDate: filterState.typeDate || null,
      startDate: filterState.startDate || undefined,
      endDate: filterState.endDate || undefined
    },
    values: {
      company: filterState.company || undefined,
      role: filterState.role || undefined,
      typeDate: filterState.typeDate || null,
      startDate: filterState.startDate || undefined,
      endDate: filterState.endDate || undefined
    },
    mode: 'onChange',
    resolver: zodResolver(schema)
  })
  const watchCompanies = watch('company')

  const watchRole = watch('role')
  const watchTypeDate = watch('typeDate')
  const staticDate = [
    {
      label: t('Less than'),
      value: 'less_than'
    },
    {
      label: t('Greater than'),
      value: 'greater_than'
    },
    {
      label: t('Between'),
      value: 'between'
    }
  ]

  function processForm(data) {
    let newData = data
    let valid = true
    // IF typedate not selected while startdate has value
    if (watchTypeDate == undefined && data.startDate) {
      return setError('typeDate', { message: t('Required') })
    }
    // if typedate not between but enddate has value
    if (watchTypeDate !== 'between' && getValues('endDate')) {
      newData = { ...newData, endDate: undefined }
    }
    // if typedate has value but start date n end date don't have value
    if (watchTypeDate && !data.startDate && !data.endDate) {
      newData = { ...newData, typeDate: undefined }
    }
    // if typedate value is between but enddate or start date doesn't has value
    if (watchTypeDate === 'between') {
      if (!data.startDate) {
        valid = false
        setError('startDate', { message: t('Required') })
      }
      if (!data.endDate) {
        valid = false
        setError('endDate', { message: t('Required') })
      }
    }

    if (valid) {
      onSubmit(newData)
      onClose()
    }
  }
  function resetForm() {
    reset()
    resetFilter()
    onClose()
    reset({ company: undefined })
  }

  return (
    <FilterSidebar
      show={show}
      onClose={() => {
        reset(), onClose()
      }}>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-4">
          {adminData?.role?.toUpperCase() !== ROLE_ADMIN ? (
            <MultipleSelectFields
              labelClassName={'!text-white'}
              control={control}
              state={watchCompanies}
              isShowTotalSelect
              totalSelectedItems={watchCompanies?.length}
              label={t('Company')}
              showSearch
              placeholder={'Choose company'}
              errors={errors}
              name={'company'}
              options={companyList}
            />
          ) : null}
          <MultipleSelectFields
            labelClassName={'!text-white'}
            control={control}
            placeholder={t('Choose Role')}
            label={t('Role')}
            state={watchRole}
            errors={errors}
            name={'role'}
            options={[
              {
                label: 'Admin',
                value: ROLE_ADMIN
              },
              {
                label: 'User',
                value: ROLE_USER
              }
            ]}
          />
          <SelectFields
            labelClassName={'!text-white'}
            control={control}
            label={t('Date')}
            placeholder={t('Choose date')}
            errors={errors}
            name={'typeDate'}
            options={staticDate}
          />

          <DatePickerFields
            labelClassName={'!text-white'}
            control={control}
            name={'startDate'}
            placeholder={t('Choose date')}
            errors={errors}
          />
          {watchTypeDate === 'between' && (
            <DatePickerFields
              labelClassName={'!text-white'}
              control={control}
              name={'endDate'}
              placeholder={t('Choose date')}
              errors={errors}
            />
          )}
        </div>
        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" onClick={resetForm}>
            {t('Remove Filter')}
          </CustomButton>
          <CustomButton buttonType="submit" onClick={handleSubmit(processForm)}>
            {t('Apply')}
          </CustomButton>
        </div>
      </div>
    </FilterSidebar>
  )
}

export default FilterUsersModal

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/thumbnail/lib/styles/index.css'
import '@react-pdf-viewer/bookmark/lib/styles/index.css'
import React, { useEffect, useState } from 'react'
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { searchPlugin } from '@react-pdf-viewer/search'
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail'
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark'
import { zoomPlugin } from '@react-pdf-viewer/zoom'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { getFilePlugin } from '@react-pdf-viewer/get-file'
import { Breadcrumb, Dropdown, Popover, Tag, Typography } from 'antd'
import {
  AddCircleOutlineOutlined,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVertOutlined,
  PostAdd,
  RemoveCircleOutlineOutlined,
  SaveAlt,
  Search as SearchIcon,
  SwapVert
} from '@mui/icons-material'
import { ROLE_ADMIN, ROLE_USER } from 'constant'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const DocumentPdf = ({
  bookmarks = [],
  caseName,
  filename,
  fileUrl,
  isEligible,
  onRename = () => {},
  onDelete = () => {},
  onOpenAsChat = () => {},
  onExport = () => {},
  onAddNote = () => {},
  handleExportDocument
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  
  const [showSearch, setShowSearch] = useState(false)
  const [showThumbnail, setShowTumbnail] = useState(true)
  const thumbnailPluginInstance = thumbnailPlugin()
  const { Thumbnails } = thumbnailPluginInstance
  const bookmarkPluginInstance = bookmarkPlugin()
  // const { Bookmarks } = bookmarkPluginInstance
  const searchPluginInstance = searchPlugin()
  const { Search } = searchPluginInstance
  const zoomPluginInstance = zoomPlugin()
  const { Zoom, CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance
  const pageNavigationInstance = pageNavigationPlugin()
  const { CurrentPageLabel, jumpToPage } = pageNavigationInstance
  const getFilePluginInstance = getFilePlugin()
  const { Download } = getFilePluginInstance

  const optionsPopover = [
    {
      title: 'Rename',
      callback: onRename,
      role: [ROLE_ADMIN, ROLE_USER]
    },
    {
      title: 'Delete',
      callback: onDelete,
      role: [ROLE_ADMIN]
    },
    {
      title: 'Open as chat',
      callback: onOpenAsChat,
      role: [ROLE_ADMIN, ROLE_USER]
    }
  ]

  const items = [
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(SpecialZoomLevel.ActualSize)}>
              <Typography.Text>{t('Actual Size')}</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: 'actual_size'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(SpecialZoomLevel.PageFit)}>
              <Typography.Text>{t('Page Fit')}</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: 'page_fit'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(SpecialZoomLevel.PageWidth)}>
              <Typography.Text>{t('Page Width')}</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: 'page_width'
    },
    {
      type: 'divider'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(0.1)}>
              <Typography.Text>10%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '0.1'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(0.3)}>
              <Typography.Text>30%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '0.3'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(0.5)}>
              <Typography.Text>50%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '0.5'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(0.8)}>
              <Typography.Text>80%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '0.8'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(1.0)}>
              <Typography.Text>100%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '1'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(1.5)}>
              <Typography.Text>150%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '1.5'
    },
    {
      label: (
        <Zoom>
          {(renderZoomProps) => (
            <div onClick={() => renderZoomProps.onZoom(2.0)}>
              <Typography.Text>200%</Typography.Text>
            </div>
          )}
        </Zoom>
      ),
      key: '2'
    }
  ]

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
      <div className="overflow-hidden flex-1 h-full pt-4 bg-white border-solid border-[#E3E3E8] border rounded-3xl no-scrollbar relative">
        <div className="border-solid border-[#E3E3E8] border-t-0 border-x-0 px-6 pb-3 flex justify-between items-end">
          <div>
            <Breadcrumb
              items={[
                {
                  title: <span className='cursor-pointer hover:underline'>{t('Cases')}</span>,
                  onClick: () => navigate('/cases', { replace: true })
                },
                {
                  title: <span className='cursor-pointer hover:underline'>{caseName}</span>,
                  onClick: () => navigate(-1)
                },
                {
                  title: <span>{t('Documents')}</span>
                }
              ]}
            />
            <Typography.Text className="font-semibold text-3xl">{filename}</Typography.Text>
          </div>
          <div className="flex items-center mb-1">
            {isEligible && (
              <Tag color="green" className="rounded-xl px-3 mr-0">
                <Typography.Text className="font-semibold text-green-600">{t('Eligible')}</Typography.Text>
              </Tag>
            )}
            {!isEligible && (
              <Tag color="red" className="rounded-xl px-3 mr-0">
                <Typography.Text className="font-semibold text-red-600">{t('Ineligible')}</Typography.Text>
              </Tag>
            )}
            <Popover
              content={
                <div className="w-[128px] h-full flex flex-col text-black">
                  {
                    optionsPopover.map((option, index) => (
                      // option.role.includes(platformData?.role?.toUpperCase()) && (
                      <div
                        key={index}
                        onClick={option.callback}
                        className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                        {t(option.title)}
                      </div>
                    ))
                    // )
                  }
                </div>
              }
              trigger="click"
              placement="bottomRight"
              arrow={false}>
              <MoreVertOutlined
                fontSize="small"
                className={`cursor-pointer hover:text-[#0049EE] ml-4`}
              />
            </Popover>
          </div>
        </div>
        <div className="flex h-full">
          <div className="w-[200px] overflow-y-scroll h-full no-scrollbar">
            <div className="w-full px-4">
              {showThumbnail ? (
                <Thumbnails />
              ) : (
                <div>
                  <div className="font-semibold text-xl text-black text-center my-5">{t('Index')}</div>
                  {bookmarks?.length < 1 && (
                    <Typography.Text>{t('There is no index on document')}</Typography.Text>
                  )}
                  {bookmarks?.map((bookmark, item) => (
                    <div
                      key={`bookmark-doc-${item}`}
                      className="mb-4 hover:cursor-pointer hover:bg-[#DEDEDE] p-1 rounded-sm"
                      onClick={() => jumpToPage(bookmark.page_from)}>
                      <li>
                        <Typography.Text>{bookmark?.title}</Typography.Text>
                      </li>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="h-[150px] w-full" />
          </div>
          <div className="flex-1 h-full overflow-y-scroll border-solid border-[#E3E3E8] border-t-0 border-r-0 border-b-0 no-scrollbar">
            <Viewer
              enableSmoothScroll={true}
              defaultScale={1.0}
              fileUrl={fileUrl}
              plugins={[
                searchPluginInstance,
                thumbnailPluginInstance,
                bookmarkPluginInstance,
                zoomPluginInstance,
                pageNavigationInstance,
                getFilePluginInstance
              ]}
            />
            <div className="h-[150px] w-full" />
          </div>
        </div>
        <div className="absolute bottom-4 left-0 right-0 flex items-end bg-transparent">
          <div className="w-[200px]">
            <div className="bg-[#EDEDEF] flex p-[1px] rounded-lg mx-6 gap-[1px]">
              <div
                onClick={() => setShowTumbnail(false)}
                className={`rounded-lg h-12 flex-1 flex items-center justify-center hover:cursor-pointer hover:bg-white ${
                  !showThumbnail && 'bg-white'
                }`}>
                <Typography.Text
                  className={`${!showThumbnail ? 'text-[#595961]' : 'text-[#828282]'}`}>
                  Index
                </Typography.Text>
              </div>
              <div
                onClick={() => setShowTumbnail(true)}
                className={`rounded-lg h-12 flex-1 flex items-center justify-center hover:cursor-pointer hover:bg-white ${
                  showThumbnail && 'bg-white'
                }`}>
                <Typography.Text
                  className={`${showThumbnail ? 'text-[#595961]' : 'text-[#828282]'}`}>
                  {t('Pages')}
                </Typography.Text>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col">
            {showSearch && (
              <Search>
                {(renderSearchProps) => {
                  const [readyToSearch, setReadyToSearch] = useState(false)
                  const [keyword, setKeyword] = useState('')
                  useEffect(() => {
                    if (renderSearchProps.keyword !== '') {
                      renderSearchProps.search()
                    }
                  }, [renderSearchProps.keyword])
                  return (
                    <div className="mx-4 flex items-center rounded-xl border border-solid border-[#E3E3E8] w-[350px] mb-2 self-end justify-between px-2 bg-white">
                      <div className="flex justify-between flex-1 backdrop-blur">
                        <input
                          className="outline-none border-none flex-1 bg-transparent"
                          placeholder={t("Enter to search")}
                          type="text"
                          value={renderSearchProps.keyword}
                          onChange={(e) => {
                            renderSearchProps.setKeyword(e.target.value)
                            setKeyword(e.target.value)
                            if (e.target.value === '') {
                              setReadyToSearch(false)
                            } else {
                              setReadyToSearch(true)
                            }
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.keyCode === 13 &&
                              renderSearchProps.keyword &&
                              renderSearchProps.keyword !== keyword
                            ) {
                              setReadyToSearch(true)
                              renderSearchProps.search()
                            } else {
                              setReadyToSearch(true)
                              renderSearchProps.jumpToNextMatch()
                            }
                          }}
                        />
                        <div className="mx-2">
                          {readyToSearch &&
                            renderSearchProps.keyword &&
                            renderSearchProps.numberOfMatches === 0 && <span>{t('Not Found')}</span>}
                          {readyToSearch &&
                            renderSearchProps.keyword &&
                            renderSearchProps.numberOfMatches > 0 && (
                              <span>
                                {renderSearchProps.currentMatch} /{' '}
                                {renderSearchProps.numberOfMatches}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="border border-solid border-[#E3E3E8] border-r-0 border-y-0 flex justify-end self-end py-2 items-center">
                        <div className="flex items-center mr-5">
                          <div
                            className="group group-hover:text-[#0049EE] hover:cursor-pointer ml-2"
                            onClick={() => renderSearchProps.jumpToNextMatch()}>
                            <KeyboardArrowDown className="hover:text-[#0049EE] hover:cursor-pointer" />
                          </div>
                          <div
                            className="group group-hover:text-[#0049EE] hover:cursor-pointer ml-2"
                            onClick={() => renderSearchProps.jumpToPreviousMatch()}>
                            <KeyboardArrowUp className="hover:text-[#0049EE] hover:cursor-pointer" />
                          </div>
                        </div>
                        <Close
                          className="hover:cursor-pointer hover:text-[#0049EE]"
                          onClick={() => setShowSearch((prev) => !prev)}
                        />
                      </div>
                    </div>
                  )
                }}
              </Search>
            )}
            <div className="mx-4 flex justify-between items-center p-2 rounded-xl border border-solid border-[#E3E3E8] backdrop-blur">
              <div className="flex-1 flex gap-2">
                <Download>
                  {(renderDownloadProps) => (
                    <div onClick={renderDownloadProps.onClick} className="hover:cursor-pointer">
                      <SaveAlt className="hover:cursor-pointer hover:text-[#0049EE]" />
                    </div>
                  )}
                </Download>
                <div className="hover:cursor-pointer" onClick={onExport}>
                  <SwapVert className="hover:cursor-pointer hover:text-[#0049EE]" />
                </div>
              </div>
              <div className="flex-2 flex items-center justify-center">
                <div className="mr-4">
                  <CurrentPageLabel>
                    {(renderCurrentPageLabelProps) => (
                      <span>
                        <span>{renderCurrentPageLabelProps.currentPage + 1}</span>
                        <span className="mx-2">/</span>
                        <span>{renderCurrentPageLabelProps.numberOfPages}</span>
                      </span>
                    )}
                  </CurrentPageLabel>
                </div>
                <ZoomIn>
                  {(renderZoomInProps) => (
                    <div onClick={renderZoomInProps.onClick} className="mx-2 hover:cursor-pointer">
                      <AddCircleOutlineOutlined className="hover:cursor-pointer hover:text-[#0049EE]" />
                    </div>
                  )}
                </ZoomIn>
                <div className="flex items-center">
                  <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    placement="top"
                    arrow={{
                      pointAtCenter: true
                    }}>
                    <div
                      className="hover:cursor-pointer hover:bg-[#ededef] flex items-center justify-between pl-2 pr-1 py-1 rounded-md"
                      onClick={(e) => e.preventDefault()}>
                      <CurrentScale />
                      <KeyboardArrowDown className="self-end text-end" />
                    </div>
                  </Dropdown>
                </div>
                <ZoomOut>
                  {(renderZoomOutProps) => (
                    <div onClick={renderZoomOutProps.onClick} className="mx-2 hover:cursor-pointer">
                      <RemoveCircleOutlineOutlined className="hover:cursor-pointer hover:text-[#0049EE]" />
                    </div>
                  )}
                </ZoomOut>
              </div>
              <div className="flex-1 flex self-end justify-end gap-2">
                <div
                  className="hover:cursor-pointer"
                  onClick={() => setShowSearch((prev) => !prev)}>
                  <SearchIcon
                    className={`hover:cursor-pointer hover:text-[#0049EE] ${
                      showSearch && 'text-[#0049EE]'
                    }`}
                  />
                </div>
                <div
                  className="hover:cursor-pointer"
                  onClick={onAddNote}>
                  <PostAdd className="hover:cursor-pointer hover:text-[#0049EE]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Worker>
  )
}

export default DocumentPdf

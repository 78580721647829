import { Checkbox } from '@mui/material'
import { Tooltip } from 'antd'
import CustomButton from 'components/atoms/CustomButton'
import Table from 'components/atoms/Table'
import humanFileSize from 'lib/getHumanFileSize'
import moment from 'moment'
import React from 'react'
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate }
const rowDisabled = (row) => !row.is_eligible
const columnsSpecificCases = [
  {
    id: 'filename',
    name: 'Name',
    cell: (row) => (
      <div className="flex gap-2 items-center">
        <Tooltip title={row?.filename}>
          <span className="line-clamp-1">{row?.filename}</span>
        </Tooltip>
      </div>
    ),
    width: '40%',
    selector: (row) => row?.filename,
    sortable: true
  },
  {
    id: 'created_at',
    name: 'Date',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY'),
    cell: (row) => (
      <div>
        <Tooltip title={moment(row?.created_at).format('DD/MM/YYYY')}>
          <span className="line-clamp-1">{moment(row?.created_at).format('DD/MM/YYYY')}</span>
        </Tooltip>
      </div>
    ),
    sortable: true
  },
  {
    id: 'size',
    name: 'Size',
    selector: (row) => humanFileSize(row?.size),
    sortable: true
  },
  {
    id: 'is_eligible',
    name: 'Eligibility',
    selector: (row) => row?.is_eligible,
    cell: (row) => (
      <Tooltip placement="right" title={row?.is_eligible ? 'Eligible' : 'Ineligible'}>
        <div
          className={`w-2 h-2 rounded-full ${
            row.is_eligible ? 'bg-[#00B47E]' : 'bg-[#E53E3E]'
          }`}></div>
      </Tooltip>
    ),
    sortable: true
  }
]
const SelectDocsNewChatTable = ({
  isFetchingDocumentByCase,
  handleSort,
  totalFilterActive,
  tableState,
  handleChangeSelectedDocuments,
  documentListByCase,
  selectedDocuments,
  isConfirming,
  closeModal,
  handleConfirmNewChat
}) => {
  return (
    <>
      <Table
        pending={isFetchingDocumentByCase}
        highlightOnHover
        backgroundColorHeadCells="#F7F7F8"
        onSort={handleSort}
        noDataComponent={
          <div className="flex w-full justify-start items-center text-[#828282] p-4">
            {totalFilterActive > 0 || tableState.search
              ? 'No documents available'
              : 'There are no documents uploaded in this folder'}
          </div>
        }
        defaultSortFieldId={'created_at'}
        defaultSortAsc={false}
        sortServer
        selectableRows={true}
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={selectProps}
        onSelectedRowsChange={handleChangeSelectedDocuments}
        selectableRowDisabled={rowDisabled}
        selectableRowsHighlight={true}
        fixedHeader
        className={'flex-auto h-[300px] overflow-y-auto pr-6'}
        hidePagination
        columns={columnsSpecificCases}
        data={documentListByCase}
      />
      {documentListByCase.length > 0 ? (
        <div className="flex w-full py-4 pl-3">
          <span className="text-[#0049EE] text-small">
            {selectedDocuments?.length} documents selected
          </span>
        </div>
      ) : null}
      <div className="flex justify-end items-center gap-4">
        <CustomButton
          buttonColor="secondary"
          disabled={isConfirming}
          onClick={isConfirming ? () => {} : closeModal}>
          Cancel
        </CustomButton>
        <CustomButton
          buttonColor={selectedDocuments.length > 0 ? 'primary' : 'secondary'}
          disabled={selectedDocuments.length > 0 ? false : true}
          isLoading={isConfirming}
          onClick={isConfirming ? () => {} : handleConfirmNewChat}>
          Confirm
        </CustomButton>
      </div>
    </>
  )
}

export default SelectDocsNewChatTable

import { LogoFull, LogoMinimized } from 'assets/images'
import React from 'react'

const LogoContainer = ({ isFullLogo = false }) => {
  return isFullLogo ? (
    <img src={LogoFull} alt="logo" className="object-cover h-full w-full" />
  ) : (
    <div className="w-14 h-14 rounded-2xl bg-blue-700 flex justify-center items-center">
      <img src={LogoMinimized} alt="logo" className="object-contain w-full h-full p-1.5 mr-1" />
    </div>
  )
}

export default LogoContainer

import Table from 'components/atoms/Table'
import React from 'react'

import CustomButton from 'components/atoms/CustomButton'

const UploadDocsNewChatTable = ({
  updates,
  isConfirming,
  uploadListDocument,
  columnsUploadDocs,
  handleConfirmNewChat,
  closeModal
}) => {
  return (
    <>
      <Table
        backgroundColorHeadCells="#F7F7F8"
        fixedHeader
        noDataComponent={
          <div className="flex w-full justify-start items-center text-[#828282] p-4">
            No documents available
          </div>
        }
        className={'flex-auto h-[300px] overflow-y-auto pr-6'}
        hidePagination
        columns={columnsUploadDocs}
        data={uploadListDocument}
      />
      {Object.entries(updates).length > 0 ? (
        <div className="flex w-full py-4 pl-3">
          <span className="text-[#0049EE] text-small">
            {Object.entries(updates).filter(([, value]) => value?.isEligible).length} analyzable
            documents
          </span>
        </div>
      ) : null}
      <div className="flex justify-end items-center gap-4">
        <CustomButton
          buttonColor="secondary"
          disabled={isConfirming}
          onClick={isConfirming ? () => {} : closeModal}>
          Cancel
        </CustomButton>
        <CustomButton
          buttonColor={
            Object.entries(updates).filter(([, value]) => value?.docId !== undefined)?.length ==
              Object.entries(updates)?.length &&
            Object.entries(updates).filter(([, value]) => value?.isEligible).length > 0
              ? 'primary'
              : 'secondary'
          }
          disabled={
            Object.entries(updates).filter(([, value]) => value?.docId !== undefined)?.length ==
              Object.entries(updates)?.length &&
            Object.entries(updates).filter(([, value]) => value?.isEligible).length > 0
              ? false
              : true
          }
          isLoading={isConfirming}
          onClick={isConfirming ? () => {} : handleConfirmNewChat}>
          Confirm
        </CustomButton>
      </div>
    </>
  )
}

export default UploadDocsNewChatTable

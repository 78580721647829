import React, { useRef, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'

const RegulationDialogReference = ({ open, handleClose = () => {}, item }) => {
  const descriptionElementRef = useRef(null)

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const returnBreakLine = (text) => {
    // convert \n to <br>
    return text?.replace(/\n\n/g, '<br>').replace(/\n/g, '<br>') || ''
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '900px',
            maxWidth: '900px',
            borderRadius: '16px',
            position: 'relative',
            overflow: 'hidden'
          }
        }
      }}>
      <Box
        sx={{
          position: 'relative',
          padding: '24px'
        }}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 1,
            backgroundColor: 'white',
            // set hover color
            '&:hover': {
              backgroundColor: 'white'
            }
          }}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ overflow: 'auto', maxHeight: '528px' }}>
          <Box className="pr-8 pl-8 pb-8">
            {item &&
              item.map((data, index) => (
                <div key={index} data-index={index} className="mt-5 first:mt-0">
                  <div>
                    <p className="text-[30px] font-[600] mb-5">{data.filename}</p>
                    {/* <p className="text-[16px]">{data.content}</p> */}
                    <p
                      className="text-[16px]"
                      dangerouslySetInnerHTML={{
                        __html: returnBreakLine(data.content)
                      }}
                    />
                  </div>
                </div>
              ))}
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default RegulationDialogReference

import { rootService } from 'api/api-root'
import { apiResponse } from 'api/api-response'

export const apiGet = async ({ token, data, url, isExportDocument }) => {
  let headers
  let obj = apiResponse()

  if (token) {
    if (isExportDocument) {
      headers = {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        Authorization: `Bearer ${token}`
      }
    } else {
      headers = {
        // 'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  } else {
    headers = {
      // 'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  const res = await rootService.get(`/${url}`, {
    headers,
    responseType: isExportDocument ? 'blob' : 'json',
    params: data
  })
  if (isExportDocument) return res

  obj.resData = res.data
  obj.resStatus = res.status
  return obj
}

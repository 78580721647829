import React from 'react'
import { Col, Row, Typography } from 'antd'
import CustomButton from 'components/atoms/CustomButton'
import { useTranslation } from 'react-i18next'

const CompanyGptKeyInfo = ({ gptKey, onChangeGptKey }) => {
  const { t } = useTranslation()
  return (
    <Row className="mb-4">
      <Col span={6} className="pl-4 pr-2">
        <Typography.Title className="text-h2 font-manrope !text-white">
          {t('Activation')}
        </Typography.Title>
      </Col>
      <Col span={12} className="px-2">
        <div className="mb-8">
          <div>
            <Typography.Text className="font-manrope font-semibold text-[#7A7A7A] text-md">
              {t('SECRET GPT KEY')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="font-manrope font-medium text-[#FFF] text-lg">
              {gptKey}
            </Typography.Text>
          </div>
        </div>
      </Col>
      <Col span={6} className="pr-4 pl-2 text-right">
        <div className="mb-8">
          <CustomButton buttonColor={'secondary'} onClick={onChangeGptKey}>
            {t('Change Key')}
          </CustomButton>
        </div>
      </Col>
    </Row>
  )
}

export default CompanyGptKeyInfo

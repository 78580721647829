import { Typography } from 'antd'
import React, { useEffect, useMemo } from 'react'
import FilterButton from 'components/atoms/FilterButton'
import SearchInput from 'components/atoms/SearchInput'
import CustomButton from 'components/atoms/CustomButton'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
const AdminHeader = ({
  title,
  openFilter,
  hideFunc,
  onAdd,
  onSearch,
  rightComponent,
  className,
  filterCount = 0
}) => {
  const { t } = useTranslation()
  const handleSearch = (e) => {
    onSearch(e.target.value)
  }
  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })
  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300)
  }, [])

  return (
    <div className={`flex items-center justify-between ${className}`}>
      <Typography.Title className="text-h2 font-manrope !text-white">{title}</Typography.Title>
      {rightComponent}
      {!hideFunc ? (
        <div className="flex gap-2">
          {/* SearchField */}
          <SearchInput onSearch={debouncedResults} />
          {/* Filter Button */}
          <FilterButton onClick={openFilter} filterCount={filterCount} />
          {/* Add Button */}
          <CustomButton onClick={onAdd}>{t('Add')}</CustomButton>
        </div>
      ) : null}
    </div>
  )
}

export default AdminHeader

import { zodResolver } from '@hookform/resolvers/zod'
import CustomButton from 'components/atoms/CustomButton'
import DatePickerUserFields from 'components/molecules/user/DatePickerUserFields'
import ModalUser from 'components/molecules/user/ModalUser'
import TimePickerUserFields from 'components/molecules/user/TimePickerUserFields'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
const schema = z.object({
  date: z.object({
    fromDate: z.string().optional(),
    toDate: z.string().optional(),
  }),
  time: z.object({
    fromTime: z.string().optional(),
    toTime: z.string().optional()
  }),
})
const NotesTodoFilter = ({
  show,
  onClose,
  data,
  onSubmit,
  onClear
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: {
      date: {
        fromDate: data?.date?.value?.fromDate || '',
        toDate: data?.date?.value?.toDate || '',
      },
      time: {
        fromTime: data?.time?.value?.fromTime || '',
        toTime: data?.time?.value?.toTime || ''
      }
    },
    resolver: zodResolver(schema)
  })

  async function processForm(data) {
    let newDateFilterValue = null
    let newTimeFilterValue = null
    if (data.date.fromDate !== '' || data.date.toDate !== '') {
      newDateFilterValue = Object.fromEntries(
        Object.entries(data).filter(([, value]) => value !== '')
      )
    }

    if (data.date.fromDate !== 'Invalid date' || data.date.toDate !== 'Invalid date') {
      newDateFilterValue = Object.fromEntries(
        Object.entries(data.date).filter(([, value]) => value !== 'Invalid date')
      )
    }

    newDateFilterValue = Object.fromEntries(Object.entries(newDateFilterValue).filter(([, v]) => v != undefined && v != ''))

    if (data.time.fromTime !== '' || data.time.toTime !== '') {
      newTimeFilterValue = Object.fromEntries(
        Object.entries(data.time).filter(([, value]) => value !== '')
      )
    }

    if (data.time.fromTime !== 'Invalid time' || data.time.toTime !== 'Invalid time') {
      newTimeFilterValue = Object.fromEntries(
        Object.entries(data.time).filter(([, value]) => value !== 'Invalid time')
      )
    }
    
    newTimeFilterValue = Object.fromEntries(Object.entries(newTimeFilterValue).filter(([, v]) => v != undefined && v != ''))

    function isEmpty(obj) {
      for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
          return false;
        }
      }
    
      return true;
    }

    const newObjectFilter = {
      ...data,
      date: { value: isEmpty(newDateFilterValue) ? null : newDateFilterValue },
      time: { value: isEmpty(newTimeFilterValue) ? null : newTimeFilterValue }
    }

    await onSubmit(newObjectFilter)
  }
  function closeModal() {
    reset()
    onClose()
  }

  return (
    <ModalUser modalClassName={''} onClose={closeModal} title={'Filters'} show={show} zIndexPopupBase={1300}>
      {/* Regulation */}
      <div className="h-full md:h-[528px] pt-1 flex flex-col justify-between">
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">To do date</span>
            <div className="grid grid-cols-2 gap-8">
              <DatePickerUserFields
                control={control}
                label={'From'}
                name={'date.fromDate'}
                errors={errors}
              />
              <DatePickerUserFields
                control={control}
                label={'To'}
                name={'date.toDate'}
                errors={errors}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">To do time</span>
            <div className="grid grid-cols-2 gap-8">
              <TimePickerUserFields
                control={control}
                label={'From'}
                name={'time.fromTime'}
                errors={errors}
              />
              <TimePickerUserFields
                control={control}
                label={'To'}
                name={'time.toTime'}
                errors={errors}
              />
            </div>
          </div>
        </div>
        {/* Button Wrapper */}
        <div className="flex justify-end items-center gap-4">
          <CustomButton buttonColor="secondary" onClick={onClear}>
            Cancel Filter
          </CustomButton>
          <CustomButton onClick={handleSubmit(processForm)}>Apply</CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default NotesTodoFilter

import React, { useState } from 'react'
import { Typography } from 'antd'
import RegulationQueryCard from 'components/atoms/user/regulation/RegulationQueryCard'
const ListRegulationQuery = ({
  height,
  data,
  setData,
  setActiveData,
  onDelete = () => {},
  onExport = () => {}
}) => {
  return (
    <div className="w-full overflow-y-scroll py-2 " style={{ height: height }}>
      {data.map((dataReg, idx) => (
        <RegulationQueryCard
          key={`reg-${idx}`}
          className="last:mb-10"
          setData={setData}
          setActiveData={setActiveData}
          data={dataReg}
          onDelete={onDelete}
          onExport={onExport}
          {...dataReg}
        />
      ))}
    </div>
  )
}

export default ListRegulationQuery

import { LogoutOutlined, ShieldOutlined } from '@mui/icons-material'
import { CONTACTS_CONTENT, PROFILE_CONTENT, SECURITY_CONTENT } from 'constant'
import useAuth from 'hooks/useAuth'
import React, { useState } from 'react'
import LogoutModal from './LogoutModal'
import TCModal from '../TCModal'
import { showSuccessMessage } from 'utils/showMessage'
import useTCPP from 'hooks/useTCPP'
import { Popover } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { langActions } from 'store/reducers/lang'
import { useTranslation } from 'react-i18next'
const listStyle =
  'px-6 py-6 h-7 flex justify-start font-semibold text-[#595961] hover:text-white hover:bg-[#0049EE] items-center w-full rounded-md text-base cursor-pointer'
const SidebarProfile = ({ handleContent, currentContent }) => {
  const { onLogout } = useAuth()
  const [logoutModal, setLogoutModal] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const { handleSubmitTcModal, tcModal, acceptTC, isPPFetching, isTCFetching, openTcModal } =
    useTCPP()

  const selectedLang = useSelector(state => state.lang.selected)
  const dispatch = useDispatch()
  const { t } = useTranslation();

  function openLogoutModal() {
    setLogoutModal(true)
  }
  function closeLogoutModal() {
    setLogoutModal(false)
  }
  function handleSubmitLogout() {
    showSuccessMessage('Successful logout')
    return onLogout()
  }

  function updatePopover() {
    setShowPopover(prev => {
      return !prev
    })
  }

  const menuLanguage = {
    'ita': {
      key: 'ita',
      label: 'Italy',
      icon: <img
        className=' w-6 h-6 flex items-center justify-center rounded-full font-bold mr-2 hover:cursor-pointer'
        src={'https://flagpedia.net/data/flags/w1160/it.webp'}
      />
    },
    'eng': {
      key: 'eng',
      label: 'English',
      icon: <img
        className='w-6 h-6 flex items-center justify-center rounded-full font-bold mr-2 hover:cursor-pointer'
        src={'https://flagpedia.net/data/flags/w1160/gb.webp'}
      />
    }
  }

  return (
    <div className="flex flex-col justify-between flex-grow p-4 w-full">
      {/* Profile, Security & contacts */}
      <ul className="list-none w-full">
        <li
          onClick={handleContent.bind(this, 'profile')}
          className={`${listStyle} ${
            currentContent === PROFILE_CONTENT ? 'bg-[#0049EE] text-white' : ''
          }`}>
          {t("Profile-t")}
        </li>
        <li
          onClick={handleContent.bind(this, 'security')}
          className={`${listStyle} ${
            currentContent === SECURITY_CONTENT ? 'bg-[#0049EE] text-white' : ''
          }`}>
          {t("Security")}
        </li>
        <li
          onClick={handleContent.bind(this, 'contacts')}
          className={`${listStyle} ${
            currentContent === CONTACTS_CONTENT ? 'bg-[#0049EE] text-white' : ''
          }`}>
          {t("Contacts")}
        </li>
      </ul>
      {/* T&C , Logout */}
      <ul className="list-none w-full">
        <Popover
          placement="top"
          open={showPopover}
          content={
            <div className="w-[230px]">
              {Object.keys(menuLanguage).map(menu => (
                <div
                  className='flex p-2 px-3 mb-1 hover:bg-[#0049EE] rounded-md hover:cursor-pointer group'
                  key={`menu-sidebar-lang-${menuLanguage[menu].key}`}
                  onClick={() => {
                    updatePopover()
                    dispatch(langActions.setLanguage(menuLanguage[menu].key))
                  }}
                >
                  {menuLanguage[menu].icon}
                  <span className='ml-2 font-semibold text-[#595961] group-hover:text-white'>{menuLanguage[menu].label}</span>
                </div>
              ))}
            </div>
          }
          arrow={false}
          trigger="click"
        >
          <li className={`${listStyle} gap-2`} onClick={updatePopover}>
            {menuLanguage[selectedLang].icon}
            <span>{menuLanguage[selectedLang].label}</span>
          </li>
        </Popover>
        <li className={`${listStyle} gap-2`} onClick={openTcModal}>
          <ShieldOutlined /> <span>{t("Terms and Conditions-t")}</span>
        </li>
        <li className={`${listStyle} gap-2`} onClick={openLogoutModal}>
          <LogoutOutlined /> <span>{t("Logout-t")}</span>
        </li>
      </ul>
      {/* Modal Logout */}
      {logoutModal && (
        <LogoutModal
          show={logoutModal}
          onLogout={handleSubmitLogout}
          closeModal={closeLogoutModal}
        />
      )}
      {/* Modal T&C */}
      {tcModal.show && (
        <TCModal
          isFetching={isPPFetching || isTCFetching}
          isLoading={acceptTC.isLoading}
          pp={tcModal.pp}
          tc={tcModal.tc}
          show={tcModal.show}
          onClose={onLogout}
          onSubmit={handleSubmitTcModal}
          onLogout={onLogout}
        />
      )}
    </div>
  )
}

export default SidebarProfile

import React, { useState, useEffect, useCallback } from 'react'
import { Layout, Menu, ConfigProvider, Popover } from 'antd'
import { IcChevronDown, IcCompany, IcContact, IcPrivacyPolicy, IcTNC, IcUsers } from 'assets/icons'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { langActions } from 'store/reducers/lang'
import LogoContainer from 'components/atoms/LogoContainer'
import { ROLE_SUPER_ADMIN } from 'constant'

const { Sider } = Layout

const AdminTemplate = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { onLogout, adminData, getRoleUser } = useAuth()
  const [selectedKeyPath, setSelectedKeyPath] = useState([])
  const location = useLocation()
  const selectedLang = useSelector((state) => state.lang.selected)
  const dispatch = useDispatch()

  useEffect(() => {
    const location = window.location.pathname
    const newPaths = location.split('/')
    newPaths.splice(newPaths.indexOf('admin'), 1)
    const paths = []
    for (let i = newPaths.length - 1; i >= 0; i--) {
      if (newPaths[i] !== '') {
        paths.push(newPaths[i])
      }
    }
    if (paths.length === 0) {
      paths.push('companies')
    }
    onChangeSelectedKeyPath(paths)
  }, [location])

  const onChangeSelectedKeyPath = useCallback(
    (keyPath) => {
      setSelectedKeyPath(keyPath)
    },
    [selectedKeyPath]
  )

  const menuData = [
    {
      key: 'companies',
      icon: <IcCompany className={'text-white'} />,
      label: <NavLink state={adminData?.profile?.company} to="/admin/companies">{t('Companies')}</NavLink>
    },
    {
      key: 'users',
      icon: <IcUsers className={'text-white'} />,
      label: <NavLink to="/admin/users">{t('Users')}</NavLink>
    },
    getRoleUser() === ROLE_SUPER_ADMIN && 
    {
      key: 'contacts',
      icon: <IcContact className={'text-white'} />,
      label: <NavLink to="/admin/contacts">{t('Contacts')}</NavLink>
    },
    getRoleUser() === ROLE_SUPER_ADMIN && 
    {
      key: 'privacy-policy',
      icon: <IcPrivacyPolicy className={'text-white'} />,
      label: <NavLink to="/admin/privacy-policy">{t('Privacy Policy')}</NavLink>
    },
    getRoleUser() === ROLE_SUPER_ADMIN && 
    {
      key: 'terms-conditions',
      icon: <IcTNC className={'text-white'} />,
      label: <NavLink to="/admin/terms-conditions">{t('Terms and Conditions')}</NavLink>
    }
  ]

  const menuLanguage = {
    ita: {
      key: 'ita',
      label: 'Italy',
      icon: (
        <img
          className=" w-6 h-6 flex items-center justify-center rounded-full font-bold mr-2 hover:cursor-pointer"
          src={'https://flagpedia.net/data/flags/w1160/it.webp'}
        />
      )
    },
    eng: {
      key: 'eng',
      label: 'English',
      icon: (
        <img
          className="w-6 h-6 flex items-center justify-center rounded-full font-bold mr-2 hover:cursor-pointer"
          src={'https://flagpedia.net/data/flags/w1160/gb.webp'}
        />
      )
    }
  }

  const menuProfile = [
    {
      key: 'profile',
      icon: <IcCompany className={'text-white'} />,
      label: (
        <NavLink to="/admin/profile" onClick={() => setOpen(false)}>
          {t('Profile Admin')}
        </NavLink>
      )
    },
    {
      key: 'menu-logout',
      label: <div onClick={onLogout}>{t('Logout')}</div>,
      icon: <IcUsers className={'text-white'} />
    }
  ]

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            lightSiderBg: 'red',
            siderBg: '#000'
          },
          Menu: {
            itemBg: '#000',
            itemColor: '#5C5C5C',
            itemSelectedColor: '#FFF',
            itemSelectedBg: '#0F0F0F',
            itemHoverBg: '#0F0F0F',
            itemHoverColor: '#FFF',
            itemActiveBg: '#0F0F0F',
            itemMarginInline: 20,
            itemBorderRadius: 4
          },
          Popover: {
            colorBgElevated: '#1A1A1A',
            margin: 0
          }
        }
      }}>
      <Layout
        style={{ overflow: 'hidden', overflowY: 'hidden' }}
        className="flex h-screen flex-col">
        <Layout className="flex flex-1">
          <Sider color="#000" width={280} className=" overflow-x-hidden">
            <div className="m-6">
              <LogoContainer />
            </div>
            <Menu
              selectable
              defaultOpenKeys={selectedKeyPath}
              defaultSelectedKeys={selectedKeyPath}
              selectedKeys={selectedKeyPath}
              mode="inline"
              className="flex-1"
              style={{
                borderRight: 0
              }}
              items={menuData}
              onClick={(e) => onChangeSelectedKeyPath(e.keyPath)}
            />
            <div className="fixed bottom-0 my-4 w-[280px]">
              <Popover
                placement="top"
                content={
                  <ConfigProvider
                    theme={{
                      components: {
                        Menu: {
                          itemBg: '#1A1A1A',
                          itemSelectedBg: '#282828',
                          itemHoverBg: '#282828',
                          itemActiveBg: '#282828',
                          itemMarginInline: 0,
                          itemBorderRadius: 4
                        }
                      }
                    }}>
                    <div className="w-[230px] bg-[#1A1A1A]">
                      <Menu
                        selectable={false}
                        mode="inline"
                        style={{
                          borderRight: 0
                        }}
                        items={Object.keys(menuLanguage).map((menu) => {
                          return {
                            key: menuLanguage[menu].key,
                            label: menuLanguage[menu].label,
                            icon: menuLanguage[menu].icon
                          }
                        })}
                        onClick={(e) => dispatch(langActions.setLanguage(e.key))}
                      />
                    </div>
                  </ConfigProvider>
                }
                arrow={false}
                trigger="click">
                <div className="flex items-center w-[250px] mx-auto hover:bg-[#0F0F0F] p-2 py-3 rounded-md hover:cursor-pointer">
                  {menuLanguage[selectedLang].icon}
                  <label className="text-white flex-1 font-medium hover:cursor-pointer">
                    {menuLanguage[selectedLang].label}
                  </label>
                  <IcChevronDown
                    className="text-[#5C5C5C] hover:text-white hover:cursor-pointer"
                    size={14}
                  />
                </div>
              </Popover>
              <div className="flex items-center h-[1px] bg-[#5C5C5C] w-[250px] mx-auto" />
              <Popover
                placement="top"
                content={
                  <ConfigProvider
                    theme={{
                      components: {
                        Menu: {
                          itemBg: '#1A1A1A',
                          itemSelectedBg: '#282828',
                          itemHoverBg: '#282828',
                          itemActiveBg: '#282828',
                          itemMarginInline: 0,
                          itemBorderRadius: 4
                        }
                      }
                    }}>
                    <div className="w-[230px] bg-[#1A1A1A]">
                      <Menu
                        selectable={false}
                        defaultOpenKeys={selectedKeyPath}
                        defaultSelectedKeys={selectedKeyPath}
                        selectedKeys={selectedKeyPath}
                        mode="inline"
                        style={{
                          borderRight: 0
                        }}
                        items={menuProfile}
                      />
                    </div>
                  </ConfigProvider>
                }
                arrow={false}
                trigger="click"
                open={open}
                onOpenChange={() => setOpen(!open)}>
                <div className="flex items-center w-[250px] mx-auto hover:bg-[#0F0F0F] p-2 py-3 rounded-md hover:cursor-pointer">
                  <div className="bg-blue-700 w-6 h-6 flex items-center justify-center rounded-full text-white font-bold mr-2 hover:cursor-pointer text-[10px]">
                    {adminData?.profile?.name !== '' ? adminData?.profile?.name[0] : 'A'}{' '}
                    {adminData?.profile?.surname !== '' ? adminData?.profile?.surname[0] : ''}
                  </div>
                  <label className="text-white flex-1 font-medium hover:cursor-pointer">
                    {adminData?.profile?.name !== '' ? adminData?.profile?.name : 'Admin'}{' '}
                    {adminData?.profile?.surname !== '' ? adminData?.profile?.surname : ''}
                  </label>
                  <IcChevronDown
                    className="text-[#5C5C5C] hover:text-white hover:cursor-pointer"
                    size={14}
                  />
                </div>
              </Popover>
            </div>
          </Sider>
          <div className="w-full h-screen bg-black  flex-1 p-2 pl-0 overflow-y-auto">
            <div className="bg-[#FFFFFF0F] overflow-hidden grid grid-cols-1 h-full pt-4 pl-6 pr-3 rounded-2xl ">
              <Outlet />
            </div>
          </div>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default AdminTemplate

import { z } from 'zod'
export const LoginFormSchema = z.object({
  email: z.string().optional(),
  password: z.string().optional()
})
export const phoneNumberObject = z.object({
  countryCallingCode: z.string().optional(),
  countryCode: z.string().nullable().optional(),
  nationalNumber: z.string().optional(),
  numberType: z.string().nullable().optional(),
  numberValue: z.string().optional(),
  reason: z.string().optional()
})
export const addUserFormSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  surname: z.string().min(1, { message: 'Surname is required' }),
  email: z.string().email().min(1, { message: 'Email is required' }),
  phoneNumber: phoneNumberObject.or(z.string()).optional(),
  company: z.number().min(1, { message: 'Company is required' }),
  role: z.string().min(1, { message: 'Role is required' })
})

export const addCompanyFormSchema = z.object({
  gptKey: z.string().min(1, { message: 'Secret Gpt Key is required' }),
  companyName: z.string().min(1, { message: 'Company name is required' }),
  email: z.string().email().min(1, { message: 'email is required' }),
  phoneNumber: phoneNumberObject.or(z.string()).optional(),
  users: z.number().array().optional(),
  SDIReceiverCode: z.string().optional(),
  VATNumber: z.string().optional(),
  TaxCode: z.string().optional(),
  PECAddress: z.string().optional(),
  country: z.string().optional(),
  address: z.string().optional(),
  municipality: z.string().optional(),
  zipCode: z.string().optional(),
  province: z.string().optional()
})
export const editCompanyFormSchema = z.object({
  companyName: z.string().min(1, { message: 'Company name is required' }),
  email: z.string().email().min(1, { message: 'email is required' }),
  phoneNumber: z.string().min(1, { message: 'Telephone Number is required' }),
  SDIReceiverCode: z.string().optional(),
  VATNumber: z.string().optional(),
  TaxCode: z.string().optional(),
  PECAddress: z.string().optional(),
  country: z.string().optional(),
  address: z.string().optional(),
  municipality: z.string().optional(),
  zipCode: z.string().optional(),
  province: z.string().optional()
})

import React, { useState } from 'react'
import useAuth from './useAuth'
import { useMutation, useQuery } from 'react-query'
import { getPP, getTC, getTCByUser, putAcceptTC } from 'api'
import { errorObj } from 'utils/handleError'
import { showErrorMessage } from 'utils/showMessage'

const useTCPP = (isWorkspace = false) => {
  const { platformData, getAccessToken, handleAcceptTC } = useAuth()
  const [tcModal, setTcModal] = useState({ show: false, tc: null, pp: null })

  // T&C
  const { isFetching: isTCFetching } = useQuery({
    queryKey: ['getTC'],
    queryFn: () => (isWorkspace ? getTCByUser(getAccessToken()) : getTC(getAccessToken())),
    onSuccess: (res) => {
      if (
        res.resData.data?.data?.length !== 0 ||
        res.resData.data?.terms_and_conditions?.length !== 0
      ) {
        setTcModal((prev) => ({
          ...prev,
          show: true,
          tc: {
            ...res.resData.data,
            data: res.resData.data?.terms_and_conditions || res.resData.data?.data
          }
        }))
      }
    },
    onError: (err) => {
      const errObj = errorObj(err)
      showErrorMessage(errObj?.resData?.errors?.[0]?.message)
    },
    enabled: isWorkspace ? !platformData?.profile?.accepted_term_condition : tcModal.show
  })
  const { isFetching: isPPFetching } = useQuery({
    queryKey: ['getPP'],
    queryFn: () => getPP(getAccessToken()),
    onSuccess: (res) => {
      return setTcModal((prev) => ({
        ...prev,
        show: true,
        pp: res.resData.data
      }))
    },
    onError: (err) => {
      const errObj = errorObj(err)
      showErrorMessage(errObj?.resData?.errors?.[0]?.message)
    },
    enabled: isWorkspace ? !platformData?.profile?.accepted_term_condition : tcModal.show
  })
  const acceptTC = useMutation({
    mutationKey: 'putAcceptTC',
    mutationFn: () => putAcceptTC(getAccessToken()),
    onSuccess: () => {
      handleAcceptTC()
      closeTcModal()
    },
    onError: (err) => {
      const errobj = errorObj(err)
      showErrorMessage(errobj?.resData?.errors?.[0]?.message)
    }
  })
  function handleSubmitTcModal() {
    acceptTC.mutate()
  }
  function openTcModal() {
    setTcModal((prev) => ({ ...prev, show: true }))
  }
  function closeTcModal() {
    setTcModal((prev) => ({ ...prev, show: false }))
  }
  return {
    handleSubmitTcModal,
    tcModal,
    acceptTC,
    isPPFetching,
    isTCFetching,
    openTcModal
  }
}

export default useTCPP

import React from 'react'
import { Modal, ConfigProvider } from 'antd'

const ModalUser = ({
  title,
  show,
  onClose,
  width = 900,
  children,
  closable = false,
  modalClassName,
  centered = true,
  modalTheme = {},
  isCloseIconVisible,
  titleComponent,
  zIndexPopupBase = 1100,
  ...modalProps
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            headerBg: '#F7F7F8',
            titleColor: '#000000',
            titleFontSize: '30px',
            fontWeightStrong: '600',
            fontFamily: '"Manrope", sans-serif',
            contentBg: '#F7F7F8',
            colorIcon: '#828282',
            colorIconHover: '#000000',
            zIndexPopupBase:  zIndexPopupBase,
            ...modalTheme
          }
        }
      }}>
      <Modal
        className={modalClassName}
        {...modalProps}
        title={titleComponent ? titleComponent : <div className="pt-3 pb-8">{title}</div>}
        style={{ top: 20 }}
        width={width}
        open={show}
        centered
        footer={null}
        onCancel={onClose}
        closable={isCloseIconVisible}
        keyboard={false}
        maskClosable={closable}>
        {children}
      </Modal>
    </ConfigProvider>
  )
}
export default ModalUser

import { apiGet } from 'api/api-get'
import { urls } from 'api/api-url'

export const getPP = (token) => {
  return apiGet({
    token: token,
    url: urls.privacyPolicies.privacyPolicies
  })
}

export const getTC = (token) => {
  return apiGet({ url: urls.termsConditions.termsConditions, token: token })
}

export * from './auth'
export * from './workspace'
export * from './cases'
export * from './regulations'
export * from './chat'
export * from './document'

import ContactsPlatformContent from 'components/organisms/users/profile/ContactsPlatformContent'
import ProfilePlatformContent from 'components/organisms/users/profile/ProfilePlatformContent'
import SecurityPlatformContent from 'components/organisms/users/profile/SecurityPlatformContent'

import SidebarProfile from 'components/organisms/users/profile/SidebarProfile'
import { CONTACTS_CONTENT, PROFILE_CONTENT, SECURITY_CONTENT } from 'constant'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const navList = {
  profile: PROFILE_CONTENT,
  security: SECURITY_CONTENT,
  contacts: CONTACTS_CONTENT
}

const ProfilePlatformPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentContent = navList[location.pathname.replace('/', '')]

  function handleContent(value) {
    return navigate(`/${value}`)
  }

  return (
    // Wrapper grey
    <div className="w-full h-full flex flex-grow bg-[#EDEDEF] rounded-2xl">
      <div className="grid grid-cols-[300px_1fr] w-full h-full">
        {/* Sidebar */}
        <SidebarProfile handleContent={handleContent} currentContent={currentContent} />
        {/* Content */}
        <div className="bg-white flex flex-grow rounded-2xl border-solid border border-[#E3E3E8] p-8">
          {currentContent === PROFILE_CONTENT && <ProfilePlatformContent />}
          {currentContent === SECURITY_CONTENT && <SecurityPlatformContent />}
          {currentContent === CONTACTS_CONTENT && <ContactsPlatformContent />}
        </div>
      </div>
    </div>
  )
}

export default ProfilePlatformPage

import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox, FormControlLabel } from '@mui/material'
import CustomButton from 'components/atoms/CustomButton'
import DatePickerUserFields from 'components/molecules/user/DatePickerUserFields'
import ModalUser from 'components/molecules/user/ModalUser'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
const schema = z.object({
  fromDate: z.string().optional(),
  toDate: z.string().optional()
})
const ELIGIBILITY = 'eligibility'
const NewChatFilterModal = ({ show, onClose, data, resetFilter, onSubmit }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: {
      fromDate: data?.date?.value?.fromDate || '',
      toDate: data?.date?.value?.toDate || ''
    },
    resolver: zodResolver(schema)
  })
  const [filter, setFilter] = useState({ ...data })

  async function processForm(data) {
    let newDateFilterValue = null
    if (data.fromDate !== '' || data.toDate !== '') {
      newDateFilterValue = Object.fromEntries(
        Object.entries(data).filter(([, value]) => value !== '')
      )
    }

    const newObjectFilter = {
      ...filter,
      date: { value: newDateFilterValue }
    }
    await onSubmit(newObjectFilter)
  }
  function closeModal() {
    reset()
    onClose()
  }
  function handleChange(category, e, isChecked) {
    let newValues = null
    if (!isChecked) {
      newValues = filter[category.toLowerCase()].value
      delete newValues[e.target.name]
      if (Object.entries(newValues).length === 0) newValues = null
    } else {
      newValues = { ...filter[category.toLowerCase()]?.value, [e.target.name]: isChecked }
    }

    return setFilter((prev) => ({
      ...prev,
      [category.toLowerCase()]: {
        value: newValues
      }
    }))
  }

  return (
    <ModalUser modalClassName={''} onClose={closeModal} title={'Filters'} show={show}>
      <div className="h-full md:h-[528px] pt-3 flex flex-col justify-between">
        <div className="flex flex-col gap-8">
          {/* Status */}
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">Eligiblity</span>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-x-8">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange.bind(this, ELIGIBILITY)}
                      name={'eligible'}
                      checked={filter?.eligibility?.value?.['eligible'] || false}
                    />
                  }
                  label={<span className="text-black">Eligible</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange.bind(this, ELIGIBILITY)}
                      name={'ineligible'}
                      checked={filter?.eligibility?.value?.['ineligible'] || false}
                    />
                  }
                  label={<span className="text-black">Ineligible</span>}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">Loading date</span>
            <div className="grid grid-cols-2 gap-8">
              <DatePickerUserFields
                control={control}
                label={'From'}
                name={'fromDate'}
                errors={errors}
              />
              <DatePickerUserFields
                control={control}
                label={'To'}
                name={'toDate'}
                errors={errors}
              />
            </div>
          </div>
        </div>
        {/* Button Wrapper */}
        <div className="flex justify-end items-center gap-4">
          <CustomButton buttonColor="secondary" onClick={resetFilter}>
            Remove Filter
          </CustomButton>
          <CustomButton onClick={handleSubmit(processForm)}>Apply</CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default NewChatFilterModal

// ROLES
export const ROLE_SUPER_ADMIN = 'SUPER ADMIN'
export const ROLE_ADMIN = 'ADMIN'
export const ROLE_USER = 'USER'
// PLATFORM
export const BACK_OFFICE = 'BACK_OFFICE'
export const PLATFORM = 'PLATFORM'

export const PROFILE_CONTENT = 'PROFILE_CONTENT'
export const SECURITY_CONTENT = 'SECURITY_CONTENT'
export const CONTACTS_CONTENT = 'CONTACTS_CONTENT'

export const EDIT_PROFILE = 'EDIT_PROFILE'
export const DELETE_PROFILE = 'DELETE_PROFILE'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RETRIEVE_PROFILE = 'RETRIEVE_PROFILE'

export const TWENTY_FOUR_HOURS_IN_MS = 1000 * 60 * 60 * 24
export const TEXT_INPUT = 'TEXT_INPUT'
export const PHONE_INPUT = 'PHONE_INPUT'
export const COUNTRY_INPUT = 'COUNTRY_INPUT'
export const GROUP_INPUT = 'GROUP_INPUT'
export const SELECT_INPUT = 'SELECT_INPUT'
export const MULTI_SELECT_INPUT = 'MULTI_SELECT_INPUT'
export const PASSWORD_INPUT = 'PASSWORD_INPUT'
export const SEARCH_INPUT = 'SEARCH_INPUT'
export const GRID_VIEW = 'GRID_VIEW'
export const LIST_VIEW = 'LIST_VIEW'
export const OFFSET_NUMBER = 9
export const PAIR_COMPANY = 'PAIR_COMPANY'
export const CHANGE_COMPANY = 'CHANGE_COMPANY'

export const INITIAL_WORKSPACE_FILTER = {
  show: false,
  data: {
    regulation: { value: null },
    status: { value: null },
    date: { value: null }
  }
}
export const INITIAL_CHAT_FILTER = {
  show: false,
  data: {
    date: { value: null }
  }
}
export const INITIAL_NOTES_INSIGHT_FILTER = {
  show: false,
  data: {
    topology: { value: null },
    date: { value: null }
  }
}
export const INITIAL_NOTES_TODO_FILTER = {
  show: false,
  data: {
    date: { value: null },
    time: { value: null }
  }
}
export const REGULATION = 'REGULATION'
export const STATUS = 'STATUS'
export const DATE = 'DATE'
export const ADD_CASE = 'ADD_CASE'
export const EDIT_CASE = 'EDIT_CASE'
export const DELETE_CASE = 'DELETE_CASE'
export const EXPORT_CASE_NOTES = 'EXPORT_CASE_NOTES'
export const CONST_QUESTION = 'CONST_QUESTION'
export const CONST_SHORTCUT = 'CONST_SHORTCUT'

import React from 'react'

const styleBadgeList = {
  cancellation: 'text-black bg-white'
}

const BadgeItem = ({ children, badgeType = '', className }) => {
  return (
    <div
      className={`${styleBadgeList?.[badgeType]} font-manrope text-[10px] leading-5 font-semibold h-4 rounded-[20px] flex justify-center items-center px-2 shrink-0 uppercase ${className}`}>
      {children}
    </div>
  )
}

export default BadgeItem

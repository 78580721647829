import { Typography } from 'antd'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import PasswordFields from 'components/molecules/PasswordFields'
import { passwordValidation } from 'utils/passwordValidation'
import CustomButton from 'components/atoms/CustomButton'
import { isAdminPage } from 'utils/isAdminPage'
import { useMutation } from 'react-query'
import { postResetPassword } from 'api'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import useNavigatorOnLine from 'hooks/useNavigatorOnline'
import { useTranslation } from 'react-i18next'
const schema = z
  .object({
    password: z
      .string()
      .min(10, {
        message: 'Password does not meet the safety guidelines'
      })
      .regex(passwordValidation(), 'Password does not meet the safety guidelines'),
    confirm: z.string().min(1, { message: 'password is required' })
  })
  .refine((data) => data.password === data.confirm, {
    message: 'Passwords do not match',
    path: ['confirm']
  })
const ChangePasswordForm = ({ onNavigate }) => {
  const { t } = useTranslation()
  const statusConnection = useNavigatorOnLine()
  const token = localStorage.getItem('tokenOtp')
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control
  } = useForm({
    defaultValues: {
      password: '',
      confirm: ''
    },
    mode: 'onChange',
    resolver: zodResolver(schema)
  })

  const changePassword = useMutation({
    mutationKey: 'changePassword',
    mutationFn: (data) => postResetPassword(token, data?.password, data?.confirm),
    onSuccess: () => {
      showSuccessMessage(isAdminPage() ? t('Password updated') : t('Password reset'))
      onNavigate()
    }
  })

  const onSubmit = (data) => {
    if (!statusConnection) return showErrorMessage(t('Internet connection error'))
    changePassword.mutate(data)
  }
  return (
    <Box
      noValidate
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full">
      {/* Typography */}
      <div className="flex flex-col justify-center items-center gap-2 ">
        <Typography.Title
          className={`${
            isAdminPage() ? '!text-white' : '!text-black'
          } font-bold text-lg text-h4 text-nowrap`}>
          {t('Create new password')}
        </Typography.Title>
        <Typography.Paragraph className="text-[#595961] text-body1 text-center">
          {t(
            'The new password must contain at least 10 characters, 1 number, 1 capital letter and a special character'
          )}
        </Typography.Paragraph>
      </div>

      {/* Form */}
      <div className="flex flex-col gap-8">
        <div className="flex gap-4 flex-col">
          <PasswordFields
            control={control}
            label={t('New Password')}
            name={'password'}
            errors={errors}
          />
          <PasswordFields
            control={control}
            label={t('Repeat Password')}
            name={'confirm'}
            errors={errors}
          />
        </div>

        {/* Button Submit */}
        <CustomButton disabled={isSubmitting} type="submit" buttonColor="primary" fullWidth>
          {t('Create Password')}
        </CustomButton>
      </div>
    </Box>
  )
}

export default ChangePasswordForm

import React, { useState, useRef, useEffect } from 'react'
import {
  ChatBubbleOutline,
  InsertDriveFileOutlined,
  StickyNote2Outlined
} from '@mui/icons-material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CloseIcon from '@mui/icons-material/Close'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Typography, Input } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { deleteNote, updateDataNote, createNote } from 'api'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { showSuccessMessage, showErrorMessage } from 'utils/showMessage'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { errorObj } from 'utils/handleError'
import { useTranslation } from 'react-i18next'

const NoteCard = ({
  text,
  note_type,
  created_at,
  chat,
  document,
  edit,
  todo_date,
  setNoteData,
  id,
  tabNote,
  isNewData,
  caseId
}) => {
  const { t } = useTranslation()
  const { TextArea } = Input
  const [idValue, setIdValue] = useState(null)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user.access_token)

  const clickToEdit = (id) => {
    setIdValue(id)
    ref?.current?.clientHeight && setHeight(ref?.current?.clientHeight)
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            edit: true
          }
        } else {
          return {
            ...item,
            edit: false
          }
        }
      })
    })
  }

  const onChangeText = (id, e) => {
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            text: e.target.value
          }
        } else {
          return item
        }
      })
    })
  }

  const onChangetodoDate = (value) => {
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            todo_date: value
          }
        } else {
          return item
        }
      })
    })
  }

  const onChangeTimePicker = (value) => {
    const newTime = moment(value).format('HH:mm')
    const timeZone = moment(value).format('Z')
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            todo_date: moment(item.todo_date).format('YYYY-MM-DD') + 'T' + newTime + timeZone
          }
        } else {
          return item
        }
      })
    })
  }

  const deletingNote = useMutation({
    mutationKey: ['delete-note'],
    mutationFn: async (id) => await deleteNote(token, id),
    onSuccess: () => {
      showSuccessMessage(t('Note deleted successfully'))
      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)
    }
  })

  const onClickDelete = (id) => {
    if (isNewData) {
      setNoteData((prev) => {
        return prev.filter((item) => item.id !== id)
      })
    } else {
      deletingNote.mutate(id)
    }
  }
  const updatingDataNote = useMutation({
    mutationKey: ['update-data-note'],
    mutationFn: async (data) => await updateDataNote(token, idValue, data),
    onSuccess: () => {
      showSuccessMessage(t('Note updated successfully'))
      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    },
    onError: async (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)

      queryClient.invalidateQueries(['note-list-insight'])

      queryClient.invalidateQueries(['note-list-todo'])
    }
  })

  const createDataNote = useMutation({
    mutationKey: ['create-data-note'],
    mutationFn: async (data) => {
      if (data.note_type === 'CHAT') {
        return await createNote(token, caseId, data.note_type, data.todo_date, data.text, chat.id)
      } else if (data.note_type === 'DOCUMENT') {
        return await createNote(
          token,
          caseId,
          data.note_type,
          data.todo_date,
          data.text,
          document.id
        )
      } else {
        return await createNote(token, caseId, data.note_type, data.todo_date, data.text)
      }
    },
    onSuccess: () => {
      showSuccessMessage(t('Note created successfully'))
      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)

      if (isNewData) {
        setNoteData((prev) => {
          return prev.filter((item) => item.id !== idValue)
        })
      }
    }
  })

  const onEnterInsight = (e) => {
    if (e.key === 'Enter') {
      if (!isNewData) {
        const payload = {
          text: text
        }
        updatingDataNote.mutate(payload)
        setNoteData((prev) => {
          return prev.map((item) => {
            if (item.id === idValue) {
              return {
                ...item,
                edit: false
              }
            } else {
              return item
            }
          })
        })
      } else {
        const payload = {
          text,
          todo_date,
          note_type
        }
        createDataNote.mutate(payload)
        setNoteData((prev) => {
          return prev.map((item) => {
            if (item.id === idValue) {
              return {
                ...item,
                edit: false
              }
            } else {
              return item
            }
          })
        })
      }
    }
  }
  const onEnterTodo = (e) => {
    if (e.key === 'Enter') {
      if (!isNewData) {
        const payload = {
          text: text,
          todo_date: moment(todo_date).format('YYYY-MM-DDTHH:mm:ssZ')
        }
        updatingDataNote.mutate(payload)

        setNoteData((prev) => {
          return prev.map((item) => {
            if (item.id === idValue) {
              return {
                ...item,
                edit: false
              }
            } else {
              return item
            }
          })
        })
      } else {
        // todo_date with format 2024-06-13T00:10+07:00
        const payload = {
          text,
          todo_date: moment(todo_date).format('YYYY-MM-DDTHH:mm:ssZ'),
          note_type
        }
        createDataNote.mutate(payload)
        setNoteData((prev) => {
          return prev.map((item) => {
            if (item.id === idValue) {
              return {
                ...item,
                edit: false
              }
            } else {
              return item
            }
          })
        })
      }
    }
  }

  return (
    <div
      className={`w-full ${edit ? 'bg-white' : 'bg-[#EDEDEF]'} p-4 rounded-lg mb-3 ${
        edit ? '' : 'group/item hover:border-solid border border-[#0049EE] hover:bg-blue-100'
      }`}
      onClick={() => clickToEdit(id)}>
      <div className="flex justify-between">
        {edit && idValue === id && (
          <TextArea
            rows={4}
            className="border-0 outline-0 focus:outline-0 focus:outline-none focus:border-0 focus:ring-0 "
            value={text}
            onChange={(e) => onChangeText(id, e)}
            onKeyDown={tabNote === 1 ? onEnterTodo : onEnterInsight}
            style={{
              resize: 'none',
              height:
                height === 0 || height === undefined || height === null ? 'auto' : height + 100
            }}
          />
        )}
        {!edit && (
          <Typography.Text className="mb-4 text-black block" ref={ref}>
            {text}
          </Typography.Text>
        )}
        <CloseIcon
          className={edit ? 'cursor-pointer' : 'cursor-pointer invisible group-hover/item:visible'}
          onClick={() => onClickDelete(id)}
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {note_type === 'CHAT' && (
            <div className="flex">
              <ChatBubbleOutline
                fontSize="small"
                className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              />
            </div>
          )}
          {note_type === 'DOCUMENT' && (
            <div className="flex">
              <InsertDriveFileOutlined
                fontSize="small"
                className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              />
            </div>
          )}
          {note_type === 'INSIGHT' && (
            <StickyNote2Outlined
              fontSize="small"
              className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
            />
          )}
          {note_type === 'TODO' && (
            <CalendarTodayIcon
              fontSize="small"
              className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
            />
          )}
          {note_type === 'INSIGHT' && (
            <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
              {t('Free Insight')}
            </Typography.Text>
          )}
          {note_type === 'CHAT' && (
            <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
              {chat?.name}
            </Typography.Text>
          )}
          {note_type === 'DOCUMENT' && (
            <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
              {document?.filename}
            </Typography.Text>
          )}
          {note_type === 'TODO' && edit && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                onChange={(value) => onChangetodoDate(value)}
                value={todo_date ? moment(todo_date) : null}
                format="DD/MM/YYYY"
                defaultValue={null}
                slotProps={{
                  textField: {
                    size: 'small',
                    onKeyDown: onEnterTodo
                  }
                }}
              />
            </LocalizationProvider>
          )}

          {note_type === 'TODO' && !edit && (
            <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
              {moment(todo_date).format('DD/MM/yyyy')}
            </Typography.Text>
          )}
        </div>
        {note_type === 'TODO' ? (
          !edit ? (
            <div className="flex items-center">
              <AccessTimeIcon
                fontSize="small"
                className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              />
              <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
                {moment(todo_date).format('HH:mm')}
              </Typography.Text>
            </div>
          ) : (
            <div className="flex items-center">
              <AccessTimeIcon
                fontSize="small"
                className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  value={moment(todo_date)}
                  onChange={(value) => onChangeTimePicker(value)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      onKeyDown: onEnterTodo
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
          )
        ) : (
          <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
            {moment(created_at).format('DD/MM/yyyy')}
          </Typography.Text>
        )}
      </div>
    </div>
  )
}

export default NoteCard

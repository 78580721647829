import { forwardRef, useImperativeHandle } from 'react'
import { LinearProgress } from '@mui/material'
import { useQuery } from 'react-query'
import { Typography } from 'antd'
import { rootService } from 'api'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const ProgressBarDocument = (
  { file, token, caseId, onUpdate, state, progress, setProgress },
  ref
) => {
  const { t } = useTranslation()
  const retryUpload = () => {
    setProgress({
      isSuccess: false,
      isError: false,
      progress: 0
    })
    uploadForm()
  }

  useImperativeHandle(ref, () => ({
    retryUpload
  }))

  useQuery({
    queryKey: [`upload-document-${file?.name}-${caseId}`],
    queryFn: async () => {
      const res = await uploadForm()
      return res
    },
    refetchOnWindowFocus: false,
    enabled: !state?.isFirstDownloaded
  })

  const uploadForm = async () => {
    try {
      const formData = new FormData()
      formData.append('case_id', caseId)
      formData.append('files', file)
      await rootService
        .post('documents/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data; boundary=BOUNDARY',
            Authorization: `Bearer ${token}`
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100
            if (progress <= 100) {
              setProgress({
                progress: progress
              })
            }
          }
        })
        .then((response) => {
          setTimeout(() => {
            setProgress({
              isSuccess: true,
              isError: false,
              progress: 100
            })
            onUpdate({
              ...response?.data?.data?.[0],
              isFirstDownloaded: true,
              isEligible: response?.data?.data?.[0]?.is_eligible,
              docId: response?.data?.data?.[0]?.id
            })
          }, 800)

          return response
        })
    } catch (error) {
      setTimeout(() => {
        setProgress({
          progress: 100,
          isSuccess: false,
          isError: true
        })
        onUpdate({
          isFirstDownloaded: true,
          isEligible: false,
          docId: null
        })
      }, 800)
    }
  }

  return (
    <div className="w-[150px]">
      {progress?.isSuccess ? (
        <Typography.Text>{t('Uploaded')}</Typography.Text>
      ) : progress?.isError ? (
        <Typography.Text className="text-[#828282]">{t('Unloaded')}</Typography.Text>
      ) : (
        <LinearProgress variant="determinate" value={progress?.progress} />
      )}
    </div>
  )
}

export default forwardRef(ProgressBarDocument)

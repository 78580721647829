export const checkPhoneNumber = (data) => {
  let newData = { ...data }
  if (typeof newData?.phoneNumber == 'string') {
    return { ...newData }
  } else if (!newData?.phoneNumber?.nationalNumber) {
    newData.phoneNumber = ''
  } else {
    newData.phoneNumber = newData?.phoneNumber?.numberValue
  }
  return { ...newData }
}

import React from 'react'
import { Controller } from 'react-hook-form'
import { MenuItem, TextField } from '@mui/material'
import ErrorMessage from 'components/atoms/ErrorMessage'

const SelectUserFields = ({
  control,
  label,
  name,
  errors,
  theme = 'users',
  options = [],
  isRequired = false,
  ...selectProps
}) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        required={isRequired}
        render={({ field }) => (
          <>
            <TextField
              required={isRequired}
              select
              label={
                theme === 'admin' ? null : (
                  <span className={`${errors[name] ? '!text-red-500' : ''} }`}>{label}</span>
                )
              }
              {...field}
              {...selectProps}
              onChange={e => {
                field.onChange(e)
                if(typeof selectProps.onChange !== 'undefined') {
                  selectProps.onChange(e)
                }
              }}>
              {options.map((option, index) => (
                <MenuItem
                  key={`select-user-fields-${index}`}
                  value={option?.value}
                  label={option?.label}>
                  {option?.label}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      />
      {errors[name] ? <ErrorMessage errorMessage={errors[name].message} /> : null}
    </div>
  )
}
export default SelectUserFields

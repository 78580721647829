import React from 'react'
import { Typography } from 'antd'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import moment from 'moment'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { Popover } from 'antd'
import { useTranslation } from 'react-i18next'
const RegulationQueryCard = ({
  title,
  active,
  created_at,
  id,
  setData,
  setActiveData,
  data,
  onDelete = () => {},
  onExport = () => {}
}) => {
  const { t } = useTranslation();
  const handleClick = () => {
    setData((prev) =>
      prev.map((data) => {
        if (data.id === id) {
          setActiveData(data)
          return { ...data, active: true }
        }

        return { ...data, active: false }
      })
    )
  }
  const optionsPopover = [
    {
      title: t('Delete Conversation'),
      callback: onDelete,
      role: ['admin', 'editor']
    },
    {
      title: t('Export Documents'),
      callback: onExport,
      role: ['admin']
    }
  ]
  function handleClickMenu(callback, row) {
    return callback(row)
  }
  console.log(t('Source'))
  return (
    <div
      className={
        'w-full  p-4 rounded-lg mb-3 cursor-pointer ' + (active ? 'bg-[#0049EE]' : 'bg-white')
      }
      onClick={handleClick}>
      <div className="flex justify-between items-center">
        <Typography.Text className={' block ' + (active ? 'text-white' : 'text-black')}>
          {title}
        </Typography.Text>
        <div className="flex items-center">
          <div
            className="rounded-full  p-1  flex items-center"
            style={{
              border: '1px solid ' + (active ? '#fff' : '#0049EE')
            }}>
            <Typography.Text className={'text-[11px] ' + (active ? 'text-white' : 'text-blue-700')}>
              {moment(created_at).format('DD/MM/YYYY')}
            </Typography.Text>
          </div>
          <Popover
            content={
              <div className="w-[178px] h-full flex flex-col text-black">
                {optionsPopover.map(
                  (option, index) =>
                    option.role.includes('admin') && (
                      <div
                        key={index}
                        onClick={() => {
                          handleClickMenu(option.callback, data)
                        }}
                        className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                        {option.title}
                      </div>
                    )
                )}
              </div>
            }
            trigger="click"
            placement="leftTop"
            arrow={false}>
            <MoreVertOutlinedIcon
              className={`cursor-pointer hover:text-[#0049EE] `}
              style={{
                color: active ? '#fff' : '#000'
              }}
            />
          </Popover>
        </div>
      </div>
      <div className="h-[50px]"></div>
      <div className="flex items-center">
        <div className="flex items-center">
          <InfoOutlinedIcon
            fontSize="small"
            className=" text-[12px]"
            style={{
              color: active ? '#fff' : '#828282'
            }}
          />
          <Typography.Text
            className={'text-[11px] ' + (active ? 'text-white' : 'text-[#828282]') + ' ml-2'}>
            {t('Source')}
          </Typography.Text>
        </div>
      </div>
    </div>
  )
}

export default RegulationQueryCard

import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox, FormControlLabel } from '@mui/material'
import CustomButton from 'components/atoms/CustomButton'
import DatePickerUserFields from 'components/molecules/user/DatePickerUserFields'
import ModalUser from 'components/molecules/user/ModalUser'
import { REGULATION, STATUS } from 'constant'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
const schema = z.object({
  fromDate: z.string().optional(),
  toDate: z.string().optional()
})
const WorkspaceFilter = ({
  show,
  regulations = [],
  casesStatus = [],
  onClose,
  data,
  onSubmit,
  onClear = () => {},
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: {
      fromDate: data?.date?.value?.fromDate || '',
      toDate: data?.date?.value?.toDate || ''
    },
    resolver: zodResolver(schema)
  })
  const [filter, setFilter] = useState({ ...data })

  async function processForm(data) {
    let newDateFilterValue = null
    if (data.fromDate !== '' || data.toDate !== '') {
      newDateFilterValue = Object.fromEntries(
        Object.entries(data).filter(([, value]) => value !== '')
      )
    }

    if (data.fromDate !== 'Invalid date' || data.toDate !== 'Invalid date') {
      newDateFilterValue = Object.fromEntries(
        Object.entries(data).filter(([, value]) => value !== 'Invalid date')
      )
    }

    newDateFilterValue = Object.fromEntries(Object.entries(newDateFilterValue).filter(([, v]) => v != undefined && v != ''))

    function isEmpty(obj) {
      for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
          return false;
        }
      }
    
      return true;
    }
    

    const newObjectFilter = {
      ...filter,
      date: { value: isEmpty(newDateFilterValue) ? null : newDateFilterValue }
    }

    await onSubmit(newObjectFilter)
  }
  function closeModal() {
    reset()
    onClose()
  }

  function handleChange(category, value, e, isChecked) {
    let newValues = null
    if (!isChecked) {
      newValues = filter[category.toLowerCase()].value
      delete newValues[e.target.name]
      if (Object.entries(newValues).length === 0) newValues = null
    } else {
      newValues = { ...filter[category.toLowerCase()]?.value, [e.target.name]: value }
    }

    return setFilter((prev) => ({
      ...prev,
      [category.toLowerCase()]: {
        value: newValues
      }
    }))
  }

  return (
    <ModalUser modalClassName={''} onClose={closeModal} title={t('Filters')} show={show}>
      {/* Regulation */}
      <div className="h-full md:h-[528px] pt-3 flex flex-col justify-between">
        <div className="flex flex-col gap-8">
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">{t('Regulation')}</span>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-x-8">
                {regulations?.map(regulation => (
                  <FormControlLabel
                    key={`workspace-filter-regulation-${regulation?.id}`}
                    control={
                      <Checkbox
                        onChange={handleChange.bind(this, REGULATION, regulation?.id)}
                        name={regulation?.title}
                        checked={filter?.regulation?.value?.[regulation?.title] !== undefined || false}
                      />
                    }
                    label={t(regulation?.title)}
                  />
                ))}
              </div>
              {/* <FormHelperText>Be careful</FormHelperText> */}
            </div>
          </div>
          {/* Status */}
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">{t('Status')}</span>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-x-8">
                {casesStatus.map((status, key) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        onChange={handleChange.bind(this, STATUS, status?.id)}
                        name={status.name}
                        checked={filter?.status?.value?.[status.name] !== undefined || false}
                      />
                    }
                    label={
                      <div className="flex justify-center items-center gap-2">
                        <div
                          className="w-2 h-2 rounded-full "
                          style={{ backgroundColor: "#" + status.hex_code }}></div>
                        <span className="text-black">{t(status.name)}</span>
                      </div>
                    }
                  />
                ))}
              </div>
              {/* <FormHelperText>Be careful</FormHelperText> */}
            </div>
          </div>
          {/* Proceeding Date */}
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">{t('Proceeding starting date')}</span>
            <div className="grid grid-cols-2 gap-8">
              <DatePickerUserFields
                control={control}
                label={t('From')}
                name={'fromDate'}
                errors={errors}
              />
              <DatePickerUserFields
                control={control}
                label={t('End')}
                name={'toDate'}
                errors={errors}
              />
            </div>
          </div>
        </div>
        {/* Button Wrapper */}
        <div className="flex justify-end items-center gap-4">
          <CustomButton
            buttonColor="secondary"
            onClick={() => {
              onClear()
              closeModal()
            }}
          >
            {t('Cancel Filter')}
          </CustomButton>
          <CustomButton onClick={handleSubmit(processForm)}>{t('Apply')}</CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default WorkspaceFilter

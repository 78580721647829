import { Clear, Refresh } from '@mui/icons-material'
import { Tooltip } from 'antd'
import ProgressBarDocument from 'components/organisms/users/document/ProgressBarDocument'
import useAuth from 'hooks/useAuth'
import humanFileSize from 'lib/getHumanFileSize'
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useMemo, useRef, useState } from 'react'
const INITIAL_FILTER_UPLOAD_NEW_CHAT = {
  show: false,
  data: {
    eligibility: { value: null },
    status: { value: null }
  }
}
const useUploadDocsNewChat = (data, tableState) => {
  const { getAccessToken } = useAuth()
  const [progresses, setProgresses] = useState({})
  const [updates, setUpdates] = useState({})
  const [uploadListDocument, setUploadListDocument] = useState([])
  const [uploadDocFilterState, setUploadDocFilterState] = useState(INITIAL_FILTER_UPLOAD_NEW_CHAT)
  const [documentModal, setDocumentModal] = useState({
    show: false,
    files: []
  })
  
  const refs = useRef({})
  const columnsUploadDocs = [
    {
      id: 'filename',
      name: 'Name',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <Tooltip title={row?.file?.name}>
            <span className="line-clamp-1">{row?.file?.name}</span>
          </Tooltip>
        </div>
      ),
      width: '30%',
      selector: (row) => row?.file?.name,
      sortable: true
    },
    {
      id: 'loading',
      name: 'Loading',
      selector: (row) => progresses[row?.idx]?.progress,
      cell: (row) => (
        <div>
          {updates[row?.idx] && (
            <ProgressBarDocument
              ref={(el) =>
                (refs.current = {
                  ...refs.current,
                  [row?.idx]: el
                })
              }
              state={updates[row?.idx]}
              progress={progresses[row?.idx]}
              setProgress={(data) => {
                setProgresses((prev) => {
                  return {
                    ...prev,
                    [row?.idx]: {
                      ...prev[row?.idx],
                      ...data
                    }
                  }
                })
              }}
              file={row?.file}
              caseId={data?.caseId}
              token={getAccessToken()}
              onUpdate={(data) => {
                setUpdates((prev) => ({
                  ...prev,
                  [row?.idx]: {
                    ...prev[row?.idx],
                    ...data
                  }
                }))
              }}
            />
          )}
        </div>
      ),
      width: '200px',
      sortable: true
    },
    {
      id: 'size',
      name: 'Size',
      selector: (row) => humanFileSize(row?.file?.size),
      sortable: true,
      size: '100px'
    },
    {
      id: 'is_eligible',
      name: 'Eligibility',
      selector: (row) => updates[row?.idx]?.isEligible,
      cell: (row) => {
        if (progresses[row?.idx]?.isError) {
          return <div />
        }
        if (progresses[row?.idx]?.progress === 100 && !progresses[row?.idx]?.isError) {
          return (
            <Tooltip
              placement="right"
              title={updates[row?.idx]?.isEligible ? 'Eligible' : 'Ineligible'}>
              <div
                className={`w-2 h-2 rounded-full ${
                  updates[row?.idx]?.isEligible ? 'bg-[#00B47E]' : 'bg-[#E53E3E]'
                }`}></div>
            </Tooltip>
          )
        }
      },
      sortable: true
    },
    {
      id: 'is_reload',
      name: ' ',
      selector: (row) => progresses[row?.idx]?.isError,
      cell: (row) => (
        <div className="flex gap-2">
          {progresses[row?.idx]?.isError ? (
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                refs.current[row?.idx]?.retryUpload()
              }}>
              <Refresh className="cursor-pointer text-[#595961] hover:text-[#0049EE]" />
            </div>
          ) : (
            <Refresh className="text-transparent" />
          )}
          {progresses[row?.idx]?.progress === 100 && updates[row?.idx]?.docId !== undefined && (
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                deleteDocument(row?.idx)
              }}>
              <Clear className="cursor-pointer text-[#595961] hover:text-[#0049EE]" />
            </div>
          )}
        </div>
      ),
      width: '80px'
    }
  ]

  useEffect(() => {
    if (documentModal.show && documentModal.files?.length > 0) {
      let documents = []
      let newProgresses = {}
      let newUpdates = {}
      for (let i = uploadListDocument.length; i < documentModal.files?.length; i++) {
        // Creating random Id
        let generateRandomId = uuidv4()
        do {
          generateRandomId = uuidv4()
        } while (typeof progresses[generateRandomId] !== 'undefined')

        documents?.push({
          size: documentModal.files[i]?.size,
          file: documentModal.files[i],
          idx: generateRandomId
        })
        newUpdates = {
          ...newUpdates,
          [generateRandomId]: {
            isFirstDownloaded: false,
            isEligible: false
          }
        }
        newProgresses = {
          ...newProgresses,
          [generateRandomId]: {
            progress: 0,
            isError: false,
            isSuccess: false
          }
        }
      }
      setUploadListDocument((prev) => {
        return [...prev, ...documents]
      })
      setProgresses((prev) => ({ ...prev, ...newProgresses }))
      setUpdates((prev) => ({ ...prev, ...newUpdates }))
    }
  }, [documentModal.show, documentModal.files])
  const filteredItems = useMemo(() => {
    return uploadListDocument?.filter((item) => {
      let check = true
      check = check && item?.file?.name?.toLowerCase().includes(tableState.search?.toLowerCase())
      if (
        uploadDocFilterState?.data?.eligibility?.value === null &&
        uploadDocFilterState?.data?.status?.value === null
      ) {
        return check
      } else {
        if (uploadDocFilterState?.data?.eligibility?.value === null) {
          check =
            check &&
            (progresses?.[item?.idx]?.isError ===
              uploadDocFilterState?.data?.status?.value?.unloaded ||
              !progresses?.[item?.idx]?.isError ===
                uploadDocFilterState?.data?.status?.value?.uploaded)
        }
        if (uploadDocFilterState?.data?.status?.value === null) {
          check =
            check &&
            (updates?.[item?.idx]?.isEligible ===
              uploadDocFilterState?.data?.eligibility?.value?.eligible ||
              !updates?.[item?.idx]?.isEligible ===
                uploadDocFilterState?.data?.eligibility?.value?.ineligible)
        }
        if (
          uploadDocFilterState?.data?.status?.value !== null &&
          uploadDocFilterState?.data?.eligibility?.value !== null
        ) {
          check =
            check &&
            (progresses?.[item?.idx]?.isError ===
              uploadDocFilterState?.data?.status?.value?.unloaded ||
              !progresses?.[item?.idx]?.isError ===
                uploadDocFilterState?.data?.status?.value?.uploaded)
          check =
            check &&
            (updates?.[item?.idx]?.isEligible ===
              uploadDocFilterState?.data?.eligibility?.value?.eligible ||
              !updates?.[item?.idx]?.isEligible ===
                uploadDocFilterState?.data?.eligibility?.value?.ineligible)
        }
      }
      return check
    })
  }, [updates, progresses, uploadListDocument, uploadDocFilterState.data, tableState.search])

  function deleteDocument(selectedIdx) {
    const indexDocArray = uploadListDocument.findIndex((row) => row.idx === selectedIdx)
    const newDocumentList = uploadListDocument.filter((row) => row?.idx !== selectedIdx)
    const newDocModalFiles = documentModal.files.filter((_, index) => index !== indexDocArray)
    let newUpdates = { ...updates }
    let newProgresses = { ...progresses }
    delete newUpdates[selectedIdx]
    delete newProgresses[selectedIdx]
    setUpdates(() => {
      setUploadListDocument(newDocumentList)
      setProgresses(newProgresses)
      setDocumentModal((prev) => ({ ...prev, files: newDocModalFiles }))
      return newUpdates
    })
  }

  function handleChangeDocumentModal(value) {
    setDocumentModal(() => value)
  }
  function handleSubmitUploadFilter(data) {
    setUploadDocFilterState((prev) => ({ ...prev, data: { ...data } }))
    closeUploadFilterModal()
  }
  function openUploadFilterModal() {
    setUploadDocFilterState((prev) => ({ ...prev, show: true }))
  }
  function closeUploadFilterModal() {
    setUploadDocFilterState((prev) => ({ ...prev, show: false }))
  }
  function handleResetUploadFilter() {
    setUploadDocFilterState(INITIAL_FILTER_UPLOAD_NEW_CHAT)
  }
  return {
    filteredItems,
    progresses,
    documentModal,
    columnsUploadDocs,
    uploadDocFilterState,
    updates,
    deleteDocument,
    handleChangeDocumentModal,
    openUploadFilterModal,
    closeUploadFilterModal,
    handleResetUploadFilter,
    handleSubmitUploadFilter
  }
}

export default useUploadDocsNewChat

import { getDocumentListNewChat } from 'api'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { errorObj } from 'utils/handleError'
import { showErrorMessage } from 'utils/showMessage'
const INITIAL_FILTER_NEW_CHAT = {
  eligibility: { value: null },
  date: { value: null }
}
const useSelectDocsNewChat = (
  currentContent,
  isContentSpecificCase,
  showDocumentModal,
  tableState
) => {
  const { getAccessToken } = useAuth()
  // Filled Chat
  const [documentListByCase, setDocumentListByCase] = useState([])
  const [filterModal, setFilterModal] = useState(false)
  const [filterState, setFilterState] = useState(INITIAL_FILTER_NEW_CHAT)
  const [selectedDocuments, setSelectedDocuments] = useState([])
  // Fetch Docs
  const { isFetching: isFetchingDocumentByCase } = useQuery({
    queryKey: [filterState, tableState],
    queryFn: () =>
      getDocumentListNewChat(getAccessToken(), currentContent.caseId, filterState, tableState),
    onSuccess: (data) => {
      setDocumentListByCase(data?.resData?.data)
    },
    onError: (err) => {
      showErrorMessage('Failed fetch document list, Please refresh the page')
    },
    enabled: isContentSpecificCase() && !showDocumentModal
  })
  const handleChangeSelectedDocuments = (options) => {
    setSelectedDocuments(options.selectedRows)
  }
  function openFilterModal() {
    setFilterModal(true)
  }
  function closeFilterModal() {
    setFilterModal(false)
  }
  function handleSubmitFilter(data) {
    setFilterState((prev) => ({ ...prev, ...data }))
    closeFilterModal()
  }
  function handleResetFilterState() {
    setFilterState(INITIAL_FILTER_NEW_CHAT)
    closeFilterModal()
  }
  function resetSelectedDocument() {
    setSelectedDocuments([])
  }

  return {
    documentListByCase,
    selectedDocuments,
    isFetchingDocumentByCase,
    filterModal,
    filterState,
    openFilterModal,
    closeFilterModal,
    handleSubmitFilter,
    handleResetFilterState,
    handleChangeSelectedDocuments,
    resetSelectedDocument
  }
}

export default useSelectDocsNewChat

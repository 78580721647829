import { zodResolver } from '@hookform/resolvers/zod'
import CustomButton from 'components/atoms/CustomButton'
import PhoneNumberFields from 'components/molecules/PhoneNumberFields'
import TextFields from 'components/molecules/TextFields'
import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import { phoneNumberObject } from 'lib/schema'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { checkPhoneNumber } from 'utils/checkPhoneNumber'
import { z } from 'zod'

const schema = z.object({
  name: z.string().min(1, { message: 'name is requried' }),
  surname: z.string().min(1, { message: 'surname is required' }),
  email: z.string().email({ message: 'Invalid email address' }),
  phoneNumber: phoneNumberObject.or(z.string()).optional()
})
const EditProfileUserModal = ({ show, onClose, onEditProfile, userDetail, isLoading }) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    control
  } = useForm({
    defaultValues: {
      name: userDetail?.name || undefined,
      surname: userDetail?.surname || undefined,
      email: userDetail?.email || undefined,
      phoneNumber: userDetail?.phone || ''
    },
    resolver: zodResolver(schema)
  })
  function processForm(data) {
    const newData = checkPhoneNumber(data)
    onEditProfile(newData)
  }
  function closeModal() {
    reset()
    onClose()
  }
  return (
    <ModalAdmin
      width={448}
      show={show}
      onClose={isLoading ? () => {} : closeModal}
      title={t('Edit Profile')}>
      <div className="flex flex-col justify-between">
        <div className="flex flex-col gap-6 w-full">
          <TextFields
            control={control}
            theme="admin"
            label={t('Name')}
            errors={errors}
            name={'name'}
          />
          <TextFields
            control={control}
            theme="admin"
            label={t('Surname')}
            errors={errors}
            name={'surname'}
          />
          <TextFields
            control={control}
            theme="admin"
            label={t('Email')}
            errors={errors}
            name={'email'}
          />
          <PhoneNumberFields
            control={control}
            theme="admin"
            label={t('Telephone Number') + ' ' + t('(optional)')}
            placeholder={t('input phone number')}
            errors={errors}
            name={'phoneNumber'}
          />

          <div className="flex gap-2 justify-end items-center">
            <CustomButton buttonColor="secondary" disabled={isLoading} onClick={onClose}>
              {t('Cancel')}
            </CustomButton>
            <CustomButton
              isLoading={isLoading}
              disabled={!isDirty || !isValid || isLoading}
              onClick={handleSubmit(processForm)}>
              {t('Save Changes')}
            </CustomButton>
          </div>
        </div>
      </div>
    </ModalAdmin>
  )
}

export default EditProfileUserModal

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from 'hooks';
import ModalUser from 'components/molecules/user/ModalUser';
import { getRegulationExportFile } from 'api';
import { showErrorMessage } from 'utils/showMessage';
import RegulationSelectableTable from './RegulationSelectableTable';
import { useTranslation } from 'react-i18next'
const INITIAL_DOCS_TABLE_STATE = {
  sort: 'filename',
  sort_order: 'desc',
  search: '',
};

const RegulationExportDocument = forwardRef(({ show, closeModal, id, selectedDocuments, setSelectedDocuments }, ref) => {
  const { getAccessToken } = useAuth();
  const { t } = useTranslation();
  const [tableState, setTableState] = useState(INITIAL_DOCS_TABLE_STATE);
  const [tableData, setTableData] = useState([]);
  const [clearSelected, setClearSelected] = useState(false);

  useImperativeHandle(ref, () => ({
    clearSelectedRows() {
      setClearSelected(!clearSelected);
    }
  }));

  const handleChangeSelectedDocuments = (options) => {
    setSelectedDocuments(options.selectedRows);
  };

  const handleConfirmNewExportFile = () => {
    const selectedDocumentUrl = selectedDocuments.map((doc) => doc?.url);
    selectedDocumentUrl.forEach((url) => {
      window.open(url, '_blank');
    });
    closeModal();
  };

  useQuery({
    queryKey: ['getRegulationExportFile', id, tableState],
    queryFn: async () => await getRegulationExportFile(getAccessToken(), id, tableState),
    onSuccess: (res) => {
      setTableData(res?.resData?.data?.documents);
    },
    onError: (err) => {
      showErrorMessage('Failed fetch document list, Please refresh the page');
    },
    enabled: show,
  });

  const handleChangeSort = (e) => {
    if (e.sortable) {
      // if tablestate.sort === created_at change to updated_at and vice versa
      if (e.sortField === 'filename') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'filename' ? '-filename' : 'filename'
        }))
      } else  if (e.sortField === 'updated_at') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'updated_at' ? '-updated_at' : 'updated_at'
        }))
      } else  if (e.sortField === 'ext') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'ext' ? '-ext' : 'ext'
        }))
      } 
    }
  }

  return (
    <ModalUser
      titleComponent={
        <div className="flex justify-between items-center pb-2">
          <span>{t("Source information")}</span>
        </div>
      }
      show={show}
      width={900}
      isCloseIconVisible={false}
      onClose={closeModal}
      closable={false}
    >
      <div className="mb-2">
        <p className="text-[14px]">
          {t("The data source indicates which and how many documents are used as database for this chat. For each document is indicated the last update and export format")}.
        </p>
      </div>
      <RegulationSelectableTable
        tableState={tableState}
        handleChangeSelectedDocuments={handleChangeSelectedDocuments}
        documentListByCase={tableData}
        selectedDocuments={selectedDocuments}
        clearSelected={clearSelected}
        closeModal={closeModal}
        handleConfirmNewChat={handleConfirmNewExportFile}
        handleSort={handleChangeSort}
      />
    </ModalUser>
  );
});

export default RegulationExportDocument;

import { NullAvatar } from 'assets/images'
import { useAuth } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UserResponseItem = ({ text }) => {
  const { t } = useTranslation();
  const { platformData } = useAuth()
  return (
    <div className="flex gap-2 w-full w-">
      <div className="flex justify-center w-10 overflow-hidden">
        <img
          src={platformData?.profile?.avatar_url || NullAvatar}
          className={`${
            platformData?.profile?.avatar_url
              ? 'object-cover w-6 h-6 rounded-full'
              : 'object-contain w-6 h-6'
          } object-center`}
          alt="avatar"
          aria-label="avatar profile"
        />
      </div>
      <div className="flex w-full h-full flex-col items-start justify-start">
        <span className="text-base font-bold">{t("You")}</span>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default UserResponseItem

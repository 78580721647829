import React, { useEffect, useRef, useState } from 'react'
import {
  ArrowForward,
  InsertDriveFileOutlined,
  MoreVertOutlined,
  StickyNote2Outlined
} from '@mui/icons-material'
import { TablePagination, Tooltip } from '@mui/material'
import { Popover } from 'antd'
import Table from 'components/atoms/Table'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const ListChat = ({
  height,
  data = [],
  onRename = () => {},
  onDelete = () => {},
  onRowsPerPageChange = () => {},
  page = 1,
  rowsPerPage = 9,
  total = 0,
  onPageChange = () => {},
  handleSort = () => {},
  onClickRow
}) => {
  const { t } = useTranslation()
  const paginationRef = useRef()
  const [tableHeight, setTableHeight] = useState(height ?? 0)

  const optionsPopover = [
    {
      title: 'Rename',
      callback: onRename,
      role: ['admin', 'editor']
    },
    {
      title: 'Delete',
      callback: onDelete,
      role: ['admin']
    }
  ]
  function handleClickMenu(callback, row) {
    return callback(row)
  }

  useEffect(() => {
    if (paginationRef?.current) {
      setTableHeight(height - paginationRef?.current?.clientHeight)
    }
  }, [paginationRef?.current !== null, height])

  const columns = [
    {
      name: t('Name'),
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <Tooltip title={row?.name}>
            <span className="line-clamp-1">{row?.name}</span>
          </Tooltip>
        </div>
      ),
      selector: (row) => row?.name,
      sortable: true,
      sortField: 'name',
      width: '38%'
    },
    {
      name: t('Documents'),
      cell: (row) => (
        <div className="flex gap-2 items-center mr-5 w-full justify-end">
          {row?.document_total}{' '}
          <InsertDriveFileOutlined className="!w-4 !h-[18px] !text-[#828282]" />
        </div>
      ),
      selector: (row) => row?.document_total,
      sortable: true,
      sortField: 'document_total',
      width: '120px'
    },
    {
      name: t('Created Notes'),
      cell: (row) => (
        <div className="flex gap-2 items-center mr-5 w-full justify-end">
          {row?.note_total} <StickyNote2Outlined className="!w-4 !h-[18px] !text-[#828282]" />{' '}
        </div>
      ),
      width: '150px',
      sortable: true,
      sortField: 'note_total',
      selector: (row) => row?.note_total,
    },
    {
      name: t('Creation date'),
      sortField: 'created_at',
      selector: (row) => moment(row?.created_at).format('DD/MM/yyyy'),
      sortable: true,
      width: '150px'
    },
    {
      name: '',
      cell: (row) => (
        <div className="flex justify-center items-center" onClick={onClickRow.bind(this, row)}>
          <ArrowForward className="!text-[#595961] cursor-pointer" />
        </div>
      ),
      width: '80px',
      sortable: false
    },
    {
      name: '',
      cell: (row) => (
        <Popover
          content={
            <div className="w-[158px] h-full flex flex-col text-black">
              {optionsPopover.map(
                (option, index) =>
                  option.role.includes('admin') && (
                    <div
                      key={index}
                      onClick={() => {
                        handleClickMenu(option.callback, row)
                      }}
                      className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                      {t(option.title)}
                    </div>
                  )
              )}
            </div>
          }
          trigger="click"
          placement="leftTop"
          arrow={false}>
          <MoreVertOutlined className={`cursor-pointer hover:text-[#0049EE] `} />
        </Popover>
      ),
      width: '80px',
      sortable: false
    }
  ]



  return (
    <div className="w-full" >
      <div>
        <Table
          tableStyles={{ height: tableHeight, overflowY: 'scroll' }}
          hidePagination
          columns={columns}
          data={data}
          headStyles={{ backgroundColor: 'white' }}
          fixedHeader
          onSort={(e) => handleSort(e)}
          sortServer={true}
          sortIcon={
            <div className="text-[5px] ml-1">
              <div>▲</div>
              <div className="transform rotate-180">▲</div>
            </div>
          }
        />
      </div>
      <div 
      className="pr-6 mt-1 " 
      ref={paginationRef}
      >
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[20]}
          page={page - 1}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </div>
    </div>
  )
}

export default ListChat

import { Button } from 'antd'
import React from 'react'

import FilterListIcon from '@mui/icons-material/FilterList'
import { useTranslation } from 'react-i18next'
const FilterButton = ({ onClick, filterCount = 0 }) => {
  const { t } = useTranslation()
  return (
    <Button
      ghost
      className="flex justify-between items-center min-w-[200px] w-full text-sm text-[#666666] bg-[#FFFFFF0F] hover:"
      onClick={onClick}>
      <span className="font-manrope">{t('Filters')}</span>
      {filterCount !== 0 ? (
        <div className="flex items-center">
          <FilterListIcon />
          <div
            className={`w-4 h-4 bg-[#0049EE] flex items-center justify-center text-white font-semibold `}>
            {filterCount}
          </div>
        </div>
      ) : (
        <FilterListIcon />
      )}
    </Button>
  )
}

export default FilterButton

import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ExportNotesModal = ({ show, closeModal, onExport, isLoading }) => {
  const { t } = useTranslation()
  return (
    <ModalUser
      titleComponent={<div className="flex justify-start items-center">{t('Export notes')}</div>}
      show={show}
      width={444}
      zIndexPopupBase={9999}
      onClose={!isLoading ? closeModal : () => {}}
      closable={false}>
      <div className="flex justify-between flex-grow flex-col gap-4">
        <span className="text-[#595961]">
          {t('All notes will be exported to a single word file.')} <br/>
          {t('The export may take several minutes.')}
        </span>
        {/* wrapper button */}
        <div className="flex flex-grow justify-end items-center gap-2">
          <CustomButton
            isLoading={isLoading}
            buttonColor="secondary"
            onClick={!isLoading ? closeModal : () => {}}>
            {t('Cancel')}
          </CustomButton>
          <CustomButton isLoading={isLoading} onClick={!isLoading ? onExport : () => {}}>
            {t('Export')}
          </CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default ExportNotesModal

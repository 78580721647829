import CustomButton from 'components/atoms/CustomButton'
import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import SelectFields from 'components/molecules/SelectFields'
import { CHANGE_COMPANY, ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_USER } from 'constant'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks'

const UsersPairCompanyModal = ({
  show,
  status,
  onClose,
  companyList = [],
  currentCompanyId,
  currentRole = '',
  onPairCompany = () => {},
  navigateToAddCompany,
  isLoading
}) => {
  const { t } = useTranslation()
  const { getRoleUser } = useAuth()
  const schema = z.object({
    company: z.number().optional(),
    role: z.string()
  })
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch
  } = useForm({
    defaultValues: {
      company: status === CHANGE_COMPANY ? currentCompanyId : undefined,
      role: status === CHANGE_COMPANY ? currentRole?.toUpperCase() : undefined
    },
    resolver: zodResolver(schema)
  })
  const watchCompany = watch('company')
  const processForm = (data) => {
    onPairCompany(data)
  }

  function closeModal() {
    reset()
    onClose()
  }
  return (
    <ModalAdmin
      modalClassName={'h-full max-h-[466px]'}
      show={show}
      onClose={isLoading ? () => {} : closeModal}
      title={t('Pair Company')}>
      <div className="flex flex-col justify-between h-[466px] ">
        <div className="flex flex-col gap-4 max-w-72">
          {/* Company Fields */}
          <SelectFields
            labelClassName={'!text-white'}
            control={control}
            placeholder={t('Choose Company')}
            label={t('Company')}
            errors={errors}
            name={'company'}
            options={companyList}
            disabled={getRoleUser() !== ROLE_SUPER_ADMIN}
          />
          {getRoleUser() !== ROLE_SUPER_ADMIN && <span className="text-[#FFFFFF5C] font-manrope">{t('Or')}</span>}
          {getRoleUser() !== ROLE_SUPER_ADMIN && <div className="flex w-fit">
            <CustomButton onClick={navigateToAddCompany} buttonColor="secondary">
              <div className="flex gap-3">
                <AddIcon sx={{ width: '20px', height: '20px' }} /> <span>{t('Add New')}</span>
              </div>
            </CustomButton>
          </div>}
          {watchCompany ? (
            <SelectFields
              labelClassName={'!text-white'}
              control={control}
              placeholder={t('Choose Role')}
              label={t('Role')}
              errors={errors}
              name={'role'}
              options={[
                {
                  label: 'Admin',
                  value: ROLE_ADMIN
                },
                {
                  label: 'User',
                  value: ROLE_USER
                }
              ]}
            />
          ) : null}
        </div>

        {/* Btns */}
        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" disabled={isLoading} onClick={onClose}>
            {t('Cancel')}
          </CustomButton>
          <CustomButton
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit(processForm)}>
            {t('Pair')}
          </CustomButton>
        </div>
      </div>
    </ModalAdmin>
  )
}

export default UsersPairCompanyModal

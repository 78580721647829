import { Close, East, KeyboardBackspaceOutlined } from '@mui/icons-material'
import { Breadcrumbs, Tooltip } from '@mui/material'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { Spin, Typography } from 'antd'
import {
  getChatHistory,
  getDetailCase,
  getDetailDocument,
  getReferenceRegulationChat,
  postChatMessage,
  postRegenerateMessageChat
} from 'api'
import ReferenceRegulationModal from 'components/organisms/users/ReferenceRegulationModal'
import NotesContainer from 'components/organisms/users/cases/NotesContainer'
import ChatContainer from 'components/organisms/users/chat/ChatContainer'
import DocumentComparison from 'components/organisms/users/chat/DocumentComparison'
import { useAuth } from 'hooks'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { showErrorMessage } from 'utils/showMessage'
const INITIAL_REFERENCE_REGULATION_MODAL = { show: false, keyword: '' }
const ChatPage = () => {
  const { t } = useTranslation()
  const [detailChat, setDetailChat] = useState([])
  const { getAccessToken } = useAuth()
  const { state } = useLocation()
  const params = useParams()
  const [detailCase, setDetailCase] = useState()
  const [docsWindow, setDocsWindow] = useState(false)
  const [showIndexDocument, setShowIndexDocument] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [referenceRegulationState, setReferenceRegulationState] = useState(
    INITIAL_REFERENCE_REGULATION_MODAL
  )
  const navigate = useNavigate()
  useEffect(() => {
    if (!params?.caseId && !params?.id) {
      return navigate('/cases', { replace: true })
    }
  }, [])
  const [layoutInfo] = useOutletContext()
  const [tabNote, setTabNote] = useState(0)
  const chatHistoryRef = useRef(null)
  const noteRef = useRef()
  useEffect(() => {
    const node = chatHistoryRef.current
    if (node) {
      node.addEventListener('click', handleReferenceRegulation)
    }
    return () => {
      if (node) {
        node.removeEventListener('click', handleReferenceRegulation)
      }
    }
  }, [])

  function handleReferenceRegulation(e) {
    if (e.target.classList.contains('completion')) openReferenceRegulationModal(e.target.innerText)
  }

  const { data: dataReferenceRegulation, isFetching: isReferenceFetching } = useQuery({
    queryKey: ['getReferenceRegulation', referenceRegulationState.keyword],
    queryFn: () => getReferenceRegulationChat(getAccessToken(), referenceRegulationState.keyword),
    onError: () => {},
    enabled: referenceRegulationState.keyword !== '' && referenceRegulationState.show
  })

  function openReferenceRegulationModal(text) {
    setReferenceRegulationState((prev) => ({ ...prev, show: true, keyword: text }))
  }
  function closeReferenceRegulationModal() {
    setReferenceRegulationState({ ...INITIAL_REFERENCE_REGULATION_MODAL })
  }

  function handleNavigation(value, state = {}, isReplace = false) {
    return navigate(value, { state: state, replace: isReplace })
  }
  function toggleDocsWindow() {
    setDocsWindow((prev) => {
      if (prev) {
        handleChangeSelectedDocument(detailChat?.documents?.find((doc) => !doc?.is_deleted))
        closeShowIndexDocument()
      }
      return !prev
    })
  }

  function openShowIndexDocument() {
    setShowIndexDocument(true)
  }
  function closeShowIndexDocument() {
    setShowIndexDocument(false)
  }

  // Document
  const pageNavigationInstance = pageNavigationPlugin()
  const { CurrentPageLabel, jumpToPage } = pageNavigationInstance

  const { data, isFetching } = useQuery({
    queryKey: ['document-detail', selectedDocument?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getDetailDocument(getAccessToken(), selectedDocument?.id)
      return res
    },
    enabled: selectedDocument !== undefined && selectedDocument !== null && docsWindow
  })

  function handleChangeSelectedDocument(value) {
    setSelectedDocument(value)
  }
  useQuery({
    queryKey: 'getCasesDetail',
    queryFn: () => getDetailCase(getAccessToken(), params?.caseId),
    onSuccess: (res) => {
      setDetailCase(res?.resData?.data)
    },
    onError: () => {
      navigate(`/cases`, { replace: true })
    }
  })
  useQuery({
    queryKey: 'getChatHistory',
    queryFn: () => getChatHistory(getAccessToken(), params?.id),
    onSuccess: (res) => {
      setDetailChat(res.resData?.data)
      if (res?.resData?.data?.documents?.length > 0) {
        handleChangeSelectedDocument(res?.resData?.data?.documents?.find((doc) => !doc?.is_deleted))
      }
    },
    onError: (err) => {
      console.log(err)
    }
  })
  const postChatMessageMutation = useMutation({
    mutationKey: 'postChatMessageMutation',
    mutationFn: (data) => postChatMessage(getAccessToken(), params?.id, data),
    onSuccess: (res) => {
      setDetailChat((prev) => ({ ...prev, messages: res.resData?.data?.messages }))
    }
  })
  const postRegenerateMessageMutation = useMutation({
    mutationKey: 'postRegenerateMessageChat',
    mutationFn: () => postRegenerateMessageChat(getAccessToken(), params?.id),
    onSuccess: (res) => {
      setDetailChat((prev) => ({ ...prev, messages: res.resData?.data?.messages }))
    }
  })

  function handleSubmitChat(data) {
    const newMessages = detailChat.messages ?? []
    newMessages.push({ role: 'user', content: data?.message, choices: null })
    newMessages.push({ role: 'assistant', content: '', choices: null })
    setDetailChat((prev) => ({ ...prev, messages: newMessages }))
    postChatMessageMutation.mutate(data)
  }
  function handleRegenerateMessage() {
    postRegenerateMessageMutation.mutate()
  }
  function handleSourceClick(source) {
    const findDocument = detailChat?.documents?.find((doc) => doc.id == source.id)
    if (findDocument?.is_deleted) {
      showErrorMessage(
        <div className="flex flex-col gap-2">
          <span>{t('Source not found')}</span>
          <span className="text-sm">
            {t('The source associated to this document has been deleted or removed from the platform')}
          </span>
        </div>
      )
    } else {
      toggleDocsWindow()
      setSelectedDocument({ ...findDocument, page: source?.page })
    }
  }

  return (
    <div className="grid grid-cols-12 gap-4 w-full h-full overflow-hidden">
      {/* Wrapper White Content */}
      <div
        className={`grid col-span-9 grid-cols-12 overflow-hidden rounded-3xl border border-solid border-[#E3E3E8] bg-white `}>
        {/* Chat Wrapper AND Index / Bookmark Wrapper */}
        <div className={`pt-8 w-full h-full relative ${docsWindow ? 'col-span-6' : 'col-span-11'}`}>
          {/* Index / Bookmark Wrapper */}
          {showIndexDocument ? (
            <div className="absolute top-0 z-30 w-full h-full backdrop-blur rounded-xl">
              <div className="w-full h-full relative p-8">
                <div className="flex w-full justify-between">
                  <span className="font-bold text-xl">{t('Index')}</span>
                  <div
                    className="flex justify-center items-center cursor-pointer"
                    onClick={closeShowIndexDocument}>
                    <Close />
                  </div>
                </div>
                <div className="h-full max-h-[calc(100vh-190px)] overflow-y-auto">
                  {data?.resData?.data?.bookmarks?.length < 1 ? (
                    <Typography.Text>{t('There is no index on document')}</Typography.Text>
                  ) : (
                    data?.resData?.data?.bookmarks?.map((bookmark, item) => (
                      <div
                        key={`bookmark-doc-${item}`}
                        className="mb-4 hover:cursor-pointer hover:bg-[#DEDEDE] p-1 rounded-sm"
                        onClick={() => jumpToPage(bookmark.page_from)}>
                        <li>
                          <Typography.Text>{bookmark?.title}</Typography.Text>
                        </li>
                      </div>
                    ))
                  )}
                </div>
                <div className="absolute h-7 w-7 backdrop-blur bg-white border-[#E3E3E8] opacity-80 border-2 border-solid border-l-0 border-b-0 bottom-12 rotate-45 -right-4"></div>
              </div>
            </div>
          ) : null}
          {/* Breadcrumbs */}
          <div className="flex flex-col gap-2 pl-8 pb-6 border-0 border-solid border-b-2 border-[#E3E3E8]">
            <Breadcrumbs>
              <Typography.Text
                className="text-[#828282] hover:text-black font-normal text-[14px] cursor-pointer"
                onClick={() => {
                  handleNavigation(`/cases`)
                }}>
                {t('Cases')}
              </Typography.Text>
              <Typography.Text
                className="text-[#828282] hover:text-black font-normal text-[14px] cursor-pointer"
                onClick={handleNavigation.bind(
                  this,
                  `/cases/${params?.caseId}`,
                  {
                    data: { id: params?.caseId }
                  },
                  true
                )}>
                {detailCase?.name}
              </Typography.Text>
              <Typography.Text className="text-black font-normal text-[14px]">{t('Chat')}</Typography.Text>
            </Breadcrumbs>
            {/* Title Chat */}
            <Typography.Text className="text-black font-semibold text-[30px] text-ellipsis line-clamp-1">
              {detailChat?.name}
            </Typography.Text>
          </div>

          {/* Chat Content Wrapper */}
          <div className="flex px-5 max-h-[calc(100vh-170px)] h-full justify-between flex-col items-center">
            <ChatContainer
              ref={chatHistoryRef}
              onAddNote={() => noteRef?.current?.addNewNote('Chat', detailChat)}
              onRegenerateMessage={handleRegenerateMessage}
              chatHistory={detailChat?.messages ? detailChat?.messages : []}
              onSubmit={handleSubmitChat}
              onSourceClick={handleSourceClick}
              isLoading={
                postChatMessageMutation.isLoading || postRegenerateMessageMutation.isLoading
              }
            />
          </div>
        </div>
        {/* Docs Wrapper */}
        <div
          className={` border-0 border-solid border-l-2 border-[#E3E3E8]  ${
            docsWindow ? 'col-span-6' : 'col-span-1'
          }`}>
          {/* Header */}
          <div
            className={`flex gap-5 pl-3 pr-3 pt-3 pb-[27px] border-0 border-solid border-b-2 border-[#E3E3E8] ${
              docsWindow ? ' justify-start' : 'justify-center'
            }`}>
            {/* Button */}
            <div
              onClick={toggleDocsWindow}
              className={`w-16 h-24 shrink-0 cursor-pointer ${
                docsWindow
                  ? 'bg-white text-[#0049EE] border border-solid border-[#E3E3E8] hover:text-[#828282] hover:bg-[#EDEDEF]'
                  : 'bg-[#EDEDEF] text-[#828282] hover:bg-white hover:text-[#0049EE] hover:border hover:border-solid hover:border-[#E3E3E8]'
              } flex flex-col rounded-[10px] justify-center items-center gap-1 text-xs`}>
              {/* Total Files */}
              <span className="text-lg font-semibold">{detailChat?.documents?.length}</span>
              <span>{t('Files')}</span>
              {docsWindow ? <East /> : <KeyboardBackspaceOutlined />}
            </div>
            {/* docs btn & title */}
            <div
              className={`${
                docsWindow ? 'flex' : 'hidden'
              }  flex-col gap-2 justify-between overflow-hidden pr-3`}>
              <div className="flex gap-2 overflow-x-auto w-full whitespace-nowrap h-10">
                <div className="flex gap-2">
                  {detailChat?.documents?.map((doc, idx) => (
                    <Tooltip
                      arrow
                      onClick={() => handleChangeSelectedDocument(doc)}
                      placement="bottom"
                      key={idx}
                      style={{ flex: '0 0 auto' }}
                      className={`${
                        doc?.is_deleted
                          ? 'bg-[#EDEDEF] text-[#828282]'
                          : doc?.id === selectedDocument?.id
                          ? 'bg-[#0049EE] text-white'
                          : 'bg-[#EDEDEF] text-[#828282] hover:bg-[#0049EE] hover:text-white'
                      } w-6 h-6 cursor-pointer text-[10px] flex justify-center items-center rounded `}
                      title={doc?.is_deleted ? 'This file was deleted' : doc?.filename}>
                      <span>{doc?.filename?.slice(0, 2).toUpperCase()}</span>
                    </Tooltip>
                  ))}
                </div>
              </div>
              <Tooltip placement="bottom-start" title={selectedDocument?.filename}>
                <span className="text-[#0F111A] text-3xl font-semibold text-ellipsis line-clamp-1">
                  {selectedDocument?.filename}
                </span>
              </Tooltip>
            </div>
          </div>
          {/* Docs Viewer */}
          <div className={`${docsWindow ? 'flex' : 'hidden'}  overflow-hidden`}>
            {isFetching ? (
              <div className="flex flex-1 w-full h-[calc(100vh-170px)] justify-center items-center my-auto">
                <Spin />
              </div>
            ) : data == undefined && !isFetching && !selectedDocument ? (
              <div className="flex justify-center items-center w-full h-[calc(100vh-170px)]">
                {t('All documents have been deleted')}
              </div>
            ) : data == undefined && !isFetching && selectedDocument ? (
              <div className="flex justify-center items-center w-full h-[calc(100vh-170px)]">
                {t('An Error occured, please reload the page')}
              </div>
            ) : selectedDocument?.is_deleted ? (
              <div className="flex justify-center items-center w-full h-[calc(100vh-170px)]">
                {t('Document is deleted')}
              </div>
            ) : (
              <DocumentComparison
                CurrentPageLabel={CurrentPageLabel}
                plugins={[pageNavigationInstance]}
                selectedDocument={selectedDocument}
                showIndexDocument={showIndexDocument}
                openShowIndexDocument={openShowIndexDocument}
                onAddNote={() => noteRef?.current?.addNewNote('Document', selectedDocument)}
                fileUrl={data?.resData?.data?.filepath ?? state?.document?.filepath}
              />
            )}
          </div>
        </div>
      </div>
      <NotesContainer
        ref={noteRef}
        setTabNote={setTabNote}
        tabNote={tabNote}
        caseId={params?.caseId}
        height={layoutInfo}
        caseName={detailCase?.name}
      />
      {referenceRegulationState.show ? (
        <ReferenceRegulationModal
          data={dataReferenceRegulation?.resData?.data}
          show={referenceRegulationState.show}
          isLoading={isReferenceFetching}
          closeModal={closeReferenceRegulationModal}
        />
      ) : null}
    </div>
  )
}

export default ChatPage

import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import IcFolder from 'assets/icons/IcFolder'
import { IcRegulation } from 'assets/icons'
import { NullAvatar } from 'assets/images'
import useAuth from 'hooks/useAuth'
import LogoContainer from 'components/atoms/LogoContainer'
const { Sider } = Layout

const activeLinkStyle =
  'flex no-underline mx-auto rounded-xl w-14 h-14 justify-center items-center px-2 py-3 border border-solid border-[#E3E3E8] bg-white'
const nonActiveLinkStyle =
  'flex no-underline mx-auto rounded-xl w-14 h-14 justify-center items-center px-2 py-3 border border-solid border-[#0000000A] bg-[#EDEDEF] hover:bg-white hover:border-[#E3E3E8]'

const SidebarUser = () => {
  const { platformData } = useAuth()
  const location = useLocation()
  return (
    <Sider width={100} className="overflow-x-hidden">
      {/* Logo Wrapper */}
      <div className="flex justify-center items-center py-5">
        <LogoContainer />
      </div>
      {/* Sidebar Navigation */}
      <div className="flex flex-col gap-8">
        {/* Workspace */}
        <NavLink
          to="/cases"
          className={({ isActive }) =>
            isActive || location.pathname === '/' ? activeLinkStyle : nonActiveLinkStyle
          }>
          {({ isActive }) => <IcFolder isActive={isActive || location.pathname === '/'} />}
        </NavLink>
        {/* Regulations */}
        <NavLink
          to="/regulations"
          className={({ isActive }) => (isActive ? activeLinkStyle : nonActiveLinkStyle)}>
          {({ isActive }) => <IcRegulation isActive={isActive} />}
        </NavLink>
      </div>
      <div className="fixed bottom-0 my-4 w-[100px] flex justify-center items-center">
        <NavLink
          to={'/profile'}
          className="bg-gray-200 w-14 h-14 overflow-hidden flex items-center justify-center rounded-full text-white font-bold mr-2 hover:cursor-pointer">
          <img
            src={platformData?.profile?.avatar_url || NullAvatar}
            className={`${
              platformData?.profile?.avatar_url
                ? 'object-cover w-full h-full'
                : 'object-contain w-7 h-7'
            } object-center`}
            alt="avatar"
            aria-label="avatar profile"
          />
        </NavLink>
      </div>
    </Sider>
  )
}

export default SidebarUser

import { Typography } from 'antd'
import { postConfirmOtp, postForgotPassword } from 'api'
import CustomButton from 'components/atoms/CustomButton'
import ErrorMessage from 'components/atoms/ErrorMessage'
import OtpInput from 'components/atoms/OtpInput'
import { BACK_OFFICE, PLATFORM } from 'constant'
import useNavigatorOnLine from 'hooks/useNavigatorOnline'
import React, { useRef, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { errorObj } from 'utils/handleError'
import { isAdminPage } from 'utils/isAdminPage'
import { showErrorMessage } from 'utils/showMessage'

const OTPForm = ({ onNavigate }) => {
  const { t } = useTranslation()
  const statusConnection = useNavigatorOnLine()
  const [otp, setOtp] = useState('')
  const navigate = useNavigate()
  const [errorOtp, setErrorOtp] = useState(null)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || localStorage.getItem('tokenOtp')
  const email = searchParams.get('email') || localStorage.getItem('emailReset')
  const otpRef = useRef()
  const ref = useRef(Date.now())
  const [countdown, setCountdown] = useState({
    key: 1,
    show: true
  })
  const time = 60000
  const renderer = ({ minutes, seconds }) => {
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    )
  }
  function handleCompleteCountdown() {
    setCountdown((prev) => ({
      ...prev,
      show: false
    }))
  }

  const forgotPassword = useMutation({
    mutationKey: 'forgotPassword',
    mutationFn: () => postForgotPassword(email, isAdminPage() ? BACK_OFFICE : PLATFORM),
    onSuccess: (res) => {
      localStorage.setItem('tokenOtp', res.resData.data)
      navigate(
        {
          pathname: isAdminPage() ? '/admin/reset-password' : '/reset-password',
          search: createSearchParams({
            token: res.resData.data,
            email: email
          }).toString()
        },
        {
          replace: true
        }
      )
    },
    onError: () => {}
  })
  const confirmOtp = useMutation({
    mutationKey: 'confirmOtp',
    mutationFn: () => postConfirmOtp(token, otp),
    onSuccess: (res) => {
      localStorage.setItem('tokenOtp', res.resData.data)
      onNavigate()
    },
    onError: (err) => {
      const errObj = errorObj(err)
      if (errObj.resStatus === 404) {
        setErrorOtp(isAdminPage() ? t('Wrong recovery code') : t('The code is invalid'))
      } else if (errObj.resStatus === 400) {
        setErrorOtp(isAdminPage() ? t('The recovery code is expired') : t('The code is expired'))
      }
    }
  })

  const onChange = (value) => setOtp(value)
  const handleClickResend = () => {
    if (!statusConnection) return showErrorMessage(t('Internet connection error'))
    forgotPassword.mutate()
    ref.current = Date.now()
    setCountdown((prev) => ({
      key: prev + 1,
      show: true
    }))
  }
  const handleSubmitOtp = () => {
    if (otp.trim() !== '') {
      setErrorOtp(null)
      return confirmOtp.mutate()
    } else
      return setErrorOtp(isAdminPage() ? t('Recovery code is required') : t('Code is required'))
  }
  return (
    <>
      {/* Typography */}
      <div className="flex flex-col justify-center items-center gap-2 ">
        <Typography.Title
          className={`${isAdminPage() ? '!text-white' : '!text-black'} font-bold text-lg text-h4`}>
          {isAdminPage() ? t('Enter recovery code') : t('Enter code')}
        </Typography.Title>
        <Typography.Paragraph className="text-[#595961] text-center">
          {isAdminPage()
            ? t('Enter the code you received at the email address you provided')
            : t('Enter the code you received at the email address given')}
        </Typography.Paragraph>
      </div>
      <div>
        {isAdminPage() ? (
          <div className="text-sm text-[#FFFFFFA6] font-normal mb-3">{t('Recovery code')}</div>
        ) : null}
        <OtpInput isError={errorOtp} value={otp} valueLength={6} onChange={onChange} />
        <input
          ref={otpRef}
          className="hidden"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
        {errorOtp ? <ErrorMessage className={'mt-2'} errorMessage={errorOtp} /> : null}
        {!isAdminPage() ? (
          <div className="flex justify-between items-center pt-2">
            <span
              onClick={countdown.show ? () => {} : handleClickResend}
              className={`underline font-normal text-sm ${
                countdown.show ? 'text-[#828282] ' : 'text-[#0049EE] cursor-pointer'
              }`}>
              {t('Resend code')}
            </span>
            {countdown.show ? (
              <span className="text-sm-medium text-gray-500">
                {t('Send new code in')}{' '}
                <Countdown
                  key={countdown.key}
                  date={ref.current + time}
                  zeroPadTime={2}
                  renderer={renderer}
                  onComplete={handleCompleteCountdown}
                />
              </span>
            ) : null}
          </div>
        ) : null}
        <div className="text-left mb-2 mt-4">
          <CustomButton
            isLoading={confirmOtp.isLoading}
            onClick={!confirmOtp.isLoading ? handleSubmitOtp : () => {}}
            fullWidth
            variant="contained"
            sx={{
              py: 1,
              mt: 4,
              backgroundColor: '#0049EE',
              fontWeight: 600,
              fontSize: '16px'
            }}>
            {isAdminPage() ? t('Verify code') : t('Confirm link')}
          </CustomButton>
          {isAdminPage() ? (
            <div
              className={`font-manrope font-semibold text-sm flex w-full justify-center items-center mt-8`}>
              <span
                className={` text-[#828282] decoration-[#828282] ${
                  countdown.show ? '' : 'cursor-pointer hover:text-white'
                }`}
                onClick={countdown.show ? () => {} : handleClickResend}>
                {countdown.show ? (
                  <span className="text-sm-medium text-gray-500">
                    {t('Send new code in')}{' '}
                    <Countdown
                      key={countdown.key}
                      date={ref.current + time}
                      zeroPadTime={2}
                      renderer={renderer}
                      onComplete={handleCompleteCountdown}
                    />
                  </span>
                ) : (
                  t('Send new code')
                )}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default OTPForm

import { IcChatEmpty } from 'assets/icons'
import BotResponseItem from 'components/molecules/user/chat/BotResponseItem'
import UserResponseItem from 'components/molecules/user/chat/UserResponseItem'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const ChatHistoryList = ({
  chatHistory = [],
  onRegenerateMessage,
  isLoading,
  onAddNote = () => {},
  onSourceClick
}) => {
  const { t } = useTranslation()
  const bottomRef = useRef(null)
  useEffect(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [chatHistory.length, chatHistory])
  return (
    <>
      {chatHistory && chatHistory.length > 0 ? (
        <>
          {chatHistory.map((chatItem, index) => {
            if (chatItem?.role == 'user') {
              return <UserResponseItem key={`user-response-${index}`} text={chatItem?.content} />
            }
            if (chatItem?.role == 'assistant') {
              return (
                <BotResponseItem
                  key={`bot-response-${index}`}
                  onSourceClick={onSourceClick}
                  onAddNote={onAddNote}
                  isLoading={isLoading}
                  onRegenerate={onRegenerateMessage}
                  isLastIndex={index == chatHistory.length - 1}
                  text={chatItem?.content}
                  sources={chatItem?.sources}
                />
              )
            }
          })}
        </>
      ) : (
        <div className="flex flex-col w-full h-screen justify-center items-center">
          <IcChatEmpty className={'drop-shadow-lg'} />
          <span className="text-2xl font-medium -mt-10 text-black">{t('How can I help you?')}</span>
        </div>
      )}
      <div ref={bottomRef}></div>
    </>
  )
}

export default ChatHistoryList

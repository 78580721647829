import CustomButton from 'components/atoms/CustomButton'
import React, { useState } from 'react'
import UsersPairCompanyModal from './UsersPairCompanyModal'
import { CHANGE_COMPANY, PAIR_COMPANY } from 'constant'
import { useMutation, useQuery } from 'react-query'
import { errorObj } from 'utils/handleError'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { getCompanyList, putPairUserCompany } from 'api'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
const titleClassName = 'text-[#FFFFFF7A] font-manrope text-xs'
const descriptionClassName = 'font-manrope text-lg text-white'

const PairUserCompany = ({
  isUserPaired,
  role = '',
  company = {},
  userId,
  refetchUserDetail,
  navigateToAddCompany,
  changedCompanyDisabled
}) => {
  const { t } = useTranslation()
  const { getAccessToken } = useAuth()
  const [modalChangeCompany, setModalChangeCompany] = useState({
    // Type pair / change
    type: isUserPaired ? CHANGE_COMPANY : PAIR_COMPANY,
    show: false
  })
  const [companyList, setCompanyList] = useState([])

  useQuery({
    queryKey: ['getCompanyQuery'],
    queryFn: () => getCompanyList(getAccessToken()),
    onSuccess: (data) => {
      const newCompanyListOptions = data.resData.data.map((company) => ({
        label: company?.name,
        value: company?.id,
        ...company
      }))
      setCompanyList(newCompanyListOptions)
    },
    onError: (err) => {
      const objErr = errorObj(err)
      showErrorMessage(t('Failed fetch company list options,Please refresh the page'))
    }
  })

  const changeCompanyUser = useMutation({
    mutationKey: 'change Company',
    mutationFn: (data) => putPairUserCompany(getAccessToken(), userId, data?.company, data?.role),
    onSuccess: () => {
      refetchUserDetail()
      showSuccessMessage(t('Company changed'))
      closeModalCompanyUpdate()
    },
    onError: (err) => {
      showErrorMessage(t('Company could not be changed'))
    }
  })

  function onChangeCompany(data) {
    changeCompanyUser.mutate(data)
  }

  const openModalCompanyUpdate = (type) => {
    setModalChangeCompany((prev) => ({ ...prev, show: true, type: type }))
  }

  function closeModalCompanyUpdate() {
    setModalChangeCompany((prev) => ({ ...prev, show: false }))
  }
  return (
    <div className="grid grid-cols-4">
      <span className="text-white font-manrope text-2xl">{t('Pairing')}</span>
      {isUserPaired ? (
        <>
          <div className="flex flex-col gap-1 items-start">
            <span className={titleClassName}>{t('COMPANY')}</span>
            <span className={descriptionClassName}>{company?.name}</span>
          </div>
          <div className="flex flex-col gap-1 items-start">
            <span className={titleClassName}>{t('ROLE')}</span>
            <span className={descriptionClassName}>{role}</span>
          </div>
        </>
      ) : (
        <div className="col-span-2 flex items-center">
          <span className={'text-[#FFFFFFA3] text-lg font-manrope'}>
            {t('No information entered')}
          </span>
        </div>
      )}
      <div className="flex justify-end items-start">
        {isUserPaired ? (
          <CustomButton
            disabled={changedCompanyDisabled}
            onClick={openModalCompanyUpdate.bind(this, CHANGE_COMPANY)}
            buttonColor="secondary">
            {t('Change company')}
          </CustomButton>
        ) : (
          <CustomButton
            disabled={changedCompanyDisabled}
            onClick={openModalCompanyUpdate.bind(this, PAIR_COMPANY)}>
            {t('Pair Company')}
          </CustomButton>
        )}
      </div>
      {modalChangeCompany.show ? (
        <UsersPairCompanyModal
          companyList={companyList}
          currentCompanyId={company?.id}
          currentRole={role}
          navigateToAddCompany={navigateToAddCompany}
          onClose={closeModalCompanyUpdate}
          show={modalChangeCompany.show}
          status={modalChangeCompany.type}
          onPairCompany={onChangeCompany}
          isLoading={changeCompanyUser.isLoading}
        />
      ) : null}
    </div>
  )
}

export default PairUserCompany

import { apiGet } from 'api/api-get'
import { apiPut } from 'api/api-put'
import { urls } from 'api/api-url'

export const getTCByUser = (token) => {
  return apiGet({ url: urls.termsConditions.termsConditonsByUser, token: token })
}
export const getTC = (token) => {
  return apiGet({ url: urls.termsConditions.termsConditions, token: token })
}
export const putAcceptTC = (token) => {
  return apiPut({
    url: `${urls.termsConditions.termsConditonsByUser}/${urls.termsConditions.acceptTermsConditions}`,
    token: token
  })
}

import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import CustomButton from 'components/atoms/CustomButton'
import DatePickerFields from 'components/molecules/DatePickerFields'
import FilterSidebar from 'components/molecules/FilterSidebar'
import MultipleSelectFields from 'components/molecules/MultipleSelectFields'
import SelectFields from 'components/molecules/SelectFields'
import { ROLE_ADMIN, ROLE_USER } from 'constant'
import moment from 'moment'
// import { staticRole } from 'data/roles'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

const schema = z.object({
  role: z.string().array().optional(),
  typeDate: z.string().optional(),
  startDate: z.instanceof(moment).optional(),
  endDate: z.instanceof(moment).optional()
})

const FilterPairUserCompany = ({
  show,
  onClose,
  onSubmit,
  // resetFilter,
  filterState
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    // setError,
    watch
  } = useForm({
    defaultValues: {
      role: filterState.role || undefined,
      typeDate: filterState.typeDate || undefined,
      startDate: filterState.startDate || undefined,
      endDate: filterState.endDate || undefined
    },
    resolver: zodResolver(schema)
  })
  const watchRole = watch('role')
  const watchTypeDate = watch('typeDate')
  const staticDate = [
    {
      label: t('Less than'),
      value: 'less_than'
    },
    {
      label: t('Greater than'),
      value: 'greater_than'
    },
    {
      label: t('Between'),
      value: 'between'
    }
  ]

  function processForm(data) {
    onSubmit(data)
    onClose()
  }

  return (
    <FilterSidebar show={show} onClose={onClose}>
      <Box
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-4">
          <MultipleSelectFields
            labelClassName={'!text-white'}
            control={control}
            placeholder={t('Choose Role')}
            label={t('Role')}
            state={watchRole}
            errors={errors}
            name={'role'}
            options={[
              {
                label: 'Admin',
                value: ROLE_ADMIN
              },
              {
                label: 'User',
                value: ROLE_USER
              }
            ]}
          />
          <SelectFields
            labelClassName={'!text-white'}
            control={control}
            label={t('Date')}
            placeholder={t('Choose date')}
            errors={errors}
            name={'typeDate'}
            options={staticDate}
          />

          <DatePickerFields
            labelClassName={'!text-white'}
            control={control}
            name={'startDate'}
            placeholder={t('Choose date')}
            errors={errors}
          />
          {watchTypeDate === 'between' && (
            <DatePickerFields
              labelClassName={'!text-white'}
              control={control}
              name={'endDate'}
              placeholder={t('Choose date')}
              errors={errors}
            />
          )}
        </div>
        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" onClick={reset}>
            {t('Remove Filter')}
          </CustomButton>
          <CustomButton buttonType="submit">{t('Apply')}</CustomButton>
        </div>
      </Box>
    </FilterSidebar>
  )
}

export default FilterPairUserCompany

import React, { useState, useRef, useMemo, createContext, useContext } from 'react'
import {
  ChatBubbleOutline,
  InsertDriveFileOutlined,
  StickyNote2Outlined
} from '@mui/icons-material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CloseIcon from '@mui/icons-material/Close'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Typography, Input } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { deleteNote, updateDataNote } from 'api'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { showSuccessMessage, showErrorMessage } from 'utils/showMessage'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { errorObj } from 'utils/handleError'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { useTranslation } from 'react-i18next'

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {}
})
const NoteCardExpand = ({
  text,
  note_type,
  created_at,
  chat,
  document,
  edit,
  todo_date,
  setNoteData,
  id,
  tabNote,
  height,
  setHeight,
}) => {
  const { t } = useTranslation()
  const { TextArea } = Input
  const [idValue, setIdValue] = useState(null)
  const ref = useRef(null)

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id })

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef
    }),
    [attributes, listeners, setActivatorNodeRef]
  )

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition
  }

  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user.access_token)

  const clickToEdit = (id) => {
    setIdValue(id)
    ref?.current?.clientHeight && setHeight(ref?.current?.clientHeight)

    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            edit: true
          }
        } else {
          return {
            ...item,
            edit: false
          }
        }
      })
    })
  }

  const onChangeText = (id, e) => {
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            text: e.target.value
          }
        } else {
          return item
        }
      })
    })
  }

  const onChangetodoDate = (value) => {
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            todo_date: value
          }
        } else {
          return item
        }
      })
    })
  }

  const onChangeTimePicker = (value) => {
    const newTime = moment(value).format('HH:mm')
    const timeZone = moment(value).format('Z')
    setNoteData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            todo_date: moment(item.todo_date).format('YYYY-MM-DD') + 'T' + newTime + timeZone
          }
        } else {
          return item
        }
      })
    })
  }

  const deletingNote = useMutation({
    mutationKey: ['delete-note'],
    mutationFn: async (id) => await deleteNote(token, id),
    onSuccess: () => {
      showSuccessMessage(t('Note deleted successfully'))
      queryClient.invalidateQueries(['note-list-insight'])
      queryClient.invalidateQueries(['note-list-todo'])
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)
    }
  })

  const onClickDelete = (id) => {
    deletingNote.mutate(id)
  }
  const updatingDataNote = useMutation({
    mutationKey: ['update-data-note'],
    mutationFn: async (data) => await updateDataNote(token, idValue, data),
    onSuccess: () => {
      showSuccessMessage(t('Note updated successfully'))
      queryClient.invalidateQueries(['note-list'])
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)
      queryClient.invalidateQueries(['note-list-insight'])

      queryClient.invalidateQueries(['note-list-todo'])
    }
  })

  const onEnterInsight = (e) => {
    if (e.key === 'Enter') {
      const payload = {
        text: text
      }
      updatingDataNote.mutate(payload)
      setNoteData((prev) => {
        return prev.map((item) => {
          if (item.id === idValue) {
            return {
              ...item,
              edit: false
            }
          } else {
            return item
          }
        })
      })
    }
  }
  const onEnterTodo = (e) => {
    if (e.key === 'Enter') {
      const payload = {
        text: text,
        todo_date: moment(todo_date).format('YYYY-MM-DDTHH:mm:ssZ')
      }
      updatingDataNote.mutate(payload)

      setNoteData((prev) => {
        return prev.map((item) => {
          if (item.id === idValue) {
            return {
              ...item,
              edit: false
            }
          } else {
            return item
          }
        })
      })
    }
  }

  const DragHandle = () => {
    const { attributes, listeners, ref } = useContext(SortableItemContext)
    return (
      <DragHandleIcon
        style={{
          fontSize: 20,
          color: '#828282'
        }}
        {...attributes}
        {...listeners}
        ref={ref}
      />
    )
  }

  return (
    <SortableItemContext.Provider value={context}>
      <div
        className={`w-full   ${
          edit  ? 'bg-white border-solid border border-black' : 'bg-[#EDEDEF] '
        } p-4 rounded-lg mb-3 ${
          edit 
            ? ''
            : 'group/item hover:border-solid border  hover:border-[#0049EE] hover:bg-blue-100'
        }`}
        ref={setNodeRef}
        style={style}
        onClick={() => clickToEdit(id)}>
        <div className="flex justify-between items-center">
          {/* <div className="flex items-center cursor-grab">
            <DragHandle
              style={{
                height: '20px',
                width: '20px'
              }}
            />

            {!edit  && (
              <Typography.Text className="mb-4 text-black block ml-4" ref={ref}>
                {text}
              </Typography.Text>
            )}
            
          </div> */}

          {edit && idValue === id && (
            <TextArea
              rows={4}
              className="border-0 outline-0 focus:outline-0 focus:outline-none focus:border-0 focus:ring-0 ml-4 "
              value={text}
              onChange={(e) => onChangeText(id, e)}
              onKeyDown={tabNote === 1 ? onEnterTodo : onEnterInsight}
              style={{
                resize: 'none',
                height:
                  height === 0 || height === undefined || height === null ? 'auto' : height + 100
              }}
            />
          )}

          {!edit && (
            <>
              <div className="flex items-center cursor-grab">
                <DragHandle />

                <Typography.Text className="mb-4 text-black block ml-4" ref={ref}>
                  {text}
                </Typography.Text>
              </div>

              <CloseIcon
                className={
                  edit ? 'cursor-pointer' : 'cursor-pointer invisible group-hover/item:visible'
                }
                onClick={() => onClickDelete(id)}
              />
            </>
          )}
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center ml-[40px]">
            {note_type === 'CHAT' && (
              // <ChatBubbleOutline
              //   fontSize="small"
              //   className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              // />
              <div className="flex">
              <ChatBubbleOutline
                fontSize="small"
                className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              />
              {/* <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
                Chat
              </Typography.Text> */}
            </div>
            )}
            {note_type === 'DOCUMENT' && (
              // <InsertDriveFileOutlined
              //   fontSize="small"
              //   className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              // />
              <div className="flex">
              <InsertDriveFileOutlined
                fontSize="small"
                className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              />
              {/* <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
                Document
              </Typography.Text> */}
            </div>
            )}
            {note_type === 'INSIGHT' && (
              <StickyNote2Outlined
                fontSize="small"
                className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              />
            )}
            {note_type === 'TODO' && (
              <CalendarTodayIcon
                fontSize="small"
                className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
              />
            )}
            {note_type === 'INSIGHT' && (
              <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
                {t('Free Insight')}
              </Typography.Text>
            )}
            {note_type === 'CHAT' && (
              <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
                {chat?.name}
              </Typography.Text>
            )}
            {note_type === 'DOCUMENT' && (
              <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
                {document?.filename}
              </Typography.Text>
            )}
            {note_type === 'TODO' && edit && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  onChange={(value) => onChangetodoDate(value)}
                  value={todo_date ? moment(todo_date) : null}
                  format="DD/MM/YYYY"
                  defaultValue={null}
                  slotProps={{
                    textField: {
                      size: 'small',
                      onKeyDown: onEnterTodo
                    }
                  }}
                />
              </LocalizationProvider>
            )}

            {note_type === 'TODO' && !edit && (
              <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
                {moment(todo_date).format('DD/MM/yyyy')}
              </Typography.Text>
            )}
          </div>
          {note_type === 'TODO' ? (
            !edit ? (
              <div className="flex items-center">
                <AccessTimeIcon
                  fontSize="small"
                  className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
                />
                <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
                  {moment(todo_date).format('HH:mm')}
                </Typography.Text>
              </div>
            ) : (
              <div className="flex items-center">
                <AccessTimeIcon
                  fontSize="small"
                  className="text-[#828282] mr-1 group-hover/item:text-[#0049EE]"
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileTimePicker
                    value={moment(todo_date)}
                    onChange={(value) => onChangeTimePicker(value)}
                    slotProps={{
                      textField: {
                        size: 'small',
                        onKeyDown: onEnterTodo
                      }
                    }}
                  />
                </LocalizationProvider>
              </div>
            )
          ) : (
            <Typography.Text className="text-[#828282] text-[12px] group-hover/item:text-[#0049EE]">
              {moment(created_at).format('DD/MM/yyyy')}
            </Typography.Text>
          )}
        </div>
      </div>
    </SortableItemContext.Provider>
  )
}

export default NoteCardExpand

import React from 'react'
import { Checkbox } from '@mui/material'
import { Tooltip } from 'antd'
import CustomButton from 'components/atoms/CustomButton'
import Table from 'components/atoms/Table'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate }



const RegulationSelectableTable = ({
  isFetchingDocumentByCase,
  handleSort = () => {},
  totalFilterActive,
  tableState,
  handleChangeSelectedDocuments,
  documentListByCase,
  selectedDocuments,
  isConfirming,
  closeModal,
  handleConfirmNewChat,
  clearSelected
}) => {
  const { t } = useTranslation();
  const columnsSpecificCases = [
    {
      id: 'filename',
      name: t('Source name'),
      sortField: 'filename',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <Tooltip title={row?.filename}>
            <span className="line-clamp-1">{row?.filename}</span>
          </Tooltip>
        </div>
      ),
      width: '35%',
      selector: (row) => row?.filename,
      sortable: true
    },
    {
      id: 'last_update',
      name: t('Last update'),
      sortField: 'updated_at',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <Tooltip title={moment(row?.last_update).format('DD/MM/YYYY')}>
            <span className="line-clamp-1">{moment(row?.last_update).format('DD/MM/YYYY')}</span>
          </Tooltip>
        </div>
      ),
      width: '35%',
      selector: (row) => row?.last_update,
      sortable: true
    },
    {
      id: 'format',
      name: t('Format'),
      sortField: 'ext',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <Tooltip title={row?.ext}>
            <span className="line-clamp-1">{row?.ext}</span>
          </Tooltip>
        </div>
      ),
      width: '20%',
      selector: (row) => row?.ext,
      sortable: true
    }
  ]
  return (
    <>
      <Table
        pending={isFetchingDocumentByCase}
        highlightOnHover
        backgroundColorHeadCells="#F7F7F8"
        onSort={(e) => handleSort(e)}
        noDataComponent={
          <div className="flex w-full justify-start items-center text-[#828282] p-4">
            {totalFilterActive > 0 || tableState.search
              ? 'No documents available'
              : 'There are no documents uploaded in this folder'}
          </div>
        }
        defaultSortFieldId={'created_at'}
        defaultSortAsc={false}
        sortServer
        selectableRows={true}
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={selectProps}
        onSelectedRowsChange={handleChangeSelectedDocuments}
        selectableRowsHighlight={true}
        fixedHeader
        className={'flex-auto h-[300px] overflow-y-auto pr-6'}
        
        hidePagination
        columns={columnsSpecificCases}
        data={documentListByCase}
        clearSelectedRows={clearSelected}
      />
      {documentListByCase.length > 0 ? (
        <div className="flex w-full py-4 pl-3">
          <span className="text-[#0049EE] text-small">
            {selectedDocuments?.length} {t("documents selected")}
          </span>
        </div>
      ) : null}
      <div className='pl-3'>
        <p>Disclaimer: you have to allow the Legal AI site to send pop-ups or use redirects on your browser to enable download all document’s function.</p>
      </div>
      <div className="flex justify-end items-center gap-4">
        <CustomButton
          buttonColor="secondary"
          disabled={isConfirming}
          onClick={isConfirming ? () => {} : closeModal}>
          {t("Cancel")}
        </CustomButton>
        <CustomButton
          buttonColor={selectedDocuments.length > 0 ? 'primary' : 'secondary'}
          disabled={selectedDocuments.length > 0 ? false : true}
          isLoading={isConfirming}
          onClick={isConfirming ? () => {} : handleConfirmNewChat}>
          {t("Export")}
        </CustomButton>
      </div>
    </>
  )
}

export default RegulationSelectableTable

import { getUserDetailById } from 'api'
import ContactInfoUser from 'components/molecules/admin/users/ContactInfoUser'
import PairUserCompany from 'components/organisms/admin/users/PairUserCompany'
import UsersDetailHeader from 'components/organisms/admin/users/UsersDetailHeader'
import useAuth from 'hooks/useAuth'
import moment from 'moment'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { errorObj } from 'utils/handleError'
import { showErrorMessage } from 'utils/showMessage'

const UsersDetailPage = () => {
  const { getAccessToken } = useAuth()
  const navigate = useNavigate()
  const params = useParams()
  const [userDetail, setUserDetail] = useState({})

  const { refetch } = useQuery({
    queryKey: ['getUserDetail', params?.id],
    queryFn: () => getUserDetailById(getAccessToken(), params?.id),
    onSuccess: (res) => {
      setUserDetail(res.resData.data)
    },
    onError: (err) => {
      const errObj = errorObj(err)
      if (errObj.resStatus === 400) {
        showErrorMessage(errObj.resData.errors[0].message)
        handleNavigationBack()
      }
    }
  })
  function navigateToAddCompany() {
    navigate('/admin/companies', { state: { addNew: true } })
  }

  const handleNavigationBack = () => {
    return navigate(-1)
  }

  return (
    <div className="flex flex-col gap-6">
      <UsersDetailHeader
        userDetail={userDetail}
        name={`${userDetail?.name || ''} ${userDetail?.surname || ''}`}
        created_at={moment(userDetail?.created_at).format('DD/MM/YYYY')}
        isAccountSuspend={!userDetail?.is_active_flag}
        goBackNav={handleNavigationBack}
        userId={params?.id}
        refetchUserDetail={refetch}
      />
      <ContactInfoUser email={userDetail?.email} phoneNumber={userDetail?.phone} />
      <PairUserCompany
        changedCompanyDisabled={!userDetail?.company?.is_active_flag || !userDetail?.is_active_flag}
        userId={params?.id}
        isUserPaired={userDetail?.company?.id && userDetail?.company?.id !== 0 ? true : false}
        company={userDetail?.company}
        role={userDetail?.role?.role}
        refetchUserDetail={refetch}
        navigateToAddCompany={navigateToAddCompany}
      />
    </div>
  )
}

export default UsersDetailPage

import { getOtherCases, getRegulationList, getStatusListDetailCase } from 'api'
import { INITIAL_WORKSPACE_FILTER } from 'constant'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { errorObj } from 'utils/handleError'
import { showErrorMessage } from 'utils/showMessage'

const useSearchOtherCases = (tableState, isContentSearchOtherCases) => {
  const { getAccessToken } = useAuth()

  const [otherCasesList, setOtherCasesList] = useState([])
  const [otherCasesFilterState, setOtherCasesFilterState] = useState(INITIAL_WORKSPACE_FILTER)
  // Fetch Other Cases
  const { isFetching: isFetchingOtherCases } = useQuery({
    queryKey: [otherCasesFilterState.data, tableState],
    queryFn: () => {
      let regulation_ids = []
      let status_ids = []
      if (otherCasesFilterState?.data?.regulation?.value)
        regulation_ids =
          Object.keys(otherCasesFilterState?.data?.regulation?.value)?.map?.((item) => {
            return otherCasesFilterState?.data?.regulation?.value?.[item]
          }) ?? []
      if (otherCasesFilterState?.data?.status?.value)
        status_ids =
          Object.keys(otherCasesFilterState?.data?.status?.value)?.map?.((item) => {
            return otherCasesFilterState?.data?.status?.value?.[item]
          }) ?? []

      const data = {
        regulations: regulation_ids,
        status: status_ids
      }
      return getOtherCases(getAccessToken(), tableState, data)
    },
    onSuccess: (data) => {
      setOtherCasesList(data?.resData?.data)
    },
    onError: (err) => {
      const objErr = errorObj(err)
      showErrorMessage('Failed fetch document list, Please refresh the page')
    },
    enabled: isContentSearchOtherCases()
  })
  const regulationsData = useQuery({
    queryKey: ['workspace-regulation-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getRegulationList(getAccessToken())
      const regulations = res?.resData?.data?.filter?.(
        (item) =>
          item?.title?.toLowerCase() === 'civil' || item?.title?.toLowerCase() === 'criminal'
      )
      return regulations
    },
    enabled: isContentSearchOtherCases()
  })

  const casesStatus = useQuery({
    queryKey: ['workspace-cases-status-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getStatusListDetailCase(getAccessToken())
      return res
    },
    enabled: isContentSearchOtherCases()
  })
  function openFilterOtherCases() {
    setOtherCasesFilterState((prev) => ({ ...prev, show: true }))
  }

  function closeFilterOtherCases() {
    setOtherCasesFilterState((prev) => ({ ...prev, show: false }))
  }
  function handleSubmitFilterOtherCases(data) {
    setOtherCasesFilterState((prev) => ({ ...prev, data: { ...data } }))
    closeFilterOtherCases()
  }
  function handleOtherCasesResetFilterState() {
    setOtherCasesFilterState(INITIAL_WORKSPACE_FILTER)
    closeFilterOtherCases()
  }
  function resetOtherCaseList() {
    setOtherCasesList([])
  }
  return {
    otherCasesFilterState,
    otherCasesList,
    isFetchingOtherCases,
    casesStatus,
    regulationsData,
    openFilterOtherCases,
    closeFilterOtherCases,
    handleSubmitFilterOtherCases,
    handleOtherCasesResetFilterState,
    resetOtherCaseList
  }
}

export default useSearchOtherCases

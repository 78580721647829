import React, { useRef, useEffect } from 'react'
import NoteCard from 'components/atoms/user/cases/NoteCard'
import NotePanel from 'components/atoms/user/cases/NotePanel'
import { useSelector } from 'react-redux'
const ListNotes = ({
  value,
  dataInsight,
  dataTodo,
  height,
  setNoteDataInsight,
  setNoteDataTodo,
  noteDataInsight,
  noteDataTodo,
  caseId
}) => {
  const isExpandNoteLocalStorage = localStorage.getItem('isExpandNote')
  // const handleClickOutside = (event) => {
  //   if (componentRef.current && !componentRef.current.contains(event.target)) {
  //     onClickOutsideHandler()
  //   }
  // }
  // const onClickOutsideHandler = () => {
  //   setNoteDataInsight((prev) => {
  //     return prev.map((item) => ({
  //       ...item,
  //       edit: false
  //     }))
  //   })
  //   setNoteDataTodo((prev) => {
  //     return prev.map((item) => ({
  //       ...item,
  //       edit: false
  //     }))
  //   })
  // }

  // useEffect(() => {
  //   if (isExpandNoteLocalStorage == 'false') {
  //     document.addEventListener('mousedown', handleClickOutside)
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside)
  //     }
  //   }
  // }, [])

  const onClickOutsideListener = () => {
    document.removeEventListener('click', onClickOutsideListener)
  }

  const onMouseLeaveHandler = () => {
    document.addEventListener('click', onClickOutsideListener)
    setNoteDataInsight((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          edit: false
        }
      })
    })
    setNoteDataTodo((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          edit: false
        }
      })
    })
  }
  return (
    <div
      className="w-full overflow-y-scroll py-2 "
      style={{ height: height }}
      onMouseLeave={() => onMouseLeaveHandler()}>
      <NotePanel value={value} index={0}>
        {dataInsight.map((note, idx) => (
          <NoteCard
            key={`note-${idx}`}
            className="last:mb-10"
            setNoteData={setNoteDataInsight}
            noteData={noteDataInsight}
            tabNote={value}
            caseId={caseId}
            index={idx}
            {...note}
          />
        ))}
      </NotePanel>
      <NotePanel value={value} index={1}>
        {dataTodo.map((note, idx) => (
          <NoteCard
            key={`note-${idx}`}
            className="last:mb-10"
            setNoteData={setNoteDataTodo}
            noteData={noteDataTodo}
            tabNote={value}
            caseId={caseId}
            index={idx}
            {...note}
          />
        ))}
      </NotePanel>
    </div>
  )
}

export default ListNotes

import { apiGet } from 'api/api-get'
import { apiPut } from 'api/api-put'
import { apiPost } from 'api/api-post'
import { apiDelete } from 'api/api-delete'
import { urls } from 'api/api-url'
import moment from 'moment'

export const getDetailCase = (token, caseId) => {
  return apiGet({
    url: `${urls.detailCase.cases}/${caseId}`,
    token: token
  })
}

export const getChatListDetailCase = (token, filterState, tableState, caseStatusId) => {
  let urlParam = ''
  if (tableState.search) urlParam += `&search=${tableState.search}`

  if (filterState.typeDate) urlParam += `&filter_date_type=${filterState.typeDate}`

  if (filterState.startDate)
    urlParam += `&first_date=${moment(filterState.startDate).format('DD/MM/YYYY')}`

  if (filterState.endDate)
    urlParam += `&second_date=${moment(filterState.endDate).format('DD/MM/YYYY')}`

  return apiGet({
    url: `${urls.detailCase.chatList}?limit=${tableState.limit}&page=${tableState.page}&sort=${tableState.sort}&case_id=${caseStatusId}${urlParam}`,
    token: token
  })
}

export const getDocumentListDetailCase = (token, filterState, tableState, caseStatusId) => {
  let urlParam = ''
  if (tableState.search) urlParam += `&search=${tableState.search}`

  if (filterState.typeDate) urlParam += `&filter_date_type=${filterState.typeDate}`

  if (filterState.startDate)
    urlParam += `&first_date=${moment(filterState.startDate).format('DD/MM/YYYY')}`

  if (filterState.endDate)
    urlParam += `&second_date=${moment(filterState.endDate).format('DD/MM/YYYY')}`

  return apiGet({
    url: `${urls.detailCase.documentList}?limit=${tableState.limit}&page=${tableState.page}&sort=${tableState.sort}&case_id=${caseStatusId}${urlParam}`,
    token: token
  })
}

export const getStatusListDetailCase = (token, regulationId) => {
  let request = {
    url: `${urls.detailCase.statusList}`,
    token: token,
  }
  if(regulationId) {
    request = {
      ...request,
      data: {
        regulation_id: regulationId
      }
    }
  }
  return apiGet(request)
}
export const updateStatusDetailCase = (token, caseId, id) => {
  return apiPut({
    url: `${urls.detailCase.cases}/${caseId}/change-status`,
    token: token,
    data: {
      case_status_id: id
    }
  })
}

export const updateChatNameDetailCase = (token, id, value) => {
  return apiPut({
    url: `${urls.detailCase.chatList}/${id}`,
    token: token,
    data: {
      name: value
    }
  })
}
export const deleteChat = (token, id) => {
  return apiDelete({
    url: `${urls.detailCase.chatList}/${id}`,
    token: token,
  })
}

export const deleteDocument = (token, id) => {
  return apiDelete({
    url: `${urls.detailCase.documentList}/${id}`,
    token: token,
  })
}

export const getNoteListDetailCase = (token, filterState, tableState, caseStatusId, noteType) => {
  let urlParam = ''
  if (tableState.search) urlParam += `&search=${tableState.search}`

  if (filterState.typeDate) urlParam += `&filter_date_type=${filterState.typeDate}`
  
  let filterDateType = '&filter_date_type='
  if (filterState.date?.value?.fromDate && filterState.date?.value?.toDate) {
    filterDateType += 'between'
  } else if (filterState.date?.value?.fromDate) {
    filterDateType += 'greater_than_or_equal'
  } else if (filterState.date?.value?.toDate) {
    filterDateType += 'less_than_or_equal'
  }

  if (filterState.date?.value?.fromDate)
    urlParam += `&first_date=${moment(filterState.date?.value?.fromDate).format('DD/MM/YYYY')}`

  if (filterState.date?.value?.toDate)
    urlParam += `&second_date=${moment(filterState.date?.value?.toDate).format('DD/MM/YYYY')}`

  if (filterState.time?.value?.fromTime)
    urlParam += `&todo_date_from=${moment(filterState.date?.value?.fromDate ?? null).format('DD/MM/YYYY')}+${moment(
      filterState.date?.value?.fromDate ?? null
    ).set('hour', filterState.time?.value?.fromTime?.split(":")[0])
    .set('minute', filterState.time?.value?.fromTime?.split(":")[1]).format('HH:mm')}`
    
  if (filterState.time?.value?.toTime)
    urlParam += `&todo_date_to=${moment(filterState.date?.value?.toDate ?? null).format('DD/MM/YYYY')}+${moment(
      filterState.date?.value?.toDate ?? null
    ).set('hour', filterState.time?.value?.toTime?.split(":")[0])
    .set('minute', filterState.time?.value?.toTime?.split(":")[1]).format('HH:mm')}`

  let newNoteType = ''
  if(typeof noteType === 'object') {
    Object.keys(noteType).map(type => {
      newNoteType += `&note_type=${noteType[type]}`
    })
  } else {
    newNoteType = `&note_type=${noteType}`
  }

  return apiGet({
    url: `${urls.detailCase.notes}/${caseStatusId}?limit=${tableState.limit}&page=${tableState.page}&sort=${tableState.sort}${filterDateType}${newNoteType}${urlParam}`,
    token: token
  })
}

export const updateDataNote = (token, id, payload) => {
  let dataPayload = {}
  if (payload.text) {
    dataPayload.text = payload.text
  }
  if (payload.todo_date) {
    dataPayload.todo_date = payload.todo_date
  }
  return apiPut({
    url: `${urls.detailCase.notes}/${id}`,
    token: token,
    data: dataPayload
  })
}

export const deleteNote = (token, id) => {
  return apiDelete({
    url: `${urls.detailCase.notes}/${id}`,
    token: token
  })
}

export const adjustNoteOrder = (token, caseId, firstId, secondId) => {
  return apiPut({
    url: `${urls.detailCase.notes}/${caseId}/order`,
    token: token,
    data: {
      first_note_id: firstId,
      second_note_id: secondId
    }
  })
}

export const createNote = (token, caseId, noteType, toDoDate, text, typeId) => {
  const payload = {
    note_type: noteType,
    text: text,
    case_id: parseInt(caseId)
  }

  if (toDoDate) {
    payload.todo_date = toDoDate
  }

  if (noteType === 'CHAT') {
    payload.chat_id = typeId
  }

  if (noteType === 'DOCUMENT') {
    payload.document_id = typeId
  }

  return apiPost({
    url: `${urls.detailCase.notes}`,
    token: token,
    data: payload
  })
}

export const updateDocumentNameDetailCase = (token, id, value) => {
  return apiPut({
    url: `${urls.detailCase.documentList}/${id}/rename`,
    token: token,
    data: {
      filename: value
    }
  })
}

export const retryDocument = (token, id) => {
  return apiPut({
    url: `${urls.detailCase.documentList}/upload/retry/${id}`,
    token: token
  })
}
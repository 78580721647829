import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  access_token: null,
  role: null,
  profile: {}
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setProfile(state, action) {
      state.profile = action.payload.profile
      state.role = action.payload.role
    },
    loginPlatform(state, action) {
      state.access_token = action.payload.access_token
    },
    setAcceptTc(state) {
      state.profile.accepted_term_condition = true
    },
    setEmailAddress(state, action) {
      state.profile.email = action.payload.email
      state.profile.updated_at = action.payload.updated_at
    },
    logoutPlatform(state) {
      Object.assign(state, initialState)
    }
  }
})

export const userActions = userSlice.actions

export const userReducer = userSlice.reducer

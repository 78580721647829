import { AddOutlined } from '@mui/icons-material'
import { FolderNewCase } from 'assets/images'
import CustomButton from 'components/atoms/CustomButton'
import { ROLE_USER } from 'constant'
import React from 'react'

const NullCaseComponent = ({ onAdd = () => {}, role }) => {

  return (
    <div className="flex flex-col w-full h-full justify-center items-center gap-4">
      <img src={FolderNewCase} />
      <span className="font-normal text-2xl">Create your first case!</span>
      {role !== ROLE_USER && <CustomButton onClick={onAdd}>
        <div className="flex gap-1 justify-center items-center">
          New Case <AddOutlined />
        </div>
      </CustomButton>}
    </div>
  )
}

export default NullCaseComponent

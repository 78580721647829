import { AutoFixNormalOutlined, InfoOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { IcSend } from 'assets/icons'
import { CONST_QUESTION, CONST_SHORTCUT } from 'constant'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
const INITIAL_STATE = {
 
  message: ''
}
const shortcutSummary = 'summary'
const shortcutSpecifications = 'specifications'
const shortcutTimeline = 'timeline'
const shortcutRegulation = 'regulation'
const shortcutKeyword = 'keyword'
const shortcutChat = [
  {
    titleButton: 'Summary',
    tooltipTitle: "Brief explanation of the document's main contents",
    value: shortcutSummary
  },
  {
    titleButton: 'Specifications',
    tooltipTitle:
      'Extracting the most relevant data of the document (client, crime, judge, etc...)',
    value: shortcutSpecifications
  },
  {
    titleButton: 'Timeline',
    tooltipTitle: 'Extracting all dates in chronological order',
    value: shortcutTimeline
  },
  {
    titleButton: 'Regulation',
    tooltipTitle: 'Extracting all criminal/civil articles in the document',
    value: shortcutRegulation
  },
  {
    titleButton: 'Keyword',
    tooltipTitle: 'Identificating a set of relevant keywords within the document',
    value: shortcutKeyword
  }
]
const RegulationChatInput = ({ onSubmit = () => {}, isLoading }) => {
  const [showShortcut, setShowShortcut] = useState(false)
  function toggleShortcut() {
    setShowShortcut((prev) => !prev)
  }
  const [keyword, setKeyword] = useState(INITIAL_STATE)
  const isFocus = useRef(false)
  const { t } = useTranslation();
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter' && isFocus.current) {
        event.preventDefault()
        onSubmit(keyword)
        setKeyword(INITIAL_STATE)
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [keyword, onSubmit])

  function handleChangeKeyword(value) {
    setKeyword((prev) => ({ ...prev, message: value }))
  }
  function handleShortcut(value) {
    onSubmit({ type: CONST_SHORTCUT, message: value })
  }
  return (
    <div className="flex gap-1 relative overflow-hidden px-3 flex-col border border-solid border-neutral-300 bg-white rounded-lg mb-10">
      <div className="w-full flex gap-2">
        <input
          autoFocus
          disabled={isLoading}
          onFocus={() => (isFocus.current = true)}
          onBlur={() => (isFocus.current = false)}
          value={keyword.message}
          onChange={(e) => {
            e.preventDefault()
            handleChangeKeyword(e.target.value)
          }}
          className="w-full h-14 rounded-lg border-none text-neutral-700 text-base disabled:bg-white placeholder:pl-3 focus:outline-none"
          placeholder={t("Message chat")}
          type="text"
        />

        <div className="flex justify-center items-center">
          <div
            className="bg-[#0049EE] h-9 w-9 p-2 rounded flex justify-center items-center cursor-pointer hover:bg-[#2e5fd1]"
            onClick={() => {
              if (!isLoading) {
                onSubmit(keyword)
                setKeyword(INITIAL_STATE)
              }
            }}>
            <IcSend className={'h-5'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegulationChatInput

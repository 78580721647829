import { zodResolver } from '@hookform/resolvers/zod'
import { TextField } from '@mui/material'
import { getUserProfile, putUpdateEmailPlatform, putUploadAvatar } from 'api'
import { NullAvatar } from 'assets/images'
import CustomButton from 'components/atoms/CustomButton'
import TextFields from 'components/molecules/TextFields'
import useAuth from 'hooks/useAuth'
import { capitalize } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'
const styleTextField =
  'flex flex-col gap-2 w-full max-w-[340px] border-solid border-0 border-b border-[#E3E3E8] text-lg text-[#828282] font-normal'
const schema = z.object({
  email: z.string().email({ message: 'Invalid email address' })
})

const VisuallyHiddenInput = styled(TextField)({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

const ProfilePlatformContent = () => {
  const { t } = useTranslation()
  const { getAccessToken, platformData, handleSetProfile } = useAuth()
  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control
  } = useForm({
    defaultValues: {
      email: platformData?.profile?.email ? platformData?.profile?.email : ''
    },
    values: {
      email: platformData?.profile?.email ? platformData?.profile?.email : ''
    },
    resolver: zodResolver(schema)
  })
  const { refetch } = useQuery({
    queryKey: 'getProfile',
    queryFn: () => getUserProfile(getAccessToken()),
    onSuccess: (res) => {
      handleSetProfile(res?.resData?.data, res?.resData?.data?.role)
    },
    onError: () => {
      showErrorMessage(t('Could not update your profile, please reload the page'))
    },
    enabled: true
  })

  const putUploadAvatarMutation = useMutation({
    mutationKey: 'putUploadAvatar',
    mutationFn: (file) => putUploadAvatar(getAccessToken(), file),
    onSuccess: () => {
      refetch()
      showSuccessMessage(t('Avatar has been uploaded'))
    },
    onError: () => {
      showErrorMessage(t('Avatar was not possible to upload'))
    }
  })
  const putEditEmailMutation = useMutation({
    mutationKey: 'putEditEmail',
    mutationFn: (email) => putUpdateEmailPlatform(getAccessToken(), email),
    onSuccess: () => {
      refetch()
      showSuccessMessage(t('Email changes saved'))
    },
    onError: () => {
      showErrorMessage(t('Email was not possible to save the changes'))
    }
  })

  function handleUploadAvatar(e) {
    if (e.target.files[0].type.includes('image')) {
      let formData = new FormData()
      formData.append('file', e.target.files[0])
      putUploadAvatarMutation.mutate(formData)
    } else {
      showErrorMessage(t('Only Accept Image File'))
    }
  }

  function handleSubmitEmail(data) {
    putEditEmailMutation.mutate(data?.email)
  }

  return (
    <div className="flex flex-col flex-grow gap-12">
      {/* Title */}
      <h1 className="text-black text-4xl font-medium">{t('Profile-t')}</h1>
      {/* Content */}
      <div className="grid grid-cols-[404px_1fr] h-full w-full gap-8">
        {/* Left */}
        <div className="flex flex-grow flex-col border-solid border-0 border-r border-[#E3E3E8] gap-8">
          {/* title */}
          <h2 className="font-semibold text-lg">{t('Profile picture')}</h2>
          {/* Avatar */}
          <div className="flex flex-col items-center leading-3 gap-4">
            <div className="rounded-full overflow-hidden flex justify-center items-center w-52 h-52 bg-[#F7F7F8] border border-solid border-[#E3E3E8]">
              <img
                src={platformData?.profile?.avatar_url || NullAvatar}
                className={`${
                  platformData?.profile?.avatar_url
                    ? 'object-cover w-full h-full'
                    : 'object-contain'
                } object-center`}
                alt="avatar"
                aria-label="avatar profile"
              />
            </div>
            <span className="text-[#595961] text-sm font-normal text-center ">
              {t('Upload your profile picture in JPG on PNG format (500x500 pixels)')}
            </span>
          </div>
          <div className="flex justify-center items-center pt-2 w-full">
            <CustomButton
              component="label"
              isLoading={putUploadAvatarMutation.isLoading}
              role={undefined}
              tabIndex={-1}>
              {t('Upload avatar')}
              <VisuallyHiddenInput
                type="file"
                onChange={putUploadAvatarMutation.isLoading ? () => {} : handleUploadAvatar}
                inputProps={{ accept: 'image/*' }}
              />
            </CustomButton>
          </div>
        </div>
        {/* Right */}
        <div className="flex flex-col flex-grow gap-8">
          {/* title */}
          <h2 className="font-semibold text-lg">{t('General information')}</h2>

          {/* Content */}
          <div className="flex flex-col flex-grow gap-16">
            {/* Information */}
            <div className="flex gap-4 flex-col">
              <div className={styleTextField}>
                <span className="text-base">{t('Name-t')}</span>
                <span>
                  {platformData?.profile?.name && platformData?.profile?.surname
                    ? `${platformData?.profile?.name} ${platformData?.profile?.surname}`
                    : '-'}
                </span>
              </div>
              <div className={styleTextField}>
                <span className="text-base">{t('Role-t')}</span>
                <span>{capitalize(platformData?.role) || '-'}</span>
              </div>
              <div className={styleTextField}>
                <span className="text-base">{t('Company-t')}</span>
                <span>{platformData?.profile?.company?.name || '-'}</span>
              </div>
            </div>
            {/* Edit Email */}
            <div className="flex flex-col gap-8 max-w-[340px]">
              {/* title */}
              <h2 className="font-semibold text-lg">{t('Email')}</h2>
              <div className="flex flex-col gap-4">
                <TextFields
                  theme="admin"
                  label={'Email'}
                  control={control}
                  autoComplete="off"
                  name={'email'}
                  errors={errors}
                />
                <div>
                  <CustomButton
                    buttonColor={isDirty && isValid ? 'primary' : 'secondary'}
                    onClick={
                      putEditEmailMutation.isLoading ? () => {} : handleSubmit(handleSubmitEmail)
                    }
                    isLoading={putEditEmailMutation.isLoading}
                    disabled={!isDirty || !isValid}>
                    {t('Save Changes-t')}
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePlatformContent

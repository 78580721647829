import { Input } from 'antd'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { isAdminPage } from 'utils/isAdminPage'
import { useTranslation } from 'react-i18next'
const SearchInput = ({ onSearch, variant = 'borderless', ...inputProps }) => {
  const { t } = useTranslation()
  return (
    <Input
      {...inputProps}
      variant={variant}
      placeholder={t('Search')}
      className={isAdminPage() ? ' bg-[#FFFFFF0F] rounded-md' : 'rounded-md'}
      onChange={onSearch}
      allowClear
      suffix={<SearchIcon />}
    />
  )
}

export default SearchInput

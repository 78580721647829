import { getAppInformation, getCountryList, updateAppInformation } from 'api'
import AdminHeader from 'components/molecules/admin/AdminHeader'
import ContactsForm from 'components/organisms/admin/ContactsForm'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'

const ContactsPage = () => {
  const [forms, setForms] = useState({
    email: '',
    phoneNumber: '',
    country: undefined,
    address: '',
    municipality: '',
    zip_code: '',
    province: ''
  })
  const token = useSelector((state) => state.admin.access_token)
  const { t } = useTranslation()

  useQuery({
    queryKey: ['app-information-page'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getAppInformation(token)
      return res
    },
    onSuccess: (res) => {
      let newForms = {
        ...forms,
        email: res?.resData?.data?.filter((item) => item.key === 'email')?.[0]?.value ?? undefined,
        phoneNumber: res?.resData?.data?.filter((item) => item.key === 'phone')?.[0]?.value ?? '',
        country:
          res?.resData?.data?.filter((item) => item.key === 'country')?.[0]?.value ?? undefined,
        address:
          res?.resData?.data?.filter((item) => item.key === 'address')?.[0]?.value ?? undefined,
        municipality:
          res?.resData?.data?.filter((item) => item.key === 'municipality')?.[0]?.value ??
          undefined,
        zip_code:
          res?.resData?.data?.filter((item) => item.key === 'zip_code')?.[0]?.value ?? undefined,
        province:
          res?.resData?.data?.filter((item) => item.key === 'province')?.[0]?.value ?? undefined
      }
      setForms(newForms)
    }
  })

  const countries = useQuery({
    queryKey: ['countries-app-information-list', forms?.email],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getCountryList(token)
      return res
    },
    onSuccess: (res) => {
      let country = forms?.country
      if (country) {
        country = res?.resData?.data?.filter((item) => item?.id === parseInt(country))[0]
        setForms((prevState) => {
          return {
            ...prevState,
            country: country
          }
        })
      }
    }
  })

  const onUpdate = async (data) => {
    try {
      Object.keys(forms).forEach(async (key) => {
        if (key === 'country') {
          if (forms?.[key]?.id !== data[key]) {
            await updateAppInformation(token, key, { value: data[key]?.toString() })
          }
        } else {
          if (data[key] !== forms?.[key]) {
            await updateAppInformation(token, key, { value: data[key] })
          }
        }
      })
      showSuccessMessage(t('Contact Updated'))
    } catch (error) {
      showErrorMessage(t('Update Failed'))
    }
  }

  return (
    <div className="flex flex-col gap-6 ">
      <AdminHeader title={t('Contacts')} hideFunc />
      <ContactsForm
        data={forms}
        onUpdate={onUpdate}
        countries={countries?.data?.resData?.data ?? []}
      />
    </div>
  )
}

export default ContactsPage

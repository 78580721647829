import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DeleteCaseDetailTableItem = ({ show, closeModal, onDelete, isLoading, item }) => {
  const { t } = useTranslation()
  return (
    <ModalUser
      titleComponent={<div className="flex justify-start items-center">{t(`Delete ${item}`)}</div>}
      show={show}
      width={444}
      onClose={!isLoading ? closeModal : () => {}}
      closable={false}>
      <div className="flex justify-between flex-grow flex-col gap-4">
        <span className="text-[#595961]">
          {t(`Are you sure you want to delete the ${item}?`)} <br />
          {item === 'chat'
            ? t('The notes created in association with that chat will lose their referral link')
            : t('It will no longer be available as a data source in all chats and notes already created.')}
        </span>
        {/* wrapper button */}
        <div className="flex flex-grow justify-end items-center gap-2">
          <CustomButton
            isLoading={isLoading}
            buttonColor="secondary"
            onClick={!isLoading ? closeModal : () => {}}>
            {t('Cancel')}
          </CustomButton>
          <CustomButton isLoading={isLoading} onClick={!isLoading ? onDelete : () => {}}>
            {t('Delete')}
          </CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default DeleteCaseDetailTableItem

import React from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import ErrorMessage from 'components/atoms/ErrorMessage'
import moment from 'moment'

const DatePickerUserFields = ({
  control,
  label,
  name,
  errors,
  theme = 'users',
  options = [],
  isRequired = false,
  ...datePickerProps
}) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        required={isRequired}
        render={({ field }) => (
          <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                {...field}
                onChange={(value) => {
                  field.onChange(moment(value).format('MM-DD-YYYY'))
                }}
                value={field.value ? moment(field.value) : null}
                format="DD-MM-YYYY"
                label={
                  theme === 'admin' ? null : (
                    <span className={`${errors[name] ? '!text-red-500' : ''} }`}>{label}</span>
                  )
                }
                slotProps={{ textField: { size: 'small' } }}
                defaultValue={null}
                disableFuture
              />
            </LocalizationProvider>
          </>
        )}
      />
      {errors[name] ? <ErrorMessage errorMessage={errors[name].message} /> : null}
    </div>
  )
}
export default DatePickerUserFields

import ChangePasswordProfileForm from 'components/organisms/admin/ProfileForm/ChangePasswordProfileForm'
import React from 'react'
import { useTranslation } from 'react-i18next'

const SecurityPlatformContent = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col flex-grow gap-12 max-w-lg">
      <h1 className="text-black text-4xl font-medium">{t("Security")}</h1>
      <ChangePasswordProfileForm />
    </div>
  )
}

export default SecurityPlatformContent

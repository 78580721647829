import { PostAdd, Refresh } from '@mui/icons-material'
import _ from 'lodash'
import React from 'react'
import parse from 'html-react-parser'
import { Spin } from 'antd'
import { Tooltip } from '@mui/material'
import { LogoMinimized } from 'assets/images'
const BotResponseItem = ({
  text = '',
  isLastIndex = true,
  onAddNote = () => {},
  onRegenerate = () => {},
  isLoading,
  sources,
  onSourceClick
}) => {
  return (
    <div className="flex gap-2 w-full">
      <div className="flex justify-center items-start w-10">
        <div className="rounded-full overflow-hidden h-6 w-6 bg-[#0049EE]">
          <img src={LogoMinimized} alt="avatarImage" className="object-contain w-full h-full p-1" />
        </div>
      </div>
      <div className="flex w-full h-full flex-col items-start justify-start">
        <span className="text-base font-bold">Legal AI</span>
        <div className="w-full h-full">
          {isLoading && isLastIndex ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spin />
            </div>
          ) : (
            <>
              {parse(text)}{' '}
              {sources?.map((source, index) => (
                <Tooltip key={`reference-${index}`} title={source?.filename}>
                  <span
                    className="text-[#0049EE] cursor-pointer"
                    onClick={() => onSourceClick(source)}>
                    [{source?.filename?.slice(0, 2)?.toUpperCase() ?? ''} - Page {source?.page}]
                  </span>
                </Tooltip>
              ))}
            </>
          )}
        </div>
        {!isLoading ? (
          <div className="flex gap-2 items-center pt-2 text-[#828282] ">
            <div onClick={onAddNote}>
              <PostAdd className="cursor-pointer hover:text-[#5c5b5b]" />
            </div>
            {isLastIndex && (
              <div
                onClick={() => {
                  _.throttle(() => {
                    return onRegenerate()
                  }, 1000)()
                }}>
                <Refresh className="cursor-pointer hover:text-[#5c5b5b]" />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default BotResponseItem

import React from 'react'
import { Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const CompanyDetailInfo = ({
  email,
  phone,
  address,
  province,
  vat_number,
  tax_code,
  sdi_receiver_code,
  pec_address
}) => {
  const { t } = useTranslation()
  return (
    <Row className="mb-4 pt-6">
      <Col span={6} className="pl-4 pr-2">
        <Typography.Title className="text-h2 font-manrope !text-white">
          {t('Company Info')}
        </Typography.Title>
      </Col>
      <Col span={6} className="px-2">
        <div className="mb-8">
          <div>
            <Typography.Text className="font-manrope font-semibold text-[#7A7A7A] text-md">
              {t('EMAIL')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="font-manrope font-medium text-[#FFF] text-lg">
              {email}
            </Typography.Text>
          </div>
        </div>
        <div className="mb-8">
          <div>
            <Typography.Text className="font-manrope font-semibold text-[#7A7A7A] text-md">
              {t('VAT NUMBER')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="font-manrope font-medium text-[#FFF] text-lg">
              {vat_number}
            </Typography.Text>
          </div>
        </div>
        <div className="mb-8">
          <div>
            <Typography.Text className="font-manrope font-semibold text-[#7A7A7A] text-md">
              {t('SDI CODE')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="font-manrope font-medium text-[#FFF] text-lg">
              {sdi_receiver_code}
            </Typography.Text>
          </div>
        </div>
      </Col>
      <Col span={6} className="px-2">
        <div className="mb-8">
          <div>
            <Typography.Text className="font-manrope font-semibold text-[#7A7A7A] text-md">
              {t('TELEPHONE NUMBER')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="font-manrope font-medium text-[#FFF] text-lg">
              {phone}
            </Typography.Text>
          </div>
        </div>
        <div className="mb-8">
          <div>
            <Typography.Text className="font-manrope font-semibold text-[#7A7A7A] text-md">
              {t('TAX CODE')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="font-manrope font-medium text-[#FFF] text-lg">
              {tax_code}
            </Typography.Text>
          </div>
        </div>
        <div className="mb-8">
          <div>
            <Typography.Text className="font-manrope font-semibold text-[#7A7A7A] text-md">
              {t('PEC ADDRESS')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="font-manrope font-medium text-[#FFF] text-lg">
              {pec_address}
            </Typography.Text>
          </div>
        </div>
      </Col>
      <Col span={6} className="pr-4 pl-2">
        <div className="mb-8">
          <div>
            <Typography.Text className="font-manrope font-semibold text-[#7A7A7A] text-md">
              {t('LOCATION')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="font-manrope font-medium text-[#FFF] text-lg">
              {`${address}${province ? ',' : ''} ${province}`}
            </Typography.Text>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default CompanyDetailInfo

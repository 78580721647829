import { apiGet } from 'api/api-get'
import { apiPost } from 'api/api-post'
import { urls } from 'api/api-url'
import moment from 'moment'

export const getDocumentListNewChat = (token, caseId, filter, tableState) => {
  let urlParams = `limit=0&sort=${tableState.sort}&page=0`

  if (tableState.search) urlParams += `&search=${tableState.search}`
  if (caseId) urlParams += `&case_id=${caseId}`

  //   DATE
  if (filter?.date?.value?.fromDate && !filter?.date?.value?.toDate)
    urlParams += `&filter_data_type=greater_than&first_date=${moment(
      filter?.date?.value?.fromDate
    ).format('DD/MM/YYYY')}`
  else if (!filter?.date?.value?.fromDate && filter?.date?.value?.toDate)
    urlParams += `&filter_data_type=less_than&second_date=${moment(
      filter?.date?.value?.toDate
    ).format('DD/MM/YYYY')}`
  else if (filter?.date?.value?.fromDate && filter?.date?.value?.toDate)
    urlParams += `&filter_data_type=between&first_date=${moment(
      filter?.date?.value?.fromDate
    ).format('DD/MM/YYYY')}&second_date=${moment(filter?.date?.value?.toDate).format('DD/MM/YYYY')}`
  // EGIBILITITY
  if (filter?.eligibility?.value?.eligible)
    urlParams += `&eligible=${Boolean(filter?.eligibility?.value?.eligible)}`
  if (filter?.eligibility?.value?.ineligible)
    urlParams += `&ineligible=${Boolean(filter?.eligibility?.value?.ineligible)}`
  return apiGet({
    url: `${urls.documents.documents}?${urlParams}`,
    token: token
  })
}

export const getOtherCases = (token, tableState, data) => {
  let urlParams = `limit=0&sort=${tableState.sort}&page=0`

  if (tableState.search) urlParams += `&search=${tableState.search}`
  if (data?.status) {
    data.status.forEach((item) => {
      urlParams += '&case_status_id=' + item
    })
  }

  if (data?.regulations) {
    data.regulations.forEach((item) => {
      urlParams += '&regulation_id=' + item
    })
  }

  return apiGet({
    url: `${urls.detailCase.cases}?${urlParams}`,
    token: token
  })
}

export const postNewChats = (token, caseId, selectedDocuments) => {
  return apiPost({
    token: token,
    url: urls.chats.chats,
    data: {
      case_id: caseId,
      document_ids: selectedDocuments
    }
  })
}

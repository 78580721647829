import { useRef, useState } from 'react'
import { useMutation } from 'react-query'
import useAuth from './useAuth'
import { exportDocumentAsDocx } from 'api'
import Axios from 'axios'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
const useExportAsDocx = () => {
  const docRef = useRef(null)
  const { getAccessToken } = useAuth()
  const [isExporting, setIsExporting] = useState(false)
  const exportDocumentAsDocxMutation = useMutation({
    mutationKey: ['exportDocument'],
    mutationFn: (docId) => exportDocumentAsDocx(getAccessToken(), docId),
    onSuccess: (res) => {
      try {
        Axios.get(res?.resData?.data?.filepath, {
          responseType: 'blob'
        }).then((res) => {
          const downloadURL = window.URL.createObjectURL(new Blob([res?.data]))
          const link = document.createElement('a')
          link.href = downloadURL
          link.download = `${docRef.current.filename.split('.')[0]}.docx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(downloadURL)
        })
        showSuccessMessage('Success export document to docx')
      } catch (error) {
        showErrorMessage('Failed export document to docx, please try again')
      }
      setIsExporting(() => false)
    },
    onError: () => {
      showErrorMessage('Failed export document to docx, please try again')
      setIsExporting(() => false)
    }
  })
  function handleExportDocument(doc) {
    docRef.current = doc
    exportDocumentAsDocxMutation.mutate(doc?.id)
    setIsExporting(() => true)
  }
  return { handleExportDocument, isExporting, docRef }
}

export default useExportAsDocx

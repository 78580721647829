import { apiGet } from 'api/api-get'
import { apiPost } from 'api/api-post'
import { urls } from 'api/api-url'

export const getDetailDocument = (token, documentId) => {
  return apiGet({ token: token, url: `${urls.documents.documents}/${documentId}` })
}

export const confirmDocument = (token, data) => {
  return apiPost({
    token: token,
    url: `${urls.documents.documents}/${urls.documents.uploadConfirm}`,
    data: data
  })
}

export const exportDocumentAsDocx = (token, docId) => {
  return apiGet({
    token: token,
    url: `${urls.documents.documents}/${docId}/${urls.documents.export}`
  })
}

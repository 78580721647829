import { apiGet } from 'api/api-get'
import { apiPut } from 'api/api-put'
import { apiPost } from 'api/api-post'
import { apiDelete } from 'api/api-delete'
import { urls } from 'api/api-url'

export const getRegulations = (token, tableState) => {
  let urlParam = ''
  if (tableState.search) urlParam += `&search=${tableState.search}`

  return apiGet({
    url: `${urls.regulations.regulations}?limit=${tableState.limit}&page=${tableState.page}&sort=${tableState.sort}${urlParam}`,
    token: token
  })
}

export const postRegulationsChat = (token, regulationId, chat) => {
  return apiPost({
    url: `${urls.regulations.chats}/regulation-message`,
    token: token,
    data: {
      regulation_id: regulationId,
      question: chat
    }
  })
}

export const getRegulationChat = (token, chatId) => {
  return apiGet({ token: token, url: `${urls.regulations.chats}/regulation/${chatId}` })
}

export const getRegulationExportFile = (token, chatId, tableState) => {
  return apiGet({ token: token, url: `${urls.regulations.regulation}/documents/${chatId}/export?sort=${tableState.sort}` })
}

export const deleteChatRegulation = (token, chatId) => {
  return apiDelete({ token: token, url: `${urls.regulations.chats}/regulation/${chatId}` })
}

export const postRegulationReferences = (token, sources) => {
  return apiPost({ 
    token: token, 
    url: `${urls.regulations.regulation}/documents/source`,
    data: {
      sources: sources
    } 
  })
}

export const postRegenerateRegulationChat = (token, chatId) => {
  return apiPost({
    token: token,
    url: `${urls.chats.chats}/${urls.chats.regenerateMessage}`,
    data: {
      chat_id: chatId,
      type: 'regulation'
    }
  })
}

import { Spin } from 'antd'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'

const ReferenceRegulationModal = ({ show, closeModal, isLoading, data }) => {
  return (
    <ModalUser
      titleComponent={<div className="flex justify-start items-center pb-5"></div>}
      show={show}
      width={900}
      onClose={closeModal}
      closable={false}>
      <div className="flex justify-between flex-grow flex-col gap-4 max-h-[528px] h-full overflow-hidden overflow-y-auto">
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Spin />
          </div>
        ) : (
          <div className="flex flex-col gap-8">
            {data?.map((file, idx) => (
              <div className="flex flex-col gap-4" key={`reference-${idx}`}>
                <span className="font-medium text-xl">
                  {file?.filename} (page:{file?.page})
                </span>
                <p>{file?.content}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </ModalUser>
  )
}

export default ReferenceRegulationModal

import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { passwordValidation } from 'utils/passwordValidation'
import { Typography } from 'antd'
import PasswordFields from 'components/molecules/PasswordFields'
import CustomButton from 'components/atoms/CustomButton'
import { useMutation } from 'react-query'
import { putChangePassword } from 'api'
import useAuth from 'hooks/useAuth'
import { errorObj } from 'utils/handleError'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { isAdminPage } from 'utils/isAdminPage'
import { useTranslation } from 'react-i18next'


const ChangePasswordProfileForm = () => {
  const { getAccessToken } = useAuth()
  const { t } = useTranslation();
  const schema = z
  .object({
    old_password: z.string().min(1, { message: t('Please input your current password') }),
    new_password: z
      .string()
      .min(10, {
        message: t('Password does not meet the safety guidelines')
      })
      .regex(passwordValidation(), t('Password does not meet the safety guidelines')),
    password_confirmation: z.string().min(1, { message: t('Password is required') })
  })
  .refine((data) => data.new_password === data.password_confirmation, {
    message: t('Passwords do not match'),
    path: ['password_confirmation']
  })
  const {
    handleSubmit,
    reset,
    clearErrors,
    watch,
    formState: { errors, isDirty, isValid },
    control
  } = useForm({
    defaultValues: {
      old_password: '',
      new_password: '',
      password_confirmation: ''
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema)
  })
  React.useEffect(() => {
    const subscription = watch(async (value) => {
      if (value?.password_confirmation === value?.new_password) {
        clearErrors('password_confirmation')
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const putChangePasswordMutation = useMutation({
    mutationKey: 'putChangePassword',
    mutationFn: (data) => putChangePassword(getAccessToken(), data),
    onSuccess: () => {
      showSuccessMessage(t('Password changes saved'))
      reset()
    },
    onError: (err) => {
      const errObj = errorObj(err)
      showErrorMessage(t('Password was not possible to save the changes'))
    }
  })

  const onSubmit = (data) => {
    putChangePasswordMutation.mutate(data)
  }

  return (
    <div className="flex flex-col gap-6 max-w-80">
      <div className="flex gap-1 flex-col">
        <Typography.Title
          className={`${
            isAdminPage()
              ? '!text-white !text-lg !font-manrope'
              : '!text-black !text-base !font-semibold'
          } `}>
          {t('Edit Password')}
        </Typography.Title>

        <span className={`${isAdminPage() ? 'text-[#999999]' : 'text-[#595961]'} text-sm`}>
          {t(
            'The new password must contain at least 10 characters, 1 number, 1 capital letter and a special character'
          )}
        </span>
      </div>

      <div className="flex flex-col gap-4">
        <PasswordFields
          autoComplete="new-password"
          theme="admin"
          control={control}
          label={t('Current Password')}
          name={'old_password'}
          errors={errors}
        />
        <PasswordFields
          theme="admin"
          autoComplete="off"
          control={control}
          label={t('Password')}
          // trigger={trigger}
          name={'new_password'}
          errors={errors}
        />
        <PasswordFields
          theme="admin"
          autoComplete="off"
          control={control}
          label={t('Repeat Password')}
          name={'password_confirmation'}
          errors={errors}
        />
        <CustomButton
          className={'!max-w-44'}
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
          buttonColor={isAdminPage() ? 'primary' : isValid ? 'primary' : 'secondary'}
          fullWidth>
          {t('Save changes')}
        </CustomButton>
      </div>
    </div>
  )
}

export default ChangePasswordProfileForm

import { toast } from 'react-toastify'

export const showSuccessMessage = (content, duration) => {
  toast.success(`${content ?? 'Successfully'}`, {
    hideProgressBar: true,
    autoClose: duration ?? 2000
  })
}

export const showErrorMessage = (content, duration) => {
  toast.error(content, {
    hideProgressBar: true,
    autoClose: duration ?? 2000
  })
}

export const showWarningMessage = (content, duration) => {
  toast.warning(`${content ?? 'Be carefully!'}`, {
    hideProgressBar: true,
    autoClose: duration ?? 2000
  })
}

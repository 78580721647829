import { Typography } from 'antd'
import { apiPut } from 'api'
import CustomButton from 'components/atoms/CustomButton'
import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { showErrorMessage } from 'utils/showMessage'

const RetrieveProfileCompanyModal = ({ show, onClose, onRetrieveProfile, data, refetch }) => {
  const { t } = useTranslation()

  const token = useSelector((state) => state.admin.access_token)

  const onRetrieveCompany = async () => {
    try {
      let res
      res = await apiPut({ url: `companies/${data?.id}/retrieve`, token })

      if (res?.resStatus === 200 || res?.resStatus === 201) {
        refetch()
        onRetrieveProfile()
        onClose()
      } else {
        showErrorMessage(t('Retrieve Failed!'))
      }
    } catch (error) {
      showErrorMessage(t('Retrieve Failed!'))
    }
  }

  return (
    <ModalAdmin title={'Retrieve Profile'} show={show} onClose={onClose}>
      <div className="flex flex-col gap-2">
        <Typography.Paragraph className="!text-[#FFFFFFCC] !font-manrope !text-lg">
          {t(
            'Are you sure you want to retrieve this Company&apos;s profile? Your request for deletion will be cancelled'
          )}
        </Typography.Paragraph>

        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" onClick={onClose}>
            {t('Cancel')}
          </CustomButton>
          <CustomButton onClick={onRetrieveCompany}>{t('Retrieve')}</CustomButton>
        </div>
      </div>
    </ModalAdmin>
  )
}

export default RetrieveProfileCompanyModal

import { Checkbox, FormControlLabel } from '@mui/material'
import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import { REGULATION, STATUS } from 'constant'
import React, { useState } from 'react'

const OtherCasesFilterModal = ({
  show,
  closeModal,
  regulations = [],
  casesStatus = [],
  data,
  resetFilter,
  onSubmit
}) => {
  const [filter, setFilter] = useState({ ...data })

  function handleChange(category, value, e, isChecked) {
    let newValues = null
    if (!isChecked) {
      newValues = filter[category.toLowerCase()].value
      delete newValues[e.target.name]
      if (Object.entries(newValues).length === 0) newValues = null
    } else {
      newValues = { ...filter[category.toLowerCase()]?.value, [e.target.name]: value }
    }

    return setFilter((prev) => ({
      ...prev,
      [category.toLowerCase()]: {
        value: newValues
      }
    }))
  }

  return (
    <ModalUser modalClassName={''} onClose={closeModal} title={'Filters'} show={show}>
      <div className="h-full md:h-[528px] pt-3 flex flex-col justify-between">
        <div className="flex flex-col gap-8">
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">Regulation</span>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-x-8">
                {regulations?.map((regulation) => (
                  <FormControlLabel
                    key={`workspace-filter-regulation-${regulation.id}`}
                    control={
                      <Checkbox
                        onChange={handleChange.bind(this, REGULATION, regulation?.id)}
                        name={regulation?.title}
                        checked={
                          filter?.regulation?.value?.[regulation?.title] !== undefined || false
                        }
                      />
                    }
                    label={regulation?.title}
                  />
                ))}
              </div>
              {/* <FormHelperText>Be careful</FormHelperText> */}
            </div>
          </div>
          {/* Status */}
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">Status</span>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-x-8">
                {casesStatus.map((status, key) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        onChange={handleChange.bind(this, STATUS, status?.id)}
                        name={status.name}
                        checked={filter?.status?.value?.[status.name] !== undefined || false}
                      />
                    }
                    label={
                      <div className="flex justify-center items-center gap-2">
                        <div
                          className="w-2 h-2 rounded-full "
                          style={{ backgroundColor: '#' + status.hex_code }}></div>
                        <span className="text-black">{status.name}</span>
                      </div>
                    }
                  />
                ))}
              </div>
              {/* <FormHelperText>Be careful</FormHelperText> */}
            </div>
          </div>
        </div>
        {/* Button Wrapper */}
        <div className="flex justify-end items-center gap-4">
          <CustomButton buttonColor="secondary" onClick={resetFilter}>
            Remove Filter
          </CustomButton>
          <CustomButton
            onClick={() => {
              onSubmit(filter)
            }}>
            Apply
          </CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default OtherCasesFilterModal

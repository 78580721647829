import { Checkbox, FormControlLabel } from '@mui/material'
import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import React, { useState } from 'react'
const ELIGIBILITY = 'ELIGIBILITY'
const STATUS = 'STATUS'
const UploadDocsNewChatFilterModal = ({ show, closeModal, data, resetFilter, onSubmit }) => {
  const [filter, setFilter] = useState({ ...data })

  function handleChange(category, e, isChecked) {
    let newValues = null
    if (!isChecked) {
      newValues = filter[category.toLowerCase()].value
      delete newValues[e.target.name]
      if (Object.entries(newValues).length === 0) newValues = null
    } else {
      newValues = { ...filter[category.toLowerCase()]?.value, [e.target.name]: isChecked }
    }

    return setFilter((prev) => ({
      ...prev,
      [category.toLowerCase()]: {
        value: newValues
      }
    }))
  }
  return (
    <ModalUser modalClassName={''} onClose={closeModal} title={'Filters'} show={show}>
      <div className="h-full md:h-[400px] pt-3 flex flex-col justify-between">
        <div className="flex flex-col gap-8">
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">Eligiblity</span>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-x-8">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange.bind(this, ELIGIBILITY)}
                      name={'eligible'}
                      checked={filter?.eligibility?.value?.['eligible'] || false}
                    />
                  }
                  label={<span className="text-black">Eligible</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange.bind(this, ELIGIBILITY)}
                      name={'ineligible'}
                      checked={filter?.eligibility?.value?.['ineligible'] || false}
                    />
                  }
                  label={<span className="text-black">Ineligible</span>}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-1 !text-black">
            <span className="text-black font-semibold text-base ">Status</span>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-x-8">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange.bind(this, STATUS)}
                      name={'uploaded'}
                      checked={filter?.status?.value?.['uploaded'] || false}
                    />
                  }
                  label={<span className="text-black">Uploaded</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange.bind(this, STATUS)}
                      name={'unloaded'}
                      checked={filter?.status?.value?.['unloaded'] || false}
                    />
                  }
                  label={<span className="text-black">Unloaded</span>}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Button Wrapper */}
        <div className="flex justify-end items-center gap-4">
          <CustomButton buttonColor="secondary" onClick={resetFilter}>
            Remove Filter
          </CustomButton>
          <CustomButton
            onClick={() => {
              onSubmit(filter)
            }}>
            Apply
          </CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default UploadDocsNewChatFilterModal

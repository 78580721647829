import React, { useEffect, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import CloseIcon from '@mui/icons-material/Close'
import SearchInput from 'components/atoms/SearchInput'
import { MoreVertOutlined, FilterListOutlined } from '@mui/icons-material'
import { Tabs, Tab, Breadcrumbs, IconButton } from '@mui/material'
import { Typography } from 'antd'
import ListExpandNote from 'components/molecules/user/cases/ListExpandNote'
import ExportNotesModal from 'components/organisms/users/ExportNotesModal'
import { Popover } from 'antd'
import useAuth from 'hooks/useAuth'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { getExportNotes } from 'api'
import { useMutation } from 'react-query'
import NotesInsightsFilter from './NotesInsightsFilter'
import { INITIAL_NOTES_INSIGHT_FILTER, INITIAL_NOTES_TODO_FILTER } from 'constant'
import NotesTodoFilter from './NotesTodoFilter'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogExpandNote = ({
  openExpandNote,
  handleCloseExpandNote,
  caseName,
  setTabNote,
  tabNote,
  height,
  caseId,
  dataInsight,
  dataTodo,
  setNoteDataInsight,
  setNoteDataTodo,
  filterNoteTodo = {},
  filterNoteInsight = {},
  setFilterNoteTodo = () => {},
  setFilterNoteInsight = () => {},
  handleSearch
}) => {
  const { t } = useTranslation()
  const [heightInput, setHeightInput] = useState(0)
  const [isEditAll, setIsEditAll] = useState(false)
  const [search, setSearch] = useState('')
  const [openExportModal, setOpenExportModal] = useState(false)
  const { getAccessToken } = useAuth()
  const handleEditALl = () => {
    setHeightInput(200)
    setIsEditAll(true)
    setNoteDataInsight((prev) => {
      return prev.map((note) => ({
        ...note,
        edit: true
      }))
    })
  }

  const navigate = useNavigate()

  useEffect(() => {
    debouncedResults({ value: '', tabNote: tabNote })
    return () => {
      debouncedResults.cancel()
    }
  }, [tabNote])

  const onSearch = (e) => {
    setSearch(e.target.value)
    let obj = { tabNote: tabNote, value: e.target.value }
    debouncedResults(obj)
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300)
  }, [])

  function handleSubmitInsightFilter(data) {
    setFilterNoteInsight((prev) => ({ ...prev, data: data, show: false }))
  }

  function handleSubmitTodoFilter(data) {
    setFilterNoteTodo((prev) => ({ ...prev, data: data, show: false }))
  }

  const handleCloseExportModal = () => {
    setOpenExportModal(false)
  }

  const handleOpenExportModal = () => {
    setOpenExportModal(true)
  }

  const clearInput = () => {
    setSearch('')
  }

  const optionsPopover = [
    // {
    //   title: 'Edit all',
    //   callback: handleEditALl,
    //   role: ['admin', 'editor']
    // },
    {
      title: 'Export notes',
      callback: handleOpenExportModal,
      role: ['admin']
    }
  ]

  const handleClickMenu = (callback, title) => {
    return callback(title)
  }

  const exportNotesMutation = useMutation({
    mutationKey: ['getExportNotesExpand'],
    mutationFn: (id) => getExportNotes(getAccessToken(), id),
    onSuccess: (res) => {
      const downloadURL = window.URL.createObjectURL(new Blob([res?.data]))
      const link = document.createElement('a')
      link.href = downloadURL
      link.download = `${caseName}_Notes.docx`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(downloadURL)
      showSuccessMessage('Notes exported')
      handleCloseExportModal()
    },
    onError: () => {
      showErrorMessage('Notes could not be exported')
    }
  })

  const handleExportNotes = () => {
    exportNotesMutation.mutate(caseId)
  }

  return (
    <Dialog
      fullScreen
      open={openExpandNote}
      onClose={handleCloseExpandNote}
      TransitionComponent={Transition}>
      <div className="p-5 bg-gray-100" style={{ overflow: 'hidden' }}>
        <div className="flex justify-between items-center">
          <div>
            <Breadcrumbs>
              <Typography.Text
                className="text-black font-normal text-[14px] cursor-pointer"
                onClick={() => navigate('/cases')}>
                {t('Cases')}
              </Typography.Text>
              <Typography.Text className="text-[#828282] hover:text-black font-normal text-[14px] cursor-pointer">
                {caseName}
              </Typography.Text>
            </Breadcrumbs>
          </div>
          <IconButton edge="start" color="black" onClick={handleCloseExpandNote} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <div className="flex justify-between items-center mt-2">
          <div className="flex">
            <Typography.Text className="text-black font-semibold text-[30px] mr-3">
              {t('Notes')}
            </Typography.Text>
            <Tabs
              textColor="inherit"
              sx={{
                minHeight: 0,
                height: '42px'
              }}
              TabIndicatorProps={{
                style: {
                  borderRadius: 20,
                  height: 5,
                  margin: 0,
                  marginTop: 0,
                  backgroundColor: '#0049EE'
                }
              }}
              value={tabNote}
              onChange={(_, tabName) => {
                setTabNote(() => {
                  setFilterNoteInsight(INITIAL_NOTES_INSIGHT_FILTER)
                  setFilterNoteTodo(INITIAL_NOTES_TODO_FILTER)
                  clearInput()
                  debouncedResults({ value: '', tabNote: tabName })
                  return tabName
                })
              }}>
              <Tab
                disableRipple
                fullWidth={false}
                sx={{
                  alignItems: 'start',
                  minWidth: 0,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  textTransform: 'none',
                  padding: 0,
                  marginRight: 2
                }}
                label={t('Insights')}
                value={0}
              />
              <Tab
                disableRipple
                fullWidth={false}
                sx={{
                  alignItems: 'start',
                  minWidth: 0,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  textTransform: 'none',
                  padding: 0
                }}
                label={t('To do list')}
                value={1}
              />
            </Tabs>
          </div>

          <div className="flex items-center">
            <SearchInput variant="outlined" value={search} onSearch={onSearch} />
            {tabNote === 0 && (
              <div
                className="flex gap-1 justify-center items-center cursor-pointer mx-4"
                onClick={() =>
                  setFilterNoteInsight((prev) => {
                    return { ...prev, show: true }
                  })
                }>
                <FilterListOutlined
                  className={`cursor-pointer hover:text-[#0049EE] ${
                    Object?.entries(filterNoteInsight?.data)?.filter(
                      ([, { value }]) => value !== null
                    )?.length > 0 && 'text-[#0049EE]'
                  }`}
                />
                {/* Badge Total Count */}
                {Object?.entries(filterNoteInsight?.data)?.filter(([, { value }]) => value !== null)
                  ?.length > 0 && (
                  <div className="w-5 h-5 bg-[#0049EE] px-[6px] flex justify-center items-center rounded-full text-white">
                    {
                      Object?.entries(filterNoteInsight?.data)?.filter(
                        ([, { value }]) => value !== null
                      )?.length
                    }
                  </div>
                )}
              </div>
            )}
            {tabNote === 1 && (
              <div
                className="flex gap-1 justify-center items-center cursor-pointer mx-4"
                onClick={() =>
                  setFilterNoteTodo((prev) => {
                    return { ...prev, show: true }
                  })
                }>
                <FilterListOutlined
                  className={`cursor-pointer hover:text-[#0049EE] ${
                    Object?.entries(filterNoteTodo?.data)?.filter(([, { value }]) => value !== null)
                      ?.length > 0 && 'text-[#0049EE]'
                  }`}
                />
                {/* Badge Total Count */}
                {Object?.entries(filterNoteTodo?.data)?.filter(([, { value }]) => value !== null)
                  ?.length > 0 && (
                  <div className="w-5 h-5 bg-[#0049EE] px-[6px] flex justify-center items-center rounded-full text-white">
                    {
                      Object?.entries(filterNoteTodo?.data)?.filter(
                        ([, { value }]) => value !== null
                      )?.length
                    }
                  </div>
                )}
              </div>
            )}
            <Popover
              content={
                <div className="w-[158px] h-full flex flex-col text-black">
                  {optionsPopover.map(
                    (option, index) =>
                      option.role.includes('admin') && (
                        <div
                          key={index}
                          onClick={() => {
                            handleClickMenu(option?.callback, option?.title)
                          }}
                          className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                          {t(option.title)}
                        </div>
                      )
                  )}
                </div>
              }
              trigger="click"
              placement="leftTop"
              arrow={false}
              overlayClassName="z-[10000]">
              <MoreVertOutlined fontSize="small" className="cursor-pointer hover:text-[#0049EE]" />
            </Popover>
          </div>
        </div>

        <ListExpandNote
          height={height}
          value={tabNote}
          dataInsight={dataInsight}
          dataTodo={dataTodo}
          setNoteDataInsight={setNoteDataInsight}
          setNoteDataTodo={setNoteDataTodo}
          caseId={caseId}
          heightInput={heightInput}
          setHeightInput={setHeightInput}
          IsEditAll={isEditAll}
          setIsEditAll={setIsEditAll}
        />
      </div>
      {openExportModal && (
        <ExportNotesModal
          show={openExportModal}
          closeModal={handleCloseExportModal}
          onExport={handleExportNotes}
          isLoading={exportNotesMutation.isLoading}
        />
      )}
      {filterNoteInsight.show && (
        <NotesInsightsFilter
          onClose={() =>
            setFilterNoteInsight((prev) => {
              return { ...prev, show: false }
            })
          }
          data={filterNoteInsight.data}
          show={filterNoteInsight.show}
          onSubmit={handleSubmitInsightFilter}
          onClear={() => setFilterNoteInsight(INITIAL_NOTES_INSIGHT_FILTER)}
        />
      )}
      {filterNoteTodo.show && (
        <NotesTodoFilter
          onClose={() =>
            setFilterNoteTodo((prev) => {
              return { ...prev, show: false }
            })
          }
          data={filterNoteTodo.data}
          show={filterNoteTodo.show}
          onSubmit={handleSubmitTodoFilter}
          onClear={() => setFilterNoteTodo(INITIAL_NOTES_TODO_FILTER)}
        />
      )}
    </Dialog>
  )
}

export default DialogExpandNote

import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DeleteCaseModal = ({ show, closeModal, onDelete, isLoading }) => {
  const { t } = useTranslation()
  return (
    <ModalUser
      titleComponent={<div className="flex justify-start items-center">{t('Delete case')}</div>}
      show={show}
      width={444}
      onClose={!isLoading ? closeModal : () => {}}
      closable={false}>
      <div className="flex justify-between flex-grow flex-col gap-4">
        <span className="text-[#595961]">
          {t('Are you sure you want to delete the file?')} <br/>
          {t('All uploaded documents, chats and notes created will be lost.')}
        </span>
        {/* wrapper button */}
        <div className="flex flex-grow justify-end items-center gap-2">
          <CustomButton
            isLoading={isLoading}
            buttonColor="secondary"
            onClick={!isLoading ? closeModal : () => {}}>
            {t('Cancel')}
          </CustomButton>
          <CustomButton isLoading={isLoading} onClick={!isLoading ? onDelete : () => {}}>
            {t('Delete')}
          </CustomButton>
        </div>
      </div>
    </ModalUser>
  )
}

export default DeleteCaseModal

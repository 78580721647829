import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selected: 'eng'
}

const langSlice = createSlice({
  name: 'lang',
  initialState: initialState,
  reducers: {
    setLanguage(state, action) {
      state.selected = action.payload
    },
  }
})

export const langActions = langSlice.actions

export const langReducer = langSlice.reducer

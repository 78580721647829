import CustomButton from 'components/atoms/CustomButton'
import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DeleteProfileUserModal = ({ show, onClose, onDeleteProfile, isLoading }) => {
  const { t } = useTranslation()
  return (
    <ModalAdmin show={show} onClose={isLoading ? () => {} : onClose} title={'Delete Profile'}>
      <div className="flex flex-col gap-8">
        <span className="font-manrope text-base text-[#999999]">
          {t(
            'Are you sure you want to delete this user&apos;s profile? You will have up to 30 days to recover his account, after which will lose access to the app.'
          )}
        </span>

        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" disabled={isLoading} onClick={onClose}>
            {t('Cancel')}
          </CustomButton>
          <CustomButton
            isLoading={isLoading}
            buttonType="danger"
            onClick={() => {
              onDeleteProfile()
            }}>
            {t('Delete')}
          </CustomButton>
        </div>
      </div>
    </ModalAdmin>
  )
}

export default DeleteProfileUserModal

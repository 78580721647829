import React, { useState, useRef, useEffect } from 'react'
import { Typography } from 'antd'
import ListRegulationQuery from 'components/molecules/user/regulation/ListRegulationQuery'
import { useTranslation } from 'react-i18next'
const RegulationQueryContainer = ({
  height,
  data,
  setData,
  setActiveData,
  onDelete = () => {},
  onExport = () => {}
}) => {
  const noteHeadRef = useRef()
  const { t } = useTranslation();
  const [listNotesHeight, setListNotesHeight] = useState(height ?? 0)

  useEffect(() => {
    if (noteHeadRef?.current) {
      setListNotesHeight(height - noteHeadRef?.current?.clientHeight)
    }
  }, [noteHeadRef?.current !== null, height])
  return (
    <div className="col-span-3">
      <div className="flex items-center mt-3" ref={noteHeadRef}>
        <Typography.Text className="text-[24px] flex items-center">
          {t("Regulations")}
          {/* <Add className="ml-3" /> */}
        </Typography.Text>
      </div>

      <ListRegulationQuery
        height={height}
        data={data}
        setData={setData}
        setActiveData={setActiveData}
        onDelete={onDelete}
        onExport={onExport}
      />
    </div>
  )
}

export default RegulationQueryContainer

import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'
import parse from 'html-react-parser'
import moment from 'moment'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'

const styles = {
  title: 'text-black font-normal text-2xl',
  sub_title: 'text-base font-semibold',
  paragraph: 'list-disc list-inside'
}

const TCModal = ({ show, onClose, onSubmit, onLogout, isLoading, pp, tc, isFetching }) => {
  const { t } = useTranslation();
  return (
    <ModalUser title={t('Privacy Policy & Terms and Conditions')} show={show} onClose={onClose}>
      <div className="h-96 overflow-y-auto flex flex-col gap-4">
        {isFetching ? (
          <div className="w-full h-full flex justify-center items-center">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {pp?.privacy_policies?.length > 0
              ? pp?.privacy_policies?.map(({ key, value }, index) => {
                  switch (key) {
                    case 'title':
                      return (
                        <span key={`title-pp-${index}`} className={styles[key]}>
                          {value} ({t("Last updated")}: {moment(pp?.updated_at).format('DD/MM/YYYY')})
                        </span>
                      )
                    case 'sub_title':
                      return (
                        <span key={`sub-title-pp-${index}`} className={styles[key]}>
                          {value}
                        </span>
                      )
                    case 'paragraph':
                      return <div key={`paragraph-pp-${index}`}>{parse(value)}</div>
                  }
                })
              : null}
            {tc?.data?.length > 0
              ? tc?.data?.map(({ key, value }, index) => {
                  switch (key) {
                    case 'title':
                      return (
                        <span key={`title-tc-${index}`} className={styles[key]}>
                          {value} ({t("Last updated")}: {moment(tc?.updated_at).format('DD/MM/YYYY')})
                        </span>
                      )
                    case 'sub_title':
                      return (
                        <span key={`sub-title-tc-${index}`} className={styles[key]}>
                          {value}
                        </span>
                      )
                    case 'paragraph':
                      return <div key={`paragraph-tc-${index}`}>{parse(value)}</div>
                  }
                })
              : null}
          </>
        )}
      </div>

      <div className="flex justify-end items-center gap-4">
        <CustomButton buttonColor="secondary" disabled={isLoading} onClick={onLogout}>
          {t("Logout-t")}
        </CustomButton>
        <CustomButton onClick={!isLoading ? onSubmit : () => {}} isLoading={isLoading}>
          {t("Accept")}
        </CustomButton>
      </div>
    </ModalUser>
  )
}

export default TCModal

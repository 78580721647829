import ErrorMessage from 'components/atoms/ErrorMessage'
import { MuiTelInput } from 'mui-tel-input'
import React from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
const WithStyledFlag = styled(MuiTelInput)`
  .MuiTypography-root {
    color: white !important;
  }
`

const PhoneNumberFields = ({
  control,
  label,
  name,
  errors,
  theme = 'users',
  isRequired = false,
  ...inputProps
}) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {theme === 'admin' ? (
              <span className={`${errors[name] ? '!text-red-500' : 'text-[#FFFFFFA6]'} }`}>
                {label}
              </span>
            ) : null}
            <WithStyledFlag
              {...field}
              onChange={(value, info) => {
                field.onChange(info)
              }}
              value={field.value.numberValue || field.value || ''}
              forceCallingCode
              autoComplete="off"
              hiddenLabel={theme === 'admin'}
              defaultCountry="IT"
              required={isRequired}
              label={
                theme === 'admin' ? null : (
                  <span className={`${errors[name] ? '!text-red-500' : ''} }`}>{label}</span>
                )
              }
              {...inputProps}
            />
          </>
        )}
      />
      {errors[name] ? <ErrorMessage errorMessage={errors[name].message} /> : null}
    </div>
  )
}

export default PhoneNumberFields

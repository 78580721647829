import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import CustomButton from 'components/atoms/CustomButton'
import TextFields from 'components/molecules/TextFields'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
const schema = (t) => z.object({
  renameValue: z.string().min(1, { message: t('field is required') })
})

const RenameModal = ({ title, show, onClose, onSubmit, data }) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setError
  } = useForm({
    defaultValues: {
      renameValue: data.name || ''
    },
    resolver: zodResolver(schema(t))
  })
  const processForm = async (data) => {
    await onSubmit(data)
    handleCloseModal()
  }

  function handleCloseModal() {
    reset()
    onClose()
  }

  return (
    <ModalUser onClose={handleCloseModal} show={show} width={444} title={title}>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col justify-between gap-5">
        <div className="flex w-full">
          <TextFields
            hiddenLabel
            InputLabelProps={{ shrink: false }}
            variant="outlined"
            control={control}
            // label={'Rename Case'}
            errors={errors}
            name={'renameValue'}
          />
        </div>

        <div className="flex justify-end items-center gap-4">
          <CustomButton buttonColor="secondary" onClick={handleCloseModal}>
            {t('Cancel')}
          </CustomButton>
          <CustomButton buttonType="submit">{t('Rename')}</CustomButton>
        </div>
      </Box>
    </ModalUser>
  )
}

export default RenameModal

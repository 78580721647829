import { combineReducers } from '@reduxjs/toolkit'
import { adminReducer, userReducer } from './auth'
import { adminCompanyReducer } from './admin/adminCompany'
import { langReducer } from './lang'

const rootReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
  adminCompany: adminCompanyReducer,
  lang: langReducer
})

export default rootReducer
